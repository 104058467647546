import React, { useState, useEffect } from 'react';
import { Select, Input, Button, Form, Space } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import { DataRequestStatus } from '../../utils/constants';

import { UserUI, ClientLists } from '../../utils/data_types';

export interface INewRequestProps {
    user?: UserUI;
    client: ClientLists;
    bearerToken: string;
    done: () => void;
}
export const NewRequest: React.FC<INewRequestProps> = (parent) => {
    const { Option } = Select;
    const [schema, setSchema] = useState<any>();
    const [schemas, setSchemas] = useState<any[]>([]);
    const [form] = Form.useForm();

    const azure_function_url = process.env.AZURE_FUNCTION_URL;
    
    const update = () => {
        axios.post(
            `${azure_function_url}/Databoss?command=forms_list`,
            {
                companyid: parent.user?.entity?.id
            },
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            setSchemas(x.data);
        });
    }

    useEffect(() => { update(); }, []);

    const onFinish = (values: any) => {

        if(schema !== undefined){
            values.schema = {
                id: schemas[schema].id,
                fields: schemas[schema].fields
            }
            
            const today = new Date();
            
            let expiryDate = new Date();
            expiryDate = new Date(expiryDate.setDate(expiryDate.getDate() + schemas[schema].expiry));

            const response = {
                id: values.id,
                companyid: parent.user?.entity?.id,
                userid: parent.client.id,
                type: schemas[schema].id,
                permission: schemas[schema].permission,
                expiry: schemas[schema].expiry,
                date_expiry: expiryDate,
                date_created: today,
                status: DataRequestStatus.REQUESTED,
                data: values,
            }

            axios.post(
                `${azure_function_url}/Databoss?command=data_request_create`,
                response,
                {
                    maxContentLength: Number.POSITIVE_INFINITY,
                    headers: {
                        'Authorization': parent.bearerToken
                    }
                }
            ).then(() => {
                parent.done();
            });
        }
    };


    const uuid = uuidv4();

    return (        
        <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            form={form}
            style={{ maxWidth: 600 }}
            autoComplete="off"
        >
            <Form.Item
                name={'schema'}
                style={{width:'100%'}}
                rules={[{ required: true, message: 'Schema is required' }]}
            >
                <Select
                    style={{ width: '100%' }}
                    optionLabelProp="label"
                    placeholder={'Pick a Schema'}

                    onChange={(val)=> {console.log(val);setSchema(val)}}
                >
                    {
                        schemas.map((template:any, i:number) => 
                            <Option value={i} label={template.name} key={i}>
                                <Space>
                                    {template.name}
                                </Space>
                            </Option>
                        )
                    }
                </Select>
            </Form.Item>
            
            <Form.Item
                name={'id'}
                style={{width:'100%'}}
                initialValue={uuid}
                rules={[{ required: true, message: 'Form ID' }]}
            >
                <Input disabled style={{width:'100%'}} placeholder="Form ID" />
            </Form.Item>
            <Form.Item
                name={'to'}
                style={{width:'100%'}}

                initialValue={parent.client.id}
                rules={[{ required: true, message: 'Recipient' }]}
            >
                <Input value={parent.client.id} disabled style={{width:'100%'}} placeholder="Recipient" />
            </Form.Item>
            
            
            <Form.Item>
                <Space size="small">
                    <Button onClick={() => { parent.done() }}  style={{ textAlign: 'right' }}>
                        Back
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
}