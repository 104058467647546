import React, { useState, useEffect } from 'react';
import { Popconfirm, Space, DatePicker, Table, Card, Button, message } from 'antd';

import { LoadingOutlined, LeftOutlined, UndoOutlined, CheckCircleOutlined, StopOutlined } from '@ant-design/icons';

import { RespondRequest } from '../main/RespondRequest';
import { ViewGuarantee } from '../guarantees/ViewGuarantee';
import { VerifiedDocuments } from '../documents/VerifiedDocuments';

import { DataRequestStatus } from '../../utils/constants';

import dayjs from 'dayjs';

import axios from 'axios';

import { UserUI, EmployeeLists } from '../../utils/data_types';

export interface IViewEmployeeProps {
    user?: UserUI;
    employee: EmployeeLists;
    bearerToken: string;
    done: () => void;
}
export const ViewEmployee: React.FC<IViewEmployeeProps> = (parent) => {
    const { Column } = Table;

    const [viewRequest, setViewRequest] = useState<any>();
    const [outgoingDataRequests, setOutgoingDataRequest] = useState<any[]>();
    const [viewResponse, setViewResponse] = useState<any>();

    const azure_function_url = process.env.AZURE_FUNCTION_URL;
    
    const update = () => {
        
        // axios.get(
        //     `${azure_function_url}/NFGX?command=data_request_outbox&client=${parent.employee.id}`,
        //     {
        //         maxContentLength: Number.POSITIVE_INFINITY,
        //         headers: {
        //             'Authorization': parent.bearerToken
        //         }
        //     }
        // ).then(x => {
        //     const data = x.data;

        //     // const clear = data.map((x: any) => {
        //     //     x.schema = JSON.parse(JSON.parse(x.data).data).schema;
        //     //     delete x.request;
        //     //     return x;
        //     // });

        //     setOutgoingDataRequest(data);
        // });
    }

    useEffect(() => { 
        update();
    }, []);

    return (
        viewRequest ?
            <RespondRequest bearerToken={parent.bearerToken} done={() => { setViewRequest(undefined); update();}} request={viewRequest} user={parent.user}/>
        :
        viewResponse ?
            <ViewGuarantee bearerToken={parent.bearerToken} done={() => { setViewResponse(undefined); }} doc={viewResponse} user={parent.user}/>
        :
        <Card 
            title={<>
                {`${parent.employee.data?.personal?.name?.firstName} ${parent.employee.data?.personal?.name?.lastName}`}</>
            }
            extra={
                <Space>
                    <Button style={{float:'right'}} icon={<LeftOutlined />} onClick={() => parent.done() }></Button> 
                    <Button style={{float:'right'}} icon={<UndoOutlined/>} onClick={() => update() }></Button> 
                </Space>
            }
        >
            <div style={{width:'100%'}}>
                <VerifiedDocuments update={update} user={parent.user} bearerToken={parent.bearerToken} pt100={true} doc={undefined}></VerifiedDocuments>
            </div>
            <br></br>
            <br></br>
            <Table 
                dataSource={outgoingDataRequests?.map(x => {
                    let y: any = x;
                    y.key = x.id;
                    return y;
                })}
                pagination={{hideOnSinglePage: true}}
            >
                <Column
                    title="Type"
                    key="type"
                    render={(_: any, record: any) => record.name}
                />
                <Column
                    title="Date"
                    key="date"
                    render={(_: any, record: any) => (
                        <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix((record.status === DataRequestStatus.RESPONDED ? Date.parse(record.date_responded) : Date.parse(record.date_created)) / 1000)} />
                    )}
                />
                <Column
                    title="Action"
                    key="action"
                    render={(_: any, record: any) => (
                        record.status === DataRequestStatus.REQUESTED ? 
                        <Popconfirm
                            title="Remind the client"
                            description="Are you sure to remind this client?"
                            onConfirm={async () => { 
                                message.success('email sent...');
                                // axios.get(
                                //     `${azure_function_url}/Databoss?command=data_request_get&id=${record.id}`,
                                //     {
                                //         maxContentLength: Number.POSITIVE_INFINITY,
                                //         headers: {
                                //             'Authorization': parent.bearerToken
                                //         }
                                //     }
                                // ).then((data) => {
                                //     setViewResponse(data.data);
                                // });
                            } }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button 
                                type="default" 
                                block
                            >
                                <><LoadingOutlined style={{ fontSize: '16px' }}/> Waiting </>
                            </Button>
                        </Popconfirm>
                        :
                        <Button 
                            type="default" 
                            block
                            disabled={record.status !== DataRequestStatus.RESPONDED}
                            onClick={async () => { 
                                
                                // axios.get(
                                //     `${azure_function_url}/NFGX?command=data_request_get&id=${record.id}`,
                                //     {
                                //         maxContentLength: Number.POSITIVE_INFINITY,
                                //         headers: {
                                //             'Authorization': parent.bearerToken
                                //         }
                                //     }
                                // ).then((data) => {
                                //     setViewResponse(data.data);
                                // });
                            } }
                        >
                            {record.status === DataRequestStatus.RESPONDED ? 
                                <><CheckCircleOutlined style={{ fontSize: '16px', color: '#52c41a' }} /> View </> 
                            : 
                                <><StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/> Declined </>
                            }
                        </Button>
                    )}
                />
            </Table>
        </Card>
    )
}