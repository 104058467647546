import React, { useState, useEffect, useReducer } from 'react';
import { Checkbox, Popconfirm, Space, DatePicker, Table, Typography, Card, Button, message } from 'antd';

import { ExclamationCircleOutlined, LoadingOutlined, SafetyCertificateOutlined, CloseCircleOutlined, QuestionCircleOutlined, DeleteOutlined, LeftOutlined, UndoOutlined, PlusOutlined, CheckCircleOutlined, InfoCircleOutlined, StopOutlined } from '@ant-design/icons';

import { RespondRequest } from '../main/RespondRequest';
import { ViewGuarantee } from '../guarantees/ViewGuarantee';
import { NewRequest } from './NewRequest';
import { VerifiedDocuments } from '../documents/VerifiedDocuments';

import { DataRequestStatus, BadgeTypes } from '../../utils/constants';

import { ClientLists } from '../../utils/data_types';

import dayjs from 'dayjs';

import axios from 'axios';

import { UserUI } from '../../utils/data_types';

import { parseJSON } from '../../utils/utils';

export interface IViewClientProps {
    user?: UserUI;
    client: ClientLists;
    bearerToken: string;
    update: () => void;
    done: () => void;
}
export const ViewClient: React.FC<IViewClientProps> = (parent) => {
    const { Column } = Table;
    
    const [viewRequest, setViewRequest] = useState<any>();
    const [outgoingDataRequests, setOutgoingDataRequest] = useState<any[]>();
    const [viewNewRequest, setViewNewRequest] = useState(false);
    const [viewResponse, setViewResponse] = useState<any>();

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const update = () => {
        parent.update();

        axios.get(
            `${azure_function_url}/Databoss?command=data_request_outbox&companyid=${parent.user?.entity?.id}&userid=${parent.client.id}`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            const data = parseJSON(x.data);
            setOutgoingDataRequest(data);
        });
    }

    useEffect(() => { 
        update();
    }, []);
    
    
    return (
        viewRequest ?
            <RespondRequest bearerToken={parent.bearerToken} done={() => { setViewRequest(undefined); update();}} request={viewRequest} user={parent.user}/>
        :
        viewResponse ?
            <ViewGuarantee bearerToken={parent.bearerToken} done={() => { setViewResponse(undefined); }} doc={viewResponse} user={parent.user}/>
        :
        <>
        <Card 
            title={<>
                {parent.client.badge === BadgeTypes.HIGH ?
                    <SafetyCertificateOutlined style={{ fontSize: '16px', color: '#52c41a' }} />
                    
                : parent.client.badge === BadgeTypes.LOW ?
                    <CheckCircleOutlined />
                : parent.client.badge === BadgeTypes.INVALID ?
                    <CloseCircleOutlined style={{ fontSize: '16px', color: '#FF0000' }} />
                : 
                    <QuestionCircleOutlined style={{ fontSize: '16px', color: '#E49B0F' }} />
                } &nbsp;
                {`${parent?.client?.data?.personal?.name?.firstName} ${parent?.client?.data?.personal?.name?.lastName}`}
                </>
            }

            extra={
                <Space>
                    <Checkbox
                        disabled={parent.client.badge === BadgeTypes.HIGH}
                        checked={parent.client.badge !== BadgeTypes.LOW}
                        onChange={(e) => {
                            
                            axios.post(
                                `${azure_function_url}/Databoss?command=clients_badge`,
                                {
                                    companyid: parent.user?.entity?.id,
                                    badge: BadgeTypes.HIGH,
                                    clientid: parent.client.id
                                },
                                {
                                    maxContentLength: Number.POSITIVE_INFINITY,
                                    headers: {
                                        'Authorization': parent.bearerToken
                                    }
                                }
                            ).then(x => {
                                message.success('Client has been visually verified')
                                parent.client.badge = BadgeTypes.HIGH;
                                
                                update();
                            });
                        }}
                    >
                        Verified
                    </Checkbox>
                    <Button style={{float:'right'}} icon={<LeftOutlined />} onClick={() => parent.done() }></Button> 
                    {
                    viewNewRequest !== true ? 
                    <Button style={{float:'right'}} icon={<PlusOutlined/>} onClick={() => setViewNewRequest(true) }></Button> 
                    : 
                    <></>
                    }
                </Space>
            }
            bodyStyle={{ padding: 0 }}
        >
        </Card>
        

        { viewNewRequest !== true ?
            <>
            <br></br>
            <VerifiedDocuments update={update} user={parent.user} data={parent.client.data} bearerToken={parent.bearerToken} pt100={true} doc={undefined}></VerifiedDocuments>
            
            </>
        :
        <></>
        }
        <br></br>
        
        <Card 
            title={'Data Requests'}
            extra={<Button style={{float:'right'}} icon={<UndoOutlined/>} onClick={() => update() }></Button>}
        >
            { viewNewRequest === true ?                
                <NewRequest bearerToken={parent.bearerToken} done={() => { update();  setViewNewRequest(false)}} client={parent.client} user={parent.user} />

            :   
                <>
                    <Table 
                        dataSource={outgoingDataRequests?.map(x => {
                            let y: any = x;
                            y.key = x.id;
                            return y;
                        })}
                        pagination={{hideOnSinglePage: true}}
                    >
                        <Column
                            title="Type"
                            key="type"
                            render={(_: any, record: any) => record.name}
                        />
                        <Column
                            title="Date"
                            key="date"
                            render={(_: any, record: any) => (
                                <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix((record.status === DataRequestStatus.RESPONDED ? Date.parse(record.date_responded) : Date.parse(record.date_created)) / 1000)} />
                            )}
                        />
                        <Column
                            title="Action"
                            key="action"
                            render={(_: any, record: any) => (
                                record.status === DataRequestStatus.REQUESTED ? 
                                <Popconfirm
                                    title="Remind the client"
                                    description="Are you sure to remind this client?"
                                    onConfirm={async () => { 
                                        message.success('email sent...');
                                        // axios.get(
                                        //     `${azure_function_url}/Databoss?command=data_request_get&id=${record.id}`,
                                        //     {
                                        //         maxContentLength: Number.POSITIVE_INFINITY,
                                        //         headers: {
                                        //             'Authorization': parent.bearerToken
                                        //         }
                                        //     }
                                        // ).then((data) => {
                                        //     setViewResponse(data.data);
                                        // });
                                    } }
                                    // onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button 
                                        type="default" 
                                        block
                                    >
                                        <><LoadingOutlined style={{ fontSize: '16px' }}/> Waiting </>
                                    </Button>
                                </Popconfirm>
                                :
                                <Button 
                                    type="default" 
                                    block
                                    disabled={record.status !== DataRequestStatus.RESPONDED && record.status !== DataRequestStatus.DELETION}
                                    onClick={async () => { 
                                        
                                        axios.get(
                                            `${azure_function_url}/Databoss?command=data_request_get&id=${record.id}`,
                                            {
                                                maxContentLength: Number.POSITIVE_INFINITY,
                                                headers: {
                                                    'Authorization': parent.bearerToken
                                                }
                                            }
                                        ).then((data) => {
                                            setViewResponse(data.data);
                                        });
                                    } }
                                >
                                    {record.status === DataRequestStatus.RESPONDED ? 
                                        <><CheckCircleOutlined style={{ fontSize: '16px', color: '#52c41a' }} /> View </> 
                                    : record.status === DataRequestStatus.DELETION ? 
                                        <><ExclamationCircleOutlined style={{ fontSize: '16px', color: '#FF0000' }} /> Deletion </> 
                                    : record.status === DataRequestStatus.DELETED ? 
                                        <><StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/> Deleted </>
                                    : 
                                        <><StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/> Declined </>
                                    }
                                </Button>
                            )}
                        />
                        <Column
                            title="Delete"
                            key="delete"
                            render={(_: any, record: any) => 
                                <Popconfirm
                                    title="Delete the request"
                                    description="Are you sure to delete this request?"
                                    // onConfirm={confirm}
                                    // onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button style={{border:'none'}} icon={<DeleteOutlined />}/>
                                </Popconfirm>}
                        />
                        
                    </Table>
                </> 
            }
        </Card>
        </>
    )
}