import React, { useState, useEffect } from 'react';
import { Popconfirm, Space, DatePicker, Table, Typography, Card, Button, message } from 'antd';

import { ExclamationCircleOutlined, DeleteOutlined, UndoOutlined, CheckCircleOutlined, LoadingOutlined, StopOutlined } from '@ant-design/icons';

import { ViewGuarantee } from '../guarantees/ViewGuarantee';

import { DataRequestStatus } from '../../utils/constants';

import { UserUI } from '../../utils/data_types';

import { parseJSON } from '../../utils/utils';

import dayjs from 'dayjs';

import axios from 'axios';

const { Column } = Table;

export interface IOutboxProps {
    user?: UserUI;
    bearerToken: string;
}
export const Outbox: React.FC<IOutboxProps> = (parent) => {
    
    const [outgoingDataRequests, setOutgoingDataRequest] = useState<any[]>();
    const [viewResponse, setViewResponse] = useState<any>();

    const [loading, setLoading] = useState(true);

    const azure_function_url = process.env.AZURE_FUNCTION_URL;
    
    const update = () => {
        setLoading(true);
        axios.get(
            `${azure_function_url}/Databoss?command=data_request_outbox&companyid=${parent.user?.entity?.id}`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            const data = parseJSON(x.data);
            setOutgoingDataRequest(data);
            setLoading(false);
        });
    }

    useEffect(() => { 
        update();
    }, []);
    
    
    return (
        viewResponse ?
            <ViewGuarantee bearerToken={parent.bearerToken} done={() => { setViewResponse(undefined); update(); }} doc={viewResponse} user={parent.user}/>
        :
        outgoingDataRequests?.length === 0 ?
        <Card 
            bordered={false} 
            title={'Event Logs'}
            extra={
                <Button style={{float:'right'}} icon={<UndoOutlined/>} onClick={() => update() }></Button> 
            }
            bodyStyle={{ padding: 0 }}
        ></Card>
        :
        <Card 
            bordered={false} 
            title={'Event Logs'}
            extra={
                <Space>
                    <Button style={{float:'right'}} icon={<UndoOutlined/>} onClick={() => update() }></Button> 
                </Space>
            }
        >
            {      
                <Table 
                    loading={loading}
                    dataSource={outgoingDataRequests?.map(x => {
                        let y: any = x;
                        y.key = x.id;
                        return y;
                    })}
                    pagination={{hideOnSinglePage: true}}
                >
                    <Column
                        title="Type"
                        key="type"
                        render={(_: any, record: any) => record.name}
                    />
                    <Column
                        title="To"
                        key="userid"
                        render={(_: any, record: any) => record.userid}
                    />
                    
                    <Column
                        title="Date"
                        key="date"
                        render={(_: any, record: any) => (
                            <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix((record.status === DataRequestStatus.RESPONDED ? record.date_responded.getTime() : record.date_created.getTime() ) / 1000)} />
                        )}
                    />
                    <Column
                        title="Action"
                        key="action"
                        render={(_: any, record: any) => (
                            record.status === DataRequestStatus.REQUESTED ? 
                            <Popconfirm
                                title="Remind the client"
                                description="Are you sure to remind this client?"
                                onConfirm={async () => { 
                                    message.success('email sent...');
                                    // axios.get(
                                    //     `${azure_function_url}/Databoss?command=data_request_get&id=${record.id}`,
                                    //     {
                                    //         maxContentLength: Number.POSITIVE_INFINITY,
                                    //         headers: {
                                    //             'Authorization': parent.bearerToken
                                    //         }
                                    //     }
                                    // ).then((data) => {
                                    //     setViewResponse(data.data);
                                    // });
                                } }
                                // onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button 
                                    type="default" 
                                    block
                                >
                                    <><LoadingOutlined style={{ fontSize: '16px' }}/> Waiting </>
                                </Button>
                            </Popconfirm>
                            :
                            <Button 
                                type="default" 
                                block
                                disabled={record.status !== DataRequestStatus.RESPONDED && record.status !== DataRequestStatus.DELETION}
                                onClick={async () => { 
                                    
                                    axios.get(
                                        `${azure_function_url}/Databoss?command=data_request_get&id=${record.id}`,
                                        {
                                            maxContentLength: Number.POSITIVE_INFINITY,
                                            headers: {
                                                'Authorization': parent.bearerToken
                                            }
                                        }
                                    ).then((data) => {
                                        console.log(data.data)
                                        setViewResponse(data.data);
                                    });
                                } }
                            >
                                {record.status === DataRequestStatus.RESPONDED ? 
                                    <><CheckCircleOutlined style={{ fontSize: '16px', color: '#52c41a' }} /> View </> 
                                : record.status === DataRequestStatus.DELETION ? 
                                    <><ExclamationCircleOutlined style={{ fontSize: '16px', color: '#FF0000' }} /> Deletion </> 
                                : record.status === DataRequestStatus.DELETED ? 
                                    <><StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/> Deleted </>
                                : 
                                    <><StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/> Declined </>
                                }
                            </Button>
                        )}
                    />
                    <Column
                        title="Delete"
                        key="delete"
                        render={(_: any, record: any) => 
                            <Popconfirm
                                title="Delete the request"
                                description="Are you sure to delete this request?"
                                // onConfirm={confirm}
                                // onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button style={{border:'none'}} icon={<DeleteOutlined />}/>
                            </Popconfirm>}
                    />
                </Table>
            }
        </Card>
    )
}