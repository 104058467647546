import React, { useState, useEffect } from 'react';
import { Card, Button, message } from 'antd';

import { UndoOutlined } from '@ant-design/icons';

import { VerifiedDocuments } from './VerifiedDocuments';
import { ListBankAccounts } from './ListBankAccounts';
import { ListFiles } from './ListFiles';

import { FrankieOneDocumentTypes } from '../../utils/constants';
import { FrankieOneDocument, UserUI, UserData } from '../../utils/data_types';

import axios from 'axios';

export interface IVerifiedDocumentsProps {
    pt100: boolean;
    user?: UserUI;
    data?: UserData | null;
    bearerToken: string;
    doc: { name: string, type: FrankieOneDocumentTypes | string, doc: FrankieOneDocument } | undefined;
    update: () => void;
}
export const Documents: React.FC<IVerifiedDocumentsProps> = (parent) => {
    
    const azure_function_url = process.env.AZURE_FUNCTION_URL;
    
    const [viewDoc, setViewDoc] = useState<{name: string, type: FrankieOneDocumentTypes | string, doc: FrankieOneDocument | undefined } | undefined>(undefined);

    const updateBackEnd = () => axios.get(
        `${azure_function_url}/Databoss?command=kyc_update`,
        {
            maxContentLength: Number.POSITIVE_INFINITY,
            headers: {
                'Authorization': parent.bearerToken
            }
        }
    ).then(() => {
        parent.update();
    });

    useEffect(() => { 
        parent.update();
    }, []);

    return (
        <>
            <Card 
                bordered={false} 
                title={parent.pt100 ? "Verified Documents" : `You need to verify all of the following documents...`} 
                extra={<Button icon={<UndoOutlined/>} onClick={() => updateBackEnd() }></Button>}
                bodyStyle={{ padding: 0 }}
            ></Card>
            <br></br>
            <VerifiedDocuments update={parent.update} user={parent.user} data={parent.user?.data} bearerToken={parent.bearerToken} pt100={true} doc={undefined} ></VerifiedDocuments>

            <ListBankAccounts user={parent.user} update={parent.update} bearerToken={parent.bearerToken}></ListBankAccounts>
            <ListFiles user={parent.user} update={parent.update} bearerToken={parent.bearerToken}></ListFiles>
            
        </>
    )
}