import React, { useState, useEffect } from 'react';
import { Modal, Select, Divider, Button, Form, Input, Space, FormInstance } from 'antd';

import axios from 'axios';

import { UserUI } from '../../utils/data_types';


export interface IBankAccountProps {
    user?: UserUI;
    bearerToken: string;
    form: FormInstance;
    name: string[];
}

export const BankAccounts: React.FC<IBankAccountProps> = (parent) => {
    const { Option } = Select;

    const [newModal, setNewModal] = useState(false);
    const [accounts, setAccounts] = useState<{ id: string, name: string, bsb: string, account: string }[]>([]);

    const [form] = Form.useForm();

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const update = () => axios.get(
        `${azure_function_url}/Databoss?command=bank_accounts_list`,
        {
            maxContentLength: Number.POSITIVE_INFINITY,
            headers: {
                'Authorization': parent.bearerToken
            }
        }
    ).then((x) => {
        setAccounts(x.data);
    });

    useEffect(() => {
        update(); 
    }, []);

    
    return (
    <>
        <Select
            style={{ width: '100%' }}
            optionLabelProp="label"
            placeholder={'Pick an account'}
            value={parent.form.getFieldValue(parent.name)}

            onChange={(value) => {
                if(value === 'new'){
                    setNewModal(true);
                }
                else{
                    const _value = parent.form.getFieldValue([parent.name[0]]);
                    _value[parent.name[1]] = value;
                    parent.form.setFieldsValue(_value);

                    setNewModal(false);
                }
            }}
        >
            
            {
                accounts
                .map((template, i) => 
                    <Option value={template.id} label={`${template.name} | BSB: ${template.bsb} ACC: ${template.account}`} key={i}>
                        <Space>
                            {template.name} | BSB: {template.bsb} ACC: {template.account}
                        </Space>
                    </Option>
                ).concat([
                    <Option value={'new'} label={'New'} key={10}>    
                        <Divider orientation="left" plain></Divider>
                        <Space>
                            New Account
                        </Space>
                    </Option>
                ])                
            }
        </Select>

        <Modal title={"New Acccount"} closable={false} open={newModal} footer={null}>
            <Form
                form={form}
                name="new_bank_account"
                onFinish={(values) => {
                    axios.post(
                        `${azure_function_url}/Databoss?command=bank_accounts_new`,
                        values,
                        {
                            maxContentLength: Number.POSITIVE_INFINITY,
                            headers: {
                                'Authorization': parent.bearerToken
                            }
                        }
                    ).then((x) => {
                        update();
                        setNewModal(false);
                    });
                
                }}
                style={{ maxWidth: 600 }}
                autoComplete="off"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
            >
                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="bsb" label="BSB" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="account" label="Account" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <br></br>
                <br></br>
                <Form.Item 
                    wrapperCol={{ offset: 8, span: 16 }}
                >
                    <Space>
                        <Button htmlType="button" onClick={()=>{form.resetFields(); setNewModal(false)}}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Space>
                </Form.Item>
            </Form> 
        </Modal>
    </>
    )
}