import React, { useState } from 'react';
import { Popconfirm, Select, Divider, Button, message, Form, Input, InputNumber, Row, Col, Space, DatePicker } from 'antd';
import { CheckCircleOutlined, InfoCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { SmartUI } from '../SmartUI/SmartUI';

import axios from 'axios';

import { VerifiedDocumentTypes, FrankieOneDocumentTypes, PersonalDataTypes, CustomDataTypes } from '../../utils/constants';

import { UserUI, DataRequestResult } from '../../utils/data_types';

import { BankAccounts } from '../custom/BankAccounts';
import { FileUploads } from '../custom/FileUploads';


export interface IRespondRequestProps {
    user?: UserUI;
    bearerToken: string;
    request: DataRequestResult;
    done: () => void;
}
export const RespondRequest: React.FC<IRespondRequestProps> = (parent) => {
    const { Option } = Select;

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const [smartUIDoc, setSmartUIDoc] = useState<any>();
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        let valuesCopy = {...values.data};
        parent.request.schema.fields.forEach((field: any) => {
            if(field.type === 'date')
                valuesCopy[field.id] = values.data[field.id].unix();
            else
                valuesCopy[field.id] = values.data[field.id];
        });

        const response = {
            data: valuesCopy
        }

        axios.post(
            `${azure_function_url}/Databoss?command=data_request_respond&id=${parent.request.id}`,
            response,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(() => {
            parent.done();
        });
    };

    const decline = () => {
        
        axios.get(
            `${azure_function_url}/Databoss?command=data_request_confirm_decline&id=${parent.request.id}`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(() => {
            parent.done();
        });
    }

    const check = (type: string) => {
        return Object.keys(
            type === FrankieOneDocumentTypes.PASSPORT ? parent.user?.data?.passport || [] :
            type === FrankieOneDocumentTypes.DRIVERS_LICENCE ? parent.user?.data?.license || []  :
            type === FrankieOneDocumentTypes.NATIONAL_ID ? parent.user?.data?.national_id || []  :
            type === FrankieOneDocumentTypes.NATIONAL_HEALTH_ID ? parent.user?.data.medicare || []  :
            type === PersonalDataTypes.NAME ? parent.user?.data?.personal || []  :
            type === PersonalDataTypes.ADDRESS ? parent.user?.data?.personal || []  :
            type === PersonalDataTypes.DOB ? parent.user?.data?.personal || []  :
            []
        ).length > 0 
    }
    
    return (
    <>
        <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            form={form}
            style={{ maxWidth: 600 }}
            autoComplete="off"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
        >
            { parent.request.schema.fields.map((field: any, i: number) => 
                (
                    <Row gutter={16} key={i}>
                        <Col span={24}>
                            <Form.Item 
                                label={field.name}
                                name={['data', field.id]} 
                                initialValue={
                                    field.type === PersonalDataTypes.NAME ? `${parent.user?.data?.personal?.name.firstName} ${parent.user?.data?.personal?.name.middleName} ${parent.user?.data?.personal?.name.lastName}` :
                                    field.type === PersonalDataTypes.ADDRESS ? `${parent.user?.data?.personal?.addresses[0].longForm}` :
                                    field.type === PersonalDataTypes.DOB ? `${parent.user?.data?.personal?.dateOfBirth}` :
                                    check(field.type) ? parent.user?.id : undefined
                                }
                                rules={[{ required: true, message: 'Please input request' }]}
                            >
                                {
                                    field.type === CustomDataTypes.PHOTO_ID ? 
                                        <Select
                                            style={{ width: '100%' }}
                                            optionLabelProp="label"
                                            placeholder={'Pick a permission'}
                                        >
                                            {
                                                [
                                                    {type: FrankieOneDocumentTypes.PASSPORT, name: 'Passport' },
                                                    {type: FrankieOneDocumentTypes.DRIVERS_LICENCE, name: 'Driver License'},
                                                    {type: FrankieOneDocumentTypes.NATIONAL_ID, name: 'National ID'}
                                                ].filter(x=>check(x.type))
                                                .map((template, i) => 
                                                    <Option value={template.type} label={template.name} key={i}>
                                                        <Space>
                                                            {template.name}
                                                        </Space>
                                                    </Option>
                                                )
                                            }
                                        </Select>
                                    :
                                    
                                    field.type === CustomDataTypes.BANK_ACCOUNT ? 
                                        <BankAccounts bearerToken={parent.bearerToken} user={parent.user} form={form} name={['data', field.id]} />
                                    :

                                    field.type === CustomDataTypes.FILE_UPLOAD ? 
                                        <FileUploads bearerToken={parent.bearerToken} user={parent.user} form={form} name={['data', field.id]} />
                                    :

                                    field.type === 'number' ? 
                                    <InputNumber
                                        min={0} 
                                        style={{ width: '100%' }}
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    />
                                    :
                                    field.type === 'date' ?
                                    <DatePicker 
                                        style={{ width: '100%' }}
                                        showTime 
                                        format="YYYY-MM-DD HH:mm:ss" />
                                    :
                                    VerifiedDocumentTypes.indexOf(field.type) > -1 ?
                                        !check(field.type)  ? 
                                            <>
                                                <a onClick={async () => { 
                                
                                                    const getData = !check(field.type);

                                                    if(getData && VerifiedDocumentTypes.indexOf(field.type) > -1)
                                                        setSmartUIDoc(field.type)
                                                    else
                                                        message.success('Ready to send!')
                                                    
                                                }}>
                                                    <InfoCircleOutlined style={{ fontSize: '16px', color: '#E49B0F' }}/> Click to Verify
                                                </a>
                                            </>
                                        : 
                                            <CheckCircleOutlined style={{ fontSize: '16px', color: '#52c41a' }} />
                                    :
                                    <Input 
                                        disabled={Object.values(PersonalDataTypes).indexOf(field.type) > -1}
                                    />
                                    
                                }
                                
                            </Form.Item>
                        </Col>
                    </Row>
                )
            )}
            <Form.Item>
                <Space size="small">
                    <Button onClick={() => parent.done() }>Back</Button>
                    <Button disabled={false} type="primary"  htmlType="submit" >
                        Submit
                    </Button>
                    <Popconfirm
                            title="Decline request"
                            description="Are you sure you want to decline?"
                            onConfirm={async () => { 
                                message.success('Request Declined');
                                decline();
                            } }
                            okText="Yes"
                            cancelText="No"
                        >
                        <Button disabled={false} type="default">
                            Decline
                        </Button>
                    </Popconfirm>
                </Space>
            </Form.Item>
        </Form>

        { smartUIDoc ?
            <>
                <br></br>
                <Divider orientation="left" plain></Divider>
                <Button style={{float:'right', border: 'none'}} icon={<CloseCircleOutlined/>}  onClick={() => setSmartUIDoc(undefined) }></Button>
                
                <SmartUI bearerToken={parent.bearerToken} done={() => { setSmartUIDoc(undefined) }} smartUIDoc={smartUIDoc} />
            </>
        : <></>}
    </>
    )
}