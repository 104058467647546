
export const parseJSON = (obj: any) => JSON.parse(JSON.stringify(obj), (key, value) => {
    let dateRegEx = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    if (typeof value === 'string' && dateRegEx.test(value)) {
        return new Date(value);
    }

    dateRegEx = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{2}Z$/;
    if (typeof value === 'string' && dateRegEx.test(value)) {
        return new Date(value);
    }

    dateRegEx = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{1}Z$/;
    if (typeof value === 'string' && dateRegEx.test(value)) {
        return new Date(value);
    }

    dateRegEx = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}.\d{3}Z$/;
    if (typeof value === 'string' && dateRegEx.test(value)) {
        return new Date(value);
    }

    dateRegEx = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}.\d{2}Z$/;
    if (typeof value === 'string' && dateRegEx.test(value)) {
        return new Date(value);
    }

    dateRegEx = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}.\d{1}Z$/;
    if (typeof value === 'string' && dateRegEx.test(value)) {
        return new Date(value);
    }

    return value;
});

export const isMobileBrowser = (): boolean => {
    if (typeof navigator !== 'undefined') {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    return false;
};

export const shortenString = (input: string | undefined, maxLength?: number): string => {
    if(input === undefined)
        return '';
    
    const _maxLength = maxLength ?? 20; 

    
    const ellipsis = "...";

    if(_maxLength <= 5)
        return input.substring(0, _maxLength) + ellipsis;
    
    
    // If the string is already within the max length, return it
    if (input.length <= _maxLength) {
        return input;
    }

    // Calculate the number of characters we need to show on each side of the '...'
    const charsOnEachSide = (_maxLength - ellipsis.length) / 2;
    
    // Get the start and end parts of the string
    const start = input.substring(0, charsOnEachSide);
    const end = input.substring(input.length - charsOnEachSide);

    // Construct and return the new string
    return start + ellipsis + end;
}