import React, { useState, useEffect } from 'react';
import { List, Popconfirm, DatePicker, Table, Card, Button, message } from 'antd';

import { DeleteOutlined, UndoOutlined, CheckCircleOutlined, InfoCircleOutlined, StopOutlined } from '@ant-design/icons';

import { RespondRequest } from './RespondRequest';
import { DataRequestStatus } from '../../utils/constants';
// import { ViewResponse } from './ViewResponse';

import { DataRequestResult, EmployeeInvitations, ClientInvitations, UserUI } from '../../utils/data_types';

import { parseJSON, isMobileBrowser } from '../../utils/utils';

import dayjs from 'dayjs';

import axios from 'axios';

const { Column } = Table;

export interface IInboxProps {
    user?: UserUI;
    bearerToken: string;
}
export const Inbox: React.FC<IInboxProps> = (parent) => {
    
    const [viewRequest, setViewRequest] = useState<DataRequestResult>();
    const [viewResponse, setViewResponse] = useState<any>();
    const [incomingDataRequests, setIncomingDataRequest] = useState<any[]>();

    const [collapsed, setCollapsed] = useState<boolean>(isMobileBrowser());

    const [loading, setLoading] = useState(true);
    
    const azure_function_url = process.env.AZURE_FUNCTION_URL;
    
    const update = () => {
        setLoading(true);
        const data : any[] = [];
        axios.get(
            `${azure_function_url}/NCID?command=employees_invitations`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            const invitations: EmployeeInvitations[] = parseJSON(x.data);
            invitations.forEach((x: any, i:number) => {
                data.push({
                    id: i,
                    entity_id: x.entity_id,
                    entity_name: x.entity_name,
                    name: 'Employee Invitation',
                    date_created: x.date_created,
                    employeeid: x.inviterid,
                    employee_invitation: true,
                })
            })

            axios.get(
                `${azure_function_url}/NCID?command=clients_invitations`,
                {
                    maxContentLength: Number.POSITIVE_INFINITY,
                    headers: {
                        'Authorization': parent.bearerToken
                    }
                }
            ).then(x => {
                const invitations: ClientInvitations[] = parseJSON(x.data);
                invitations.forEach((x: any, i:number) => {
                    data.push({
                        id: i,
                        entity_id: x.entity_id,
                        entity_name: x.entity_name,
                        name: 'Client Invitation',
                        date_created: x.date_created,
                        employeeid: x.inviter_employeeid,
                        client_invitation: true,
                    })
                })

                setLoading(false);

                setIncomingDataRequest(data);

            });

        });
    }

    useEffect(() => { 
        update();
    }, []);
    
    
    return (
        viewRequest ?
            <Card 
                bordered={false} 
                title={'Respond'}
                extra={
                    <Button style={{float:'right'}} icon={<UndoOutlined/>} onClick={() => update() }></Button> 
                }
            >
                <RespondRequest bearerToken={parent.bearerToken} done={() => { setViewRequest(undefined); update();}} request={viewRequest} user={parent.user}/>
            </Card>
        :
        // viewResponse ?
            // <ViewResponse bearerToken={parent.bearerToken} owner={true} done={() => { setViewResponse(undefined); update(); }} doc={viewResponse} user={parent.user}/>
        // :
        incomingDataRequests?.length === 0 ?
        <Card 
            bordered={false} 
            title={'Inbox'}
            extra={
                <Button style={{float:'right'}} icon={<UndoOutlined/>} onClick={() => update() }></Button> 
            }
            bodyStyle={{ padding: 0 }}
        ></Card>
        :
        <Card 
            bordered={false} 
            title={'Inbox'}
            extra={
                <Button style={{float:'right'}} icon={<UndoOutlined/>} onClick={() => update() }></Button> 
            }
        >
            {
                collapsed ?
                <>
                    <List 
                        itemLayout="horizontal"
                        pagination={{
                            onChange: (page) => {
                              console.log(page);
                            },
                            pageSize: 3
                        }}

                        dataSource={incomingDataRequests?.map((x, i: number) => {
                            let y: any = x;
                            y.key = i;
                            return y;
                        })}
                        renderItem= {(x) => 
                                <List.Item key={x.key}>
                                    <List.Item.Meta
                                        avatar={
                                            x.status === DataRequestStatus.RESPONDED ? 
                                                <CheckCircleOutlined style={{ fontSize: '16px', color: '#52c41a' }} />
                                            : x.status === DataRequestStatus.DECLINED ?  
                                                <StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/>
                                            : x.status === DataRequestStatus.DELETION ?  
                                                <StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/>
                                            : x.status === DataRequestStatus.DELETED ?  
                                                <StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/>
                                            :  <InfoCircleOutlined style={{ fontSize: '16px', color: '#E49B0F' }}/>
                                        }
                                        title={
                                            <a 
                                                onClick={async () => { 
                                                    // axios.get(
                                                    //     `${azure_function_url}/NFGX?command=data_request_get&id=${x.id}`,
                                                    //     {
                                                    //         maxContentLength: Number.POSITIVE_INFINITY,
                                                    //         headers: {
                                                    //             'Authorization': parent.bearerToken
                                                    //         }
                                                    //     }
                                                    // ).then((data) => {
                                                    //     const doc: DataRequestResult = data.data;
                                                    //     if(x.status === DataRequestStatus.RESPONDED || x.status === DataRequestStatus.DELETION) 
                                                    //         setViewResponse(doc);
                                                        
                                                    //     else
                                                    //         setViewRequest(doc);
                                                    // });
                                            }}>
                                                {x.entity_name}
                                            </a>
                                        }
                                        description={<>{x.name} <br></br> {new Date(x.status === DataRequestStatus.RESPONDED ? (x.date_responded) : (x.date_created)).toLocaleString()}</>}
                                    /> 
                                </List.Item>
                        }    
                    />
                </>
                :
                <Table 
                    loading={loading}
                    dataSource={incomingDataRequests?.map(x => {
                        let y: any = x;
                        y.key = x.id;
                        return y;
                    })}
                    pagination={{hideOnSinglePage: true}}
                >
                    <Column
                        title="Company"
                        key="entity_id"
                        render={(_: any, record: any) => record.entity_name}
                    />
                    <Column
                        title="Type"
                        key="type"
                        render={(_: any, record: any) => record.name}
                    />
                    <Column
                        title="From"
                        key="employeeid" 
                        render={(_: any, record: any) => record.employeeid}
                    />
                    <Column
                        title="Date"
                        key="date"
                        render={(_: any, record: any) => (
                            <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix((record.status === DataRequestStatus.RESPONDED ? Date.parse(record.date_responded) : Date.parse(record.date_created)) / 1000)} />
                        )}
                    />
                    <Column
                        title="Action"
                        key="action"
                        render={(_: any, record: any) => (
                            record.employee_invitation ? 
                            <Popconfirm
                                title="Accept or Decline Invitation"
                                description="Would you like to accept of decline the Inviation?"
                                onConfirm={() => {
                                    axios.post(
                                        `${azure_function_url}/NCID?command=employees_accept`,
                                        {
                                            entityid: record.entity_id,
                                        },
                                        {
                                            maxContentLength: Number.POSITIVE_INFINITY,
                                            headers: {
                                                'Authorization': parent.bearerToken
                                            }
                                        }
                                    ).then(x => {
                                        message.success('Employee invitation accepted');
                                        update();
                                    });

                                }}
                                onCancel={() => {
                                    axios.post(
                                        `${azure_function_url}/NCID?command=employees_decline`,
                                        {
                                            entityid: record.entity_id,
                                        },
                                        {
                                            maxContentLength: Number.POSITIVE_INFINITY,
                                            headers: {
                                                'Authorization': parent.bearerToken
                                            }
                                        }
                                    ).then(x => {
                                        message.success('Employee invitation declined');
                                        update();
                                    });

                                }}
                                okText="Accept"
                                cancelText="Decline"
                            >
                                <Button block icon={<InfoCircleOutlined />}>Respond </Button>
                            </Popconfirm>
                            : record.client_invitation ? 
                            <Popconfirm
                                title="Accept or Decline Invitation"
                                description="Would you like to accept of decline the Inviation?"
                                onConfirm={() => {
                                    axios.post(
                                        `${azure_function_url}/NCID?command=clients_accept`,
                                        {
                                            entityid: record.entity_id,
                                        },
                                        {
                                            maxContentLength: Number.POSITIVE_INFINITY,
                                            headers: {
                                                'Authorization': parent.bearerToken
                                            }
                                        }
                                    ).then(x => {
                                        message.success('Client invitation accepted');
                                        update();
                                    });

                                }}
                                onCancel={() => {
                                    axios.post(
                                        `${azure_function_url}/NCID?command=clients_decline`,
                                        {
                                            entityid: record.entity_id,
                                        },
                                        {
                                            maxContentLength: Number.POSITIVE_INFINITY,
                                            headers: {
                                                'Authorization': parent.bearerToken
                                            }
                                        }
                                    ).then(x => {
                                        message.success('Client invitation declined');
                                        update();
                                    });

                                }}
                                okText="Accept"
                                cancelText="Decline"
                            >
                                <Button block icon={<InfoCircleOutlined />}>Respond </Button>
                            </Popconfirm>
                            :
                            <Button 
                                type="default" 
                                block
                                disabled={record.status === DataRequestStatus.DECLINED || record.status === DataRequestStatus.DELETED}
                                onClick={async () => { 
                                        axios.get(
                                            `${azure_function_url}/NFGX?command=data_request_get&id=${record.id}`,
                                            {
                                                maxContentLength: Number.POSITIVE_INFINITY,
                                                headers: {
                                                    'Authorization': parent.bearerToken
                                                }
                                            }
                                        ).then((data) => {
                                            const doc: DataRequestResult = data.data;
                                            if(record.status === DataRequestStatus.RESPONDED || record.status === DataRequestStatus.DELETION) 
                                                setViewResponse(doc);
                                            
                                            else
                                                setViewRequest(doc);
                                        });
                                } }
                            >
                                {record.status === DataRequestStatus.RESPONDED ? 
                                    <><CheckCircleOutlined style={{ fontSize: '16px', color: '#52c41a' }} /> Responded </> 
                                : record.status === DataRequestStatus.DECLINED ?  
                                    <><StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/> Declined </>
                                : record.status === DataRequestStatus.DELETION ?  
                                    <><StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/> Deletion </>
                                : record.status === DataRequestStatus.DELETED ?  
                                    <><StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/> Deleted </>
                                :  <><InfoCircleOutlined style={{ fontSize: '16px', color: '#E49B0F' }}/> Respond </>}
                            </Button>
                        )}
                    />
                    <Column
                        title="Delete"
                        key="delete"
                        render={(_: any, record: any) => 
                            <Popconfirm
                                title="Delete the request"
                                description="Are you sure to delete this request?"
                                // onConfirm={confirm}
                                // onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button style={{border:'none'}} icon={<DeleteOutlined />}/>
                            </Popconfirm>}
                    />
                </Table>
            }
        </Card>
    )
}