import React, { useState, useEffect } from 'react';
import { List, Card, DatePicker, Table, Button, Popconfirm, message } from 'antd';

import { CheckCircleOutlined, StopOutlined, InfoCircleOutlined, EyeOutlined, DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { DataRequestStatus } from '../../utils/constants';

import { isMobileBrowser } from '../../utils/utils';

import dayjs from 'dayjs';

import axios from 'axios';

const { Column } = Table;

export const Shared: React.FC<{id: string | undefined | null, bearerToken: string, type: string | undefined | null, close: (() => void) | undefined }>  = (parent) => {
    const [views, setViews] = useState<any[]>([]);
    const [toDelete, setToDelete] = useState<any[]>([]);

    const [collapsed, setCollapsed] = useState<boolean>(isMobileBrowser());

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const update = () => {
        axios.get(
            `${azure_function_url}/Databoss?command=document_trace&id=${parent.id}&type=${parent.type}`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            setViews(x.data);
        });
    }

    useEffect(() => {
        update();
    },[parent.id]);

    return (
        <>
            {
                views.length > 0 ?
                <>
                    <br></br>
                    <Card 
                        bordered={false} 
                        title={'Access'}
                        extra={parent.close ? <Button icon={<CloseCircleOutlined/>} onClick={() => parent.close? parent.close() : {} }></Button> : undefined}
                    >
                    {
                        toDelete?.length > 0 ?
                        <>
                            <Popconfirm
                                title="Delete the request"
                                description="Are you sure to delete this request?"
                                onConfirm={() => {
                                    console.log(toDelete)
                                    toDelete.forEach(x => {
                                        // message.success(`Deletion request sent from ${x.companyname}`);
                                        axios.get(
                                            `${azure_function_url}/Databoss?command=data_request_request_deletion&id=${x.requestid}`,
                                            {
                                                maxContentLength: Number.POSITIVE_INFINITY,
                                                headers: {
                                                    'Authorization': parent.bearerToken
                                                }
                                            }
                                        ).then(() => {
                                            message.success(`Deletion request sent ${x.companyname}`);
                                            update();
                                        });
                                    })
                                }}
                                // onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button style={{border:'none'}} icon={<DeleteOutlined />}>Request Deletion</Button>
                            </Popconfirm>
                        </>
                        :
                        <></>
                    }
                    {
                        collapsed ?
                        <>
                        <Table 
                            rowSelection={{
                                type: 'checkbox',
                                onChange: (selectedRowKeys, selectedRows) => {
                                    setToDelete(selectedRows);
                                },
                            }}
                            dataSource={views?.map((x:any, i:number) => {
                                let y: any = x;
                                y.key = i;
                                return y;
                            })}
                            pagination={{hideOnSinglePage: true}}
                        >
                            <Column
                                title="Status"
                                key="status"
                                render={(_: any, record: any) => 
                                    record.status === DataRequestStatus.RESPONDED ? 
                                        <CheckCircleOutlined style={{ fontSize: '16px', color: '#52c41a' }} />
                                    : record.status === DataRequestStatus.DECLINED ?  
                                        <StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/>
                                    : record.status === DataRequestStatus.DELETION ?  
                                        <StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/>
                                    : record.status === DataRequestStatus.DELETED ?  
                                        <StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/>
                                    :  <InfoCircleOutlined style={{ fontSize: '16px', color: '#E49B0F' }}/>
                                }
                            />
                            <Column
                                title="Company"
                                key="companyname"
                                render={(_: any, record: any) => <>{record.companyname}<br></br>{new Date(Date.parse(record.date_expiry)).toLocaleString()}</>}
                            />
                            {/* <Column
                                title="Delete"
                                key="delete"
                                render={(_: any, record: any) => (
                                    <Popconfirm
                                        title="Delete the request"
                                        description="Are you sure to delete this request?"
                                        onConfirm={() => {
                                            message.success(`Deletion request sent from ${record.companyname}`);
                                            axios.get(
                                                `${azure_function_url}/Databoss?command=data_request_request_deletion&id=${record.requestid}`,
                                                {
                                                    maxContentLength: Number.POSITIVE_INFINITY,
                                                    headers: {
                                                        'Authorization': parent.bearerToken
                                                    }
                                                }
                                            ).then(() => {
                                                message.success(`Deletion request sent ${record.companyname}`);
                                                update();
                                            });
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button style={{border:'none'}} icon={<DeleteOutlined />}/>
                                    </Popconfirm>
                                )}
                            /> */}
                        </Table>
                        </>
                        :
                        <Table 
                            rowSelection={{
                                type: 'checkbox',
                                onChange: (selectedRowKeys, selectedRows) => {
                                    setToDelete(selectedRows);
                                },
                            }}
                            dataSource={views?.map((x:any, i:number) => {
                                let y: any = x;
                                y.key = i;
                                return y;
                            })}
                            pagination={{hideOnSinglePage: true}}
                        >
                            <Column
                                title="Status"
                                key="status"
                                render={(_: any, record: any) => 
                                    record.status === DataRequestStatus.RESPONDED ? 
                                        <CheckCircleOutlined style={{ fontSize: '16px', color: '#52c41a' }} />
                                    : record.status === DataRequestStatus.DECLINED ?  
                                        <StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/>
                                    : record.status === DataRequestStatus.DELETION ?  
                                        <StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/>
                                    : record.status === DataRequestStatus.DELETED ?  
                                        <StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/>
                                    :  <InfoCircleOutlined style={{ fontSize: '16px', color: '#E49B0F' }}/>
                                }
                            />
                            <Column
                                title="Expiry"
                                key="date"
                                render={(_: any, record: any) => 
                                    <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(Date.parse(record.date_expiry) / 1000)} />
                                }
                            />
                            
                            <Column
                                title="Company"
                                key="companyname"
                                render={(_: any, record: any) => record.companyname}
                            />
                            <Column
                                title="Delete"
                                key="delete"
                                render={(_: any, record: any) => (
                                    <Popconfirm
                                        title="Delete the request"
                                        description="Are you sure to delete this request?"
                                        onConfirm={() => {
                                            message.success(`Deletion request sent from ${record.companyname}`);
                                            axios.get(
                                                `${azure_function_url}/Databoss?command=data_request_request_deletion&id=${record.requestid}`,
                                                {
                                                    maxContentLength: Number.POSITIVE_INFINITY,
                                                    headers: {
                                                        'Authorization': parent.bearerToken
                                                    }
                                                }
                                            ).then(() => {
                                                message.success(`Deletion request sent ${record.companyname}`);
                                                update();
                                            });
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button style={{border:'none'}} icon={<DeleteOutlined />}/>
                                    </Popconfirm>
                                )}
                            />
                        </Table>
                    }
                    </Card>
                </>
                :
                <></>
            }
        </>
        
    )
}

export const Views: React.FC<{id: string | undefined | null, bearerToken: string, type: string | undefined | null, close: (() => void) | undefined }>  = (parent) => {
    const [views, setViews] = useState<any[]>([]);
    const [collapsed, setCollapsed] = useState<boolean>(isMobileBrowser());

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    useEffect(() => {
        axios.get(
            `${azure_function_url}/Databoss?command=document_views&id=${parent.id}&type=${parent.type}`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            setViews(x.data);
        });
    }, [parent.id]);
    

    return (
        <>
            {
                views.length > 0 ?
                <>
                <br></br>
                <Card 
                    bordered={false} 
                    title={'Views'}
                    extra={parent.close ? <Button icon={<CloseCircleOutlined/>} onClick={() => parent.close? parent.close() : {} }></Button> : undefined}
                >
                    {
                        collapsed ?
                        
                        <List 
                            itemLayout="horizontal"
                            pagination={{
                                onChange: (page) => {
                                  console.log(page);
                                },
                                pageSize: 3
                            }}

                            dataSource={views?.map((x, i: number) => {
                                let y: any = x;
                                y.key = i;
                                return y;
                            })}
                        
                            renderItem={x => 
                                <List.Item key={0}>
                                    <List.Item.Meta
                                        avatar={
                                            <EyeOutlined />
                                        }
                                        title={x.companyname}
                                        description={<>{x.employeeid} <br></br> {new Date(x.date).toLocaleString()}</>}
                                    /> 
                                </List.Item>
                            
                            }
                        />
                        
                        :
                        <Table 
                            dataSource={views?.map((x:any, i:number) => {
                                let y: any = x;
                                y.key = i;
                                return y;
                            })}
                            pagination={{hideOnSinglePage: true}}
                        >
                            
                            <Column
                                title="Date"
                                key="date"
                                render={(_: any, record: any) => 
                                    
                                    <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(Date.parse(record.date) / 1000)} />
                                }
                            />
                            <Column
                                title="Company"
                                key="companyname"
                                render={(_: any, record: any) => record.companyname}
                            />
                            <Column
                                title="Employee"
                                key="employeeid"
                                render={(_: any, record: any) => record.employeeid}
                            />
                        </Table>
                    }
                </Card>
                </>
                :
                <></>
            }
             
        </>
        
    )
}