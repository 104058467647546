import React, { useState, useEffect } from 'react';
import { Form, Checkbox, Typography, Space, Spin, Menu, Statistic, Layout, Button, theme, Card, Col, Row, Image } from 'antd';
import { CheckOutlined, UserOutlined, TeamOutlined, UndoOutlined, HomeOutlined, FileProtectOutlined, InboxOutlined, SendOutlined, OrderedListOutlined, WalletOutlined, ShoppingCartOutlined, LogoutOutlined, SafetyCertificateOutlined, SnippetsOutlined, FormOutlined } from '@ant-design/icons';

import axios from 'axios';

import type { MenuProps } from 'antd';

import { useMsal } from '@azure/msal-react';
import { loginRequest } from "../../utils/auth/authConfig";
import { msalInstance } from "../../index";


const { Content} = Layout;


export const Subscribe = () => {

    const { instance } = useMsal();

    const [bearerToken, setBearerToken] = useState('');
    const [products, setProducts] = useState<any[]>([]);

    const azure_function_url = process.env.AZURE_FUNCTION_URL;    

    useEffect(() => {
        const account = msalInstance.getActiveAccount();

        if(account){
            msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: account
            }).then(response => {
                const bearer = `Bearer ${response.accessToken}`;
                setBearerToken(bearer);
                axios.get(
                    `${azure_function_url}/NCID?command=payments_subcriptions`,
                    {
                        maxContentLength: Number.POSITIVE_INFINITY,
                        headers: {
                            'Authorization': bearer
                        }
                    }
                ).then(x => {
                    if(x.data.length == 0) {
                        axios.get(
                            `${azure_function_url}/NCID?command=payments_products`,
                            {
                                maxContentLength: Number.POSITIVE_INFINITY,
                                headers: {
                                    'Authorization': bearer
                                }
                            }
                        ).then(x => {
                            setProducts(x.data);
                        })
                    }
    
                })
            });

            
        }
    }, []);

    return (
        <Layout style={{ padding: '0 24px 24px' }}>
            <Content style={{ 
                padding: '50px', 
                paddingBottom: '75px',
                height:'100vmh'
                }}>
                     <Card 
                        bordered={false} 
                        title={'Please choose a subscription...'}
                        extra={<Button icon={<LogoutOutlined/>} onClick={() => instance.logoutRedirect() }></Button>}
                    >
                        <Row gutter={16}>
                            {products.sort((a,b) => a.prices[0].amount - b.prices[0].amount).map((product, i) => {
                                return (
                                <Col span={8} key={i}>
                                    <Card 
                                        title={product.name} 
                                        bordered={false}
                                    >
                                        <Statistic title={product.description} value={product.prices[0].amount} precision={2} />
                                        <br></br>
                                        <Button
                                            type="primary"
                                            icon={<SafetyCertificateOutlined />}
                                            onClick={() => {
                                                axios.post(
                                                    `${azure_function_url}/NCID?command=payments_subscribe&id=${product.prices[0].id}`,
                                                    {
                                                        redirect_url: window.location.protocol + "//" + window.location.hostname
                                                    },
                                                    {
                                                        maxContentLength: Number.POSITIVE_INFINITY,
                                                        headers: {
                                                            'Authorization': bearerToken
                                                        }
                                                    }
                                                ).then(x => {
                                                    window.location.replace(x.data.url);
                                                })
                                            }}
                                        >
                                            Purchase
                                        </Button>
                                    </Card>
                                </Col>
                                )
                            })}
                        
                        </Row>
                    </Card>
            </Content>
        </Layout>
    )
}