import React, { useState, useEffect } from 'react';
import { Switch, Form, Modal, InputNumber, Space, Select, List, Popconfirm, DatePicker, Table, Card, Button, message } from 'antd';

import { PlusOutlined, DeleteOutlined, UndoOutlined, CheckCircleOutlined, InfoCircleOutlined, StopOutlined } from '@ant-design/icons';

import { GuaranteeStatus, Currencies, GuaranteeType, DataRequestStatus } from '../../utils/constants';
// import { ViewGuarantee } from './ViewGuarantee';
// import { NewGuarantee } from './NewGuarantee';

import { Guarantee, Entity, DataRequestResult, EmployeeInvitations, ClientInvitations, UserUI } from '../../utils/data_types';

import { ethers } from 'ethers';
import { NotVault, Credentials, Schema, Tokens, Deals, Files, recoverSignature, Proof } from '@notcentralised/notvault-sdk';


// import { guarantees } from '../../utils/data';

import { parseJSON, isMobileBrowser, shortenString } from '../../utils/utils';

import dayjs from 'dayjs';

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const { RangePicker } = DatePicker;

const { Column } = Table;


const camelToWords = (s: string): string => {
    return s
        // Insert a space before all found uppercase characters.
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        // Convert the first character of each word to uppercase.
        .replace(/\b[a-z]/g, char => char.toUpperCase());
}

const unflattenObject = (flatObj: Record<string, any>): any => {
    const result: any = {};

    const assignValue = (obj: any, keys: string[], value: any) => {
        const key = keys.shift();

        if (!key) return;

        if (keys.length === 0) {
            if (key.includes('%')) { // handle string chunks
                const [actualKey, index] = key.split('%');
                obj[actualKey] = 'string';
            } else if(value) {
                obj[key] = value;
            }
        } else {
            if (!obj[key]) {
                // Check if the next key is numeric (for arrays) or not (for objects)
                const isNumeric = !isNaN(Number(keys[0]));
                obj[key] = isNumeric ? [] : {};
            }
            assignValue(obj[key], keys, value);
        }
    };

    for (const key in flatObj) {
        const keys = key.split('$');
        assignValue(result, keys, flatObj[key]);
    }

    return result;
}

type TreeNode = {
    key: string;
    title: any;
    type: any;
    children?: TreeNode[] | undefined;
};

const convertToTreeData = (obj: any, parentKey: string = ''): TreeNode[] => {
    const nodes: TreeNode[] = [];
    
    for (const key in obj) {
        const value = obj[key];
        const currentKey = parentKey ? `${parentKey}$${key}` : key;

        if (Array.isArray(value)) {
            const arrayNodes = value.map((item, index) => {
                const itemKey = `${currentKey}$${index}`;
                if (typeof item === 'object' && item !== null) {
                    return { key: itemKey, title: index.toString(), type: Array.isArray(item) ? 'array' : item, children: convertToTreeData(item, itemKey) };
                } else {
                    return { key: itemKey, title: index, type: Array.isArray(item) ? 'array' : item };
                }
            });
            nodes.push({ key: currentKey, title: key, type: Array.isArray(value) ? 'array' : value, children: arrayNodes });
        } else if (typeof value === 'object' && value !== null) {
            const children = convertToTreeData(value, currentKey);
            nodes.push({ key: currentKey, title: key, type: Array.isArray(value) ? 'array' : value, children });
            
        } else {
            nodes.push({ key: currentKey, title: key, type: Array.isArray(value) ? 'array' : value });
        }
    }

    return nodes;
};


export interface ISignaturesProps {
    user?: UserUI;
    bearerToken: string;
    type?: string;
}
export const Signatures: React.FC<ISignaturesProps> = (parent) => {
    
    const [viewGuarantee, setViewGuarantee] = useState<any>();
    const [viewNewGuarantee, setNewViewGuarantee] = useState(false);

    const [signatures, setSignatures] = useState<any[]>([]);
    const [allUsers, setAllUsers] = useState<any[]>();
    const [allDocs, setAllDocs] = useState<any[]>();
    const [allVersions, setAllVersions] = useState<any[]>();
    const [viewModal, setViewModal] = useState<any>();
    
    const [isMobile, _] = useState<boolean>(isMobileBrowser());

    const [loading, setLoading] = useState(true);

    const [form] = Form.useForm();
    
    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const { Option } = Select;
       
    const update = (loadingFlag?:boolean) => {
        if(loadingFlag === true)
            setLoading(true);

        axios.get(
            `${azure_function_url}/NCID?command=signature_list`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            const allData = parseJSON(x.data.sort((a:any,b:any) => Date.parse(b.created) - Date.parse(a.created)));

            setSignatures(allData);
            setAllUsers(unique(allData.map((x:any) => x.userid)));
            setAllDocs(unique(allData.map((x:any) => x.doc_id)));
            setAllVersions(unique(allData.map((x:any) => x.version)));

            if(loadingFlag === true)
                setLoading(false);
        });
    }

    useEffect(() => { 
        update(true);
    }, []);

    useEffect(() => {
        const id = setInterval(() => {
            if(viewGuarantee === undefined && !viewNewGuarantee)
                update();
        }, 10000 * 3); 

        return () => clearInterval(id); 
    // eslint-disable-next-line
    }, [viewGuarantee, viewNewGuarantee]);

    const unique = (arrayWithDuplicates: any[]) : any[] => {
        return arrayWithDuplicates.filter((obj, index, self) => 
            index === self.findIndex((el) => el === obj)
        )
    }
    
    return (
        // viewNewGuarantee ?
        //     <NewGuarantee bearerToken={parent.bearerToken} update={update} done={() => { setNewViewGuarantee(false); update(); }} user={parent.user}/>
        // :
        // viewGuarantee ?
        //     <ViewGuarantee bearerToken={parent.bearerToken} done={() => { setViewGuarantee(undefined); update(); }} doc={viewGuarantee} user={parent.user}/>
        // :
        signatures?.length === 0 ?
        <Card 
            bordered={false} 
            title={'Signatures'}
            extra={
                <Button style={{float:'right'}} icon={<UndoOutlined/>} onClick={() => update() }></Button> 
            }
            bodyStyle={{ padding: 0 }}
        ></Card>
        :
        signatures.length > 0 ?
        <>
        <Card 
            bordered={false} 
            title={'Signatures'}
            extra={
                <Space>
                    {/* <Button style={{float:'right'}} icon={<PlusOutlined/>} onClick={() => setNewViewGuarantee(true) }></Button>  */}
                    <Button style={{float:'right'}} icon={<UndoOutlined/>} onClick={() => update() }></Button> 
                </Space>
            }
        >
            {
                isMobile ?
                <>
                    <List 
                        itemLayout="horizontal"
                        pagination={{
                            onChange: (page) => {
                              console.log(page);
                            },
                            pageSize: 3
                        }}

                        dataSource={signatures?.map((x, i: number) => {                            
                            let y: any = Object.assign({}, x);
                            y.key = i;
                            return y;
                        })}
                        renderItem= {(x) => 
                                <List.Item key={x.key}>
                                    <List.Item.Meta
                                        avatar={
                                            x.status === DataRequestStatus.RESPONDED ? 
                                                <CheckCircleOutlined style={{ fontSize: '16px', color: '#52c41a' }} />
                                            : x.status === DataRequestStatus.DECLINED ?  
                                                <StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/>
                                            : x.status === DataRequestStatus.DELETION ?  
                                                <StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/>
                                            : x.status === DataRequestStatus.DELETED ?  
                                                <StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/>
                                            :  <InfoCircleOutlined style={{ fontSize: '16px', color: '#E49B0F' }}/>
                                        }
                                        title={
                                            <a 
                                                onClick={async () => { 
                                                    setViewGuarantee(x);
                                                    // axios.get(
                                                    //     `${azure_function_url}/Databoss?command=data_request_get&id=${x.id}`,
                                                    //     {
                                                    //         maxContentLength: Number.POSITIVE_INFINITY,
                                                    //         headers: {
                                                    //             'Authorization': parent.bearerToken
                                                    //         }
                                                    //     }
                                                    // ).then((data) => {
                                                    //     const doc: DataRequestResult = data.data;
                                                    //     if(x.status === DataRequestStatus.RESPONDED || x.status === DataRequestStatus.DELETION) 
                                                    //         setViewGuarantee(doc);
                                                        
                                                    //     else
                                                    //         setViewRequest(doc);
                                                    // });
                                            }}>
                                                {x.beneficiaries[0]?.name}
                                            </a>
                                        }
                                        description={<>{x.name} <br></br> {new Date((x.expiryDate)).toLocaleString()}</>}
                                    /> 
                                </List.Item>
                        }    
                    />
                </>
                :
                <Table
                    loading={loading}
                    dataSource={signatures?.map(x => {
                        let y: any = Object.assign({}, x);
                        y.key = x.id;
                        return y;
                    })}
                    pagination={{hideOnSinglePage: true, defaultPageSize: 5}}
                >
                    <Column
                        title="Id"
                        key="id"
                        render={(_: any, record: any) => 
                            <a onClick={()=>{
                                const data = JSON.parse(record.data);

                                const flatObj: Record<string, any> = {}
                                data.schema.fields.forEach((x: any) => {
                                    flatObj[x.id] = x.type;
                                });

                                const ufobj = unflattenObject(flatObj);
                                const treeObj = convertToTreeData(ufobj);

                                setViewModal({id: record.id, tree: treeObj});

                            }}>{shortenString(record.id, 5)}</a>
                        }
                    />
                    <Column
                        title="Reference"
                        key="reference"
                        filters={allUsers?.map((x: any) => { return { text: x, value: x }} )}
                        onFilter={(value: any, record) => record.reference_id === value}
                        
                        render={(_: any, record: any) => 
                            <a onClick={()=>{
                                const data = JSON.parse(record.data);

                                const flatObj: Record<string, any> = {}
                                data.schema.fields.forEach((x: any) => {
                                    flatObj[x.id] = x.type;
                                });

                                const ufobj = unflattenObject(flatObj);
                                const treeObj = convertToTreeData(ufobj);

                                setViewModal({id: record.id, tree: treeObj});
                            }}>{shortenString(record.reference_id, 20)}</a>
                        }
                    />
                    <Column
                        title="Document"
                        key="document"
                        filters={allDocs?.map(x => { return { text: x, value: x }} )}
                        onFilter={(value: any, record) => record.doc_id === value}
                        
                        render={(_: any, record: any) => record.doc_id}
                    />
                    <Column
                        title="Version"
                        key="version"
                        filters={allVersions?.map((x:any) => { return { text: x, value: x }} )}
                        onFilter={(value: any, record) => record.version === value}
                        
                        sorter={(a, b) => Number(a.version) - Number(b.version)}                        
                        render={(_: any, record: any) => `${record.version}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    />
                    
                    <Column
                        title="Created"
                        key="created"
                        sorter={(a, b) => a.created.getTime() - b.created.getTime()}                        
                        render={(_: any, record: any) => (
                            <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(record.created.getTime() / 1000)} />
                        )}
                    />
                    
                </Table>
            }
        </Card>

        <Modal width={800} title={"Signature"} onCancel={()=>{setViewModal(undefined)}} closable={true} open={viewModal} footer={null}>
            <Form
                name="issue"
                form={form}
                onFinish={async (values) => {
                    
                    // const query: Record<string, any> = {}
                    
                    // Object.keys(values).forEach(key => {
                    //     if(values[key]){
                            
                    //         if(key in viewModal.keys) {
                    //             console.log(key)
                    //             viewModal.keys[key].forEach((y: any) => query[y] = values[key]);
                    //         }
                    //         else if(Object.keys(values[key]).length === 2){
                    //             if(values[key].min && values[key].max)
                    //                 query[key] = values[key];
                    //             else if(Array.isArray(values[key]))
                    //                 query[key] = [0, 1].map(i => new Date(values[key][i].unix() * 1000).toISOString());
                    //         }
                    //         else
                    //             query[key] = values[key];
                    //     }
                    // });

                    // console.log('d', viewModal)
                    // console.log('values', values)
                    // console.log('query', query)

                    console.log('values', values)
                    const _filteredObject = JSON.parse(JSON.stringify(values))
                    const filteredObject: Record<string, any> = {};

                    Object.keys(_filteredObject).forEach(key => {
                        const value = _filteredObject[key];
                        
                        if(!(typeof value === 'object' && Object.keys(value).length === 0))

                            filteredObject[key] = value;

                    })

                    console.log('----- FIT', filteredObject)

                    const salt = uuidv4();

                    axios.post(
                        `${azure_function_url}/NCID?command=signature_prove`,
                        {
                            id: viewModal?.id,
                            salt: salt,
                            query: filteredObject
                        },
                        {
                            maxContentLength: Number.POSITIVE_INFINITY,
                            headers: {
                                'Authorization': parent.bearerToken
                            }
                        }
                    ).then(async x => {

                        console.log(x)

                        const result = x.data;

                        const vault = new NotVault();
                        vault.init();
                        
                        const wallet = ethers.Wallet.createRandom();
                        await vault.enterData(wallet.address, wallet.privateKey, 'user');

                        const credentials = new Credentials(vault);


                        const verification = await credentials.verify({
                            id: 'd976f819-abe9-472e-adf0-f2044bc36c74',
                            contract: {
                                // amount: 10000,
                                id: '1234-01234'
                            },
                            status: 'Review',
                            type: 'Advance Payment Guarantee',
                            // currency: 'AUD',
                            // amount: 10000,
                            beneficiaries: [{id: 'b2', name: 'NSW | Government of New South Wales'}]
                        }, salt, result.proof as Proof)//, recovery.signer);
                        console.log('Verf: ', verification);

                    });

                    //http://localhost:3000/zkp/alphaNumericalData_verification_key.json

                    
                    
    
                    
    
                }}
                labelCol={{ span: 8 }}
                initialValues={{}}
            >
                <Table
                    columns={[
                        {
                            title: 'Name',
                            dataIndex: 'title',
                            key: 'title',
                            render: (_: any, record: any) => (camelToWords(record.title))
                        },
                        {
                            title: 'Filter',
                            dataIndex: 'key',
                            key: 'key',
                            render: (_:any, record: any) => (
                                
                                record.type === 'number' ? 
                                    <Space.Compact>
                                        <Form.Item
                                            name={[record.key, 'min']}
                                            noStyle      
                                        >
                                            <InputNumber
                                                // disabled={query !== undefined}
                                                min={0} 
                                                style={{ width: '100%' }}
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name={[record.key, 'max']}
                                            noStyle
                                        >
                                            <InputNumber
                                                // disabled={query !== undefined}
                                                min={0} 
                                                style={{ width: '100%' }}
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            />
                                        </Form.Item>
                                    </Space.Compact>
                
                                :
                                record.type === 'date' ?
                                    <Form.Item
                                        name={record.key}
                                        // name={['min']}
                                        noStyle      
                                    >
                                        <RangePicker 
                                            // disabled={query !== undefined}
                                            style={{ width: '100%' }}
                                            showTime 
                                            format="YYYY-MM-DD HH:mm:ss" />
                                    </Form.Item>
                                :
                                record.type === 'string' ? 
                                    <Form.Item
                                        name={record.key}
                                        // name={['min']}
                                        noStyle      
                                    >
                                        <Switch />
                                    </Form.Item>
                                :
                                <></>
                            )
                        }
                    ]}
                    dataSource={viewModal?.tree}
                    pagination={false}
                />
                <br></br>
                <Form.Item>
                    <Button 
                        style={{
                            position: "absolute",
                            width: "120px",
                            left: 0,
                            transform: "translateY(-50%)"
                        }} 
                        onClick={() => {
                            // setProof(undefined);
                            // setQuery(undefined);
                            // setLoading(false); 
                            // data.closeModal(); 
                            form.resetFields();
                            setViewModal(undefined);
                        }}
                        disabled={false}
                    >
                        Cancel
                    </Button>
                    <Button 
                        type="primary"
                        htmlType="submit"
                        // loading={isLoading}
                        style={{
                            position: "absolute",
                            right: 0,
                            width: "120px",
                            transform: "translateY(-50%)"
                        }} 
                    >
                        Generate
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
        </>
        : <></>
    )
}