import React, { useState, useEffect } from 'react';
import { Upload, Modal, Select, Divider, Button, Form, Input, Space, FormInstance, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';

import axios from 'axios';

import { UserUI } from '../../utils/data_types';

import { isMobileBrowser } from '../../utils/utils';

export interface IFileUploadsProps {
    user?: UserUI;
    bearerToken: string;
    form: FormInstance;
    name: string[];
}

export const FileUploads: React.FC<IFileUploadsProps> = (parent) => {
    const { Option } = Select;

    const { Dragger } = Upload;

    const [newModal, setNewModal] = useState(false);
    const [files, setFiles] = useState<{ id: string, name: string }[]>([]);

    const [collapsed, setCollapsed] = useState<boolean>(isMobileBrowser());

    const [form] = Form.useForm();

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const update = () => axios.get(
        `${azure_function_url}/Databoss?command=files_uploads_list`,
        {
            maxContentLength: Number.POSITIVE_INFINITY,
            headers: {
                'Authorization': parent.bearerToken
            }
        }
    ).then((x) => {
        console.log(x.data)
        setFiles(x.data);
    });

    useEffect(() => {
        update(); 
    }, []);

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        customRequest: async (options: any) => { 
            
            // eslint-disable-next-line
            const { onSuccess, onError, file, onProgress } = options;
            const buffer = await (file as File).arrayBuffer();
            const bytes = new Uint8Array(buffer);

            const fmData = new FormData();

            fmData.append("file", new Blob([bytes], {type:file.type}), file.name);

            await axios.post(
                `${azure_function_url}/Databoss?command=files_uploads_new`,
                fmData,
                {
                    maxContentLength: Number.POSITIVE_INFINITY,
                    headers: {
                        "Content-Type": `multipart/form-data; boundery=${(fmData as any)._boundary}`,
                        'Authorization': parent.bearerToken
                    },
                }
            );

            if(onSuccess){
                onSuccess("Ok");
                update();
                setNewModal(false);
            }            
        },
        onChange: async (info) => {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            }
            else if (status === 'removed') {
                message.success(`${info.file.name} file removed successfully.`);
            }
            else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };
    
    return (
    <>
        <Select
            style={{ width: '100%' }}
            optionLabelProp="label"
            placeholder={'Pick an account'}
            value={parent.form.getFieldValue(parent.name)}

            onChange={(value) => {
                if(value === 'new'){
                    setNewModal(true);
                }
                else{
                    const _value = parent.form.getFieldValue([parent.name[0]]);
                    _value[parent.name[1]] = value;
                    parent.form.setFieldsValue(_value);
                    
                    setNewModal(false);
                }
            }}
        >
            
            {
                files
                .map((template, i) => 
                    <Option value={template.id} label={`${template.name}`} key={i}>
                        <Space>
                            {template.name}
                        </Space>
                    </Option>
                ).concat([
                    <Option value={'new'} label={'New'} key={10}>    
                        <Divider orientation="left" plain></Divider>
                        <Space>
                            New Files
                        </Space>
                    </Option>
                ])                
            }
        </Select>

        <Modal title={"New File"} closable={false} open={newModal} footer={null}>
            <Form
                    form={form}
                    name="new_file"
                    style={{ maxWidth: 600 }}
                    autoComplete="off"
                >
                    <Form.Item name="file" rules={[{ required: true }]}>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                            <UploadOutlined />
                            </p>
                            <p className="ant-upload-text">{ collapsed ? 'Click to add a file' : 'Click or drag a file'}</p>
                            {/* <p className="ant-upload-hint">
                            Only pdf files please.
                            </p> */}
                        </Dragger>
                    </Form.Item>
                    <Form.Item>
                        <Button block htmlType="button" onClick={()=>{form.resetFields(); setNewModal(false)}}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Form> 
        </Modal>
    </>
    )
}