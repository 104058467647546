import React, { useState, useReducer, useEffect } from 'react';
import { Modal, Timeline, Space, Radio, Table, Slider, DatePicker, Popconfirm, Typography, Row, Col, List, Card, Button, message, Image } from 'antd';
import type { SliderMarks } from 'antd/es/slider';
import { CloseCircleOutlined, LeftOutlined, RightOutlined, DeleteOutlined } from '@ant-design/icons';

// import { FileViewer } from './FileViewer';
// import { EditGuarantee } from './EditGuarantee';

import { VerifiedDocumentTypes, CustomDataTypes } from '../../utils/constants';

import { UserUI } from '../../utils/data_types';

import { isMobileBrowser, shortenString, parseJSON } from '../../utils/utils';

import dayjs from 'dayjs';

import axios from 'axios';
import Paragraph from 'antd/es/skeleton/Paragraph';

const { Column } = Table;

export interface IViewKeyProps {
    user?: UserUI;
    bearerToken: string;
    doc: any;
    done: () => void;
}
export const ViewKey: React.FC<IViewKeyProps> = (parent) => {

    const { Title, Paragraph } = Typography;

    const [viewItem, setViewItem] = useState<any>();
    const [editGuarantee, setEditGuarantee] = useState(false);
    const [viewFile, setViewFile] = useState<any>();
    const [viewModal, setViewModal] = useState<any>();
    const [history, setHistory] = useState<any[]>([]);
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const [isMobile, _] = useState<boolean>(isMobileBrowser());

    const azure_function_url = process.env.AZURE_FUNCTION_URL;
    
    return (        
        <Card 
            title={'Key'}
            extra={
                <Space>
                    <Button style={{float:'right'}} icon={<LeftOutlined />} onClick={() => parent.done() }></Button> 
                </Space>
            }
        >
            <Title level={3} type="secondary">{parent.doc.name}</Title>
            <Paragraph >{parent.doc.description}</Paragraph>
        </Card>
    )
}