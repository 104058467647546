import React, { useState } from 'react';
import { Form, Checkbox, Typography, Layout, Button, Card } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import axios from 'axios';

import { isMobileBrowser } from '../../utils/utils';

const { Title, Paragraph } = Typography;

const { Content } = Layout;

export interface ICheckAccessProps {
    bearerToken: string;
    update: () => void;
}
export const TermsConditions: React.FC<ICheckAccessProps> = (parent) => {

    const [collapsed, setCollapsed] = useState<boolean>(isMobileBrowser());

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    return (
        <Layout style={{ padding: '0 24px 24px' }}>
            <Content style={{ 
                padding: collapsed ? '0px' : '150px 50px', 
                height:'100vmh',
                display: 'flex',
                justifyContent: 'center',
                }}>
                    <Card 
                        bordered={false} 
                        style={{
                            width: collapsed ? '98%' : '75%',
                            padding: collapsed ? '0px' : '0 50px 0 50px'
                        }}
                    >
                        <Typography>
                            <Title>Terms and Conditions</Title>
                            <Paragraph>
                            Last updated: [Date]

                            1. Introduction

                            Welcome to [App Name]! These Terms and Conditions ("Terms", "Terms and Conditions") govern your use of the [App Name] mobile application and website (collectively referred to as the "Service") operated by [Company Name].

                            By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you do not have permission to access the Service.

                            2. User Accounts

                            When you create an account with us, you guarantee that the information you provide is accurate, complete, and current at all times. Failure to provide accurate information constitutes a breach of the Terms and may result in termination of your account.

                            3. Termination

                            We reserve the right to terminate or suspend your account immediately, without prior notice or liability, if you breach the Terms.

                            4. Prohibitions

                            You may not use the Service to engage in any illegal activities or to solicit any illegal acts. You also agree not to introduce malicious software or attempt to breach the Service's security.

                            5. Intellectual Property

                            The Service and its original content, features, and functionality are owned by [Company Name] and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.

                            6. Limitation of Liability

                            [Company Name], its affiliates, and its employees shall not be held liable for any indirect, consequential, or punitive damages arising out of or relating to your use of the Service.

                            7. Governing Law

                            These Terms shall be governed and construed in accordance with the laws of [Country/State], without regard to its conflict of law provisions.

                            8. Changes to the Terms

                            We reserve the right to modify or replace these Terms at any time. We will make reasonable efforts to provide at least [30 days] notice prior to any new terms taking effect.

                            9. Contact Us

                            If you have questions about these Terms, please contact us at [Email Address].
                            </Paragraph>
                        </Typography>
                        <br></br>

                        <Form
                            name="register"
                            onFinish={() => {
                                axios.get(
                                    `${azure_function_url}/NCID?command=id_accept_terms`,
                                    {
                                        maxContentLength: Number.POSITIVE_INFINITY,
                                        headers: {
                                            'Authorization': parent.bearerToken
                                        }
                                    }
                                ).then(parent.update);
                            }}
                            scrollToFirstError
                        >
                            <Form.Item
                                name="agreement"
                                valuePropName="checked"
                                rules={[
                                {
                                    validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject(new Error('Please accept the terms and conditions')),
                                },
                                ]}
                            >
                                <Checkbox>
                                I have read the the terms and conditions above
                                </Checkbox>
                            </Form.Item>

                            <Form.Item>
                                <Button icon={<CheckOutlined />} type="primary" htmlType="submit">Accept</Button>
                            </Form.Item>
                        </Form>
                    </Card>
            </Content>
        </Layout>
    );
}