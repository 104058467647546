import React, { useState, useEffect } from 'react';
import { Space, DatePicker, Table, Popconfirm, Card, Button, message } from 'antd';

import { QuestionCircleOutlined, LoadingOutlined, EyeOutlined, DeleteOutlined, CloseCircleOutlined, SafetyCertificateOutlined, UndoOutlined, UsergroupAddOutlined, CheckCircleOutlined, StopOutlined } from '@ant-design/icons';

import { NewClient } from './NewClient';
import { ViewClient } from './ViewClient';

import { BadgeTypes, InvitationStatus } from '../../utils/constants';
import { UserUI, ClientLists } from '../../utils/data_types';

import dayjs from 'dayjs';

import axios from 'axios';

import { parseJSON } from '../../utils/utils';
import { relative } from 'path';

const { Column } = Table;

export interface IClientsProps {
    user?: UserUI;
    bearerToken: string;
}
export const Clients: React.FC<IClientsProps> = (parent) => {
    
    const [clients, setClients] = useState<ClientLists[]>();
    const [viewNewClient, setViewNewClient] = useState(false);
    const [viewClient, setViewClient] = useState<ClientLists>();

    const [loading, setLoading] = useState(true);

    const azure_function_url = process.env.AZURE_FUNCTION_URL;
    
    const update = () => {
        setLoading(true);
        axios.post(
            `${azure_function_url}/NCID?command=clients_list`,
            {
                entityid: parent.user?.entity?.id
            },
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then((x: any) => {
            const res: ClientLists[] = parseJSON(x.data);
            setClients(res);
            setLoading(false);
        });
    }

    useEffect(() => { 
        update();
    }, []);
    
    
    return (
        viewClient ?
            <ViewClient 
                bearerToken={parent.bearerToken} 
                update={update} 
                done={() => { update(); setViewClient(undefined); }} 
                client={viewClient} 
                user={parent.user}/>
        :
        <Card 
            title={'Clients'}
            extra={
                    <Space>
                    {
                        viewNewClient !== true ? 
                        <Button style={{float:'right'}} icon={<UsergroupAddOutlined/>} onClick={() => setViewNewClient(true) }></Button> 
                        : 
                        <></>
                    }
                        <Button style={{float:'right'}} icon={<UndoOutlined/>} onClick={() => update() }></Button> 
                    </Space>
                }
        >
            { viewNewClient === true ?
                <NewClient bearerToken={parent.bearerToken} done={() => { update();  setViewNewClient(false)}} user={parent.user} />
            :        
                <Table 
                    loading={loading}
                    dataSource={clients?.map((x: any, i: number) => {
                        let y: any = x;
                        y.key = i;
                        return y;
                    })}
                    pagination={{hideOnSinglePage: true}}
                >
                    <Column
                        title="Badge"
                        key="badge"
                        render={(_: any, record: any) => 
                            record.badge === BadgeTypes.HIGH ?
                                <SafetyCertificateOutlined style={{  left: '15px', position: 'relative', fontSize: '18px', color: '#52c41a' }} />
                                
                            : record.badge === BadgeTypes.LOW ?
                                <CheckCircleOutlined />
                            : record.badge === BadgeTypes.INVALID ?
                                <CloseCircleOutlined style={{ left: '15px', position: 'relative', fontSize: '18px', color: '#FF0000' }} />
                            : 
                                <QuestionCircleOutlined style={{ left: '15px', position: 'relative', fontSize: '18px', color: '#E49B0F' }} />
                        }
                    />
                    <Column
                        title="Email"
                        key="email"
                        render={(_: any, record: any) => record.id}
                    />
                    <Column
                        title="First"
                        key="first"
                        render={(_: any, record: any) => record.data?.personal.name.firstName}
                    />
                    <Column
                        title="Last"
                        key="last"
                        render={(_: any, record: any) => record.data?.personal.name.lastName}
                    />
                    
                    <Column
                        title="Added"
                        key="date"
                        render={(_: any, record: any) => (
                            <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(Date.parse(record.date_created) / 1000)} />
                        )}
                    />
                    <Column
                        title="Action"
                        key="action"
                        render={(_: any, record: any) => (
                            record.status === InvitationStatus.WAITING ?
                            <>
                            <Popconfirm
                                title="Remind the client"
                                description="Are you sure to remind this client?"
                                onConfirm={async () => { 
                                    message.success('email sent...');
                                    // axios.get(
                                    //     `${azure_function_url}/Databoss?command=data_request_get&id=${record.id}`,
                                    //     {
                                    //         maxContentLength: Number.POSITIVE_INFINITY,
                                    //         headers: {
                                    //             'Authorization': parent.bearerToken
                                    //         }
                                    //     }
                                    // ).then((data) => {
                                    //     setViewResponse(data.data);
                                    // });
                                } }
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button 
                                    type="default" 
                                    block
                                    icon={<LoadingOutlined style={{ fontSize: '16px' }}/>}
                                >
                                    Remind
                                </Button>
                            </Popconfirm>
                            </>
                            :
                            <Button 
                                type="default" 
                                block
                                onClick={async () => { 

                                    if(record.status === InvitationStatus.DECLINED){
                                        axios.post(
                                            `${azure_function_url}/NCID?command=clients_invite`,
                                            {
                                                companyid: parent.user?.entity?.id,
                                                employeeid: record.id
                                            },
                                            {
                                                maxContentLength: Number.POSITIVE_INFINITY,
                                                headers: {
                                                    'Authorization': parent.bearerToken
                                                }
                                            }
                                        ).then(x => {
                                            message.success('Invitation sent')
                                            update();
                                        });
                                    }
                                    else{
                                        setViewClient(record);
                                    }
                                } }
                            >
                                {record.status === InvitationStatus.ACCEPTED ? 
                                    <><EyeOutlined style={{ fontSize: '16px' }} /> View </> 
                                : record.status === InvitationStatus.DECLINED ?
                                    <><StopOutlined style={{ fontSize: '16px' }}/> Declined </>
                                : 
                                    <><LoadingOutlined style={{ fontSize: '16px' }}/> Remind </>}
                            </Button>
                        )}
                    />
                    <Column
                        title="Delete"
                        key="delete"
                        render={(_: any, record: any) => 
                            <Popconfirm
                                title="Delete the client"
                                description="Are you sure to delete this client?"
                                // onConfirm={confirm}
                                // onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button style={{border:'none'}} icon={<DeleteOutlined />}/>
                            </Popconfirm>}
                    />
                </Table>
            }
        </Card>
    )
}