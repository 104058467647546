import React, { useState, useEffect } from 'react';
import { InputNumber, Space, Select, List, Popconfirm, DatePicker, Table, Card, Button, message } from 'antd';

import { PlusOutlined, DeleteOutlined, UndoOutlined, CheckCircleOutlined, InfoCircleOutlined, StopOutlined } from '@ant-design/icons';

import { GuaranteeStatus, Currencies, GuaranteeType, DataRequestStatus } from '../../utils/constants';
import { ViewGuarantee } from './ViewGuarantee';
import { NewGuarantee } from './NewGuarantee';

import { GeneralDocument, Guarantee, Entity, DataRequestResult, EmployeeInvitations, ClientInvitations, UserUI } from '../../utils/data_types';

// import { guarantees } from '../../utils/data';

import { parseJSON, isMobileBrowser, shortenString } from '../../utils/utils';

import dayjs from 'dayjs';

import axios from 'axios';

const { Column } = Table;

export interface IGuaranteesProps {
    user?: UserUI;
    bearerToken: string;
    type?: string;
}
export const Guarantees: React.FC<IGuaranteesProps> = (parent) => {
    
    const [viewGuarantee, setViewGuarantee] = useState<any>();
    const [viewNewGuarantee, setNewViewGuarantee] = useState(false);

    const [guarantees, setGuarantees] = useState<any[]>([]);
    
    const [allApplicants, setAllApplicants] = useState<any[]>();
    const [allBeneficiaries, setAllBeneficiaries] = useState<any[]>();
    const [allIssuers, setAllIssuers] = useState<any[]>();

    const [isMobile, _] = useState<boolean>(isMobileBrowser());

    const [loading, setLoading] = useState(true);
    
    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const { Option } = Select;
       
    const update = (loadingFlag?:boolean) => {
        if(loadingFlag === true)
            setLoading(true);

        axios.get(
            parent.type ? `${azure_function_url}/NFGX?command=guarantee_list&type=${parent.type}` : `${azure_function_url}/NFGX?command=guarantee_list`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            const data : GeneralDocument[] = parseJSON(x.data.sort((a:any,b:any) => Date.parse(b.creationDate) - Date.parse(a.creationDate)));
            
            setGuarantees(data);
            setAllApplicants(unique(data.map(x => x.guarantee.applicants).flat()));
            setAllBeneficiaries(unique(data.map(x => x.guarantee.beneficiaries).flat()));
            setAllIssuers(unique(data.map(x => x.guarantee.issuers).flat()));
            
            if(loadingFlag === true)
                setLoading(false);
        });
    }

    useEffect(() => { 
        update(true);
    }, []);

    useEffect(() => {
        const id = setInterval(() => {
            if(viewGuarantee === undefined && !viewNewGuarantee)
                update();
        }, 1000 * 3); 

        return () => clearInterval(id); 
    // eslint-disable-next-line
    }, [viewGuarantee, viewNewGuarantee]);

    const unique = (arrayWithDuplicates: any[]) : any[] => {
        return arrayWithDuplicates.filter((obj, index, self) => 
            index === self.findIndex((el) => el.id === obj.id)
        )
    }
    
    return (
        viewNewGuarantee ?
            <NewGuarantee bearerToken={parent.bearerToken} update={update} done={() => { setNewViewGuarantee(false); update(); }} user={parent.user}/>
        :
        viewGuarantee ?
            <ViewGuarantee bearerToken={parent.bearerToken} done={() => { setViewGuarantee(undefined); update(); }} doc={viewGuarantee.doc} user={parent.user}/>
        :
        guarantees?.length === 0 ?
        <Card 
            bordered={false} 
            title={(parent.type === 'new' ? '' : parent.type === 'applicants' ? 'Applicant' : parent.type ===  'issuers' ? 'Issuer' : 'Beneficiary') + ' Guarantees'}
            extra={
                <Space>
                    <Button style={{float:'right'}} icon={<PlusOutlined/>} onClick={() => setNewViewGuarantee(true) }></Button> 
                    <Button style={{float:'right'}} icon={<UndoOutlined/>} onClick={() => update() }></Button> 
                </Space>
            }
            bodyStyle={{ padding: 0 }}
        ></Card>
        :
        (guarantees.length > 0 || parent.type === 'new') ?
        <Card 
            bordered={false} 
            title={(parent.type === 'new' ? '' : parent.type === 'applicants' ? 'Applicant' : parent.type ===  'issuers' ? 'Issuer' : 'Beneficiary') + ' Guarantees'}
            extra={
                <Space>
                    <Button style={{float:'right'}} icon={<PlusOutlined/>} onClick={() => setNewViewGuarantee(true) }></Button> 
                    <Button style={{float:'right'}} icon={<UndoOutlined/>} onClick={() => update() }></Button> 
                </Space>
            }
        >
            {
                isMobile ?
                <>
                    <List 
                        itemLayout="horizontal"
                        pagination={{
                            onChange: (page) => {
                              console.log(page);
                            },
                            pageSize: 3
                        }}

                        dataSource={guarantees?.map((x, i: number) => {
                            let y: any = Object.assign({}, x);
                            y.key = i;
                            y.__raw = x;
                            return y;
                        })}
                        renderItem= {(x) => 
                                <List.Item key={x.key}>
                                    <List.Item.Meta
                                        avatar={
                                            x.status === DataRequestStatus.RESPONDED ? 
                                                <CheckCircleOutlined style={{ fontSize: '16px', color: '#52c41a' }} />
                                            : x.status === DataRequestStatus.DECLINED ?  
                                                <StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/>
                                            : x.status === DataRequestStatus.DELETION ?  
                                                <StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/>
                                            : x.status === DataRequestStatus.DELETED ?  
                                                <StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/>
                                            :  <InfoCircleOutlined style={{ fontSize: '16px', color: '#E49B0F' }}/>
                                        }
                                        title={
                                            <a 
                                                onClick={async () => { 
                                                    setViewGuarantee(x.__raw);
                                            }}>
                                                {x.beneficiaries[0]?.name}
                                            </a>
                                        }
                                        description={<>{x.name} <br></br> {new Date((x.expiryDate)).toLocaleString()}</>}
                                    /> 
                                </List.Item>
                        }    
                    />
                </>
                :
                <Table
                    loading={loading}
                    dataSource={guarantees?.map((x, i) => {
                        let y: any = Object.assign({}, x);
                        y.key = i;
                        y.__raw = x;
                        return y;
                    })}
                    pagination={{hideOnSinglePage: true, defaultPageSize: 5}}
                >
                    <Column
                        title="Status"
                        key="status"
                        filters={Object.values(GuaranteeStatus).map(x => { return { text: x, value: x }} )}
                        onFilter={(value: any, record) => record.status === value}
                        
                        render={(_: any, record: any) =>record.status}
                    />
                    <Column
                        title="Id"
                        key="id"
                        render={(_: any, record: any) => 
                            <a onClick={()=>{
                                setViewGuarantee({ doc: record.__raw });                                
                            }}>{shortenString(record.id, 5)}</a>
                        }
                    />
                    <Column
                        title="Type"
                        key="type"
                        filters={Object.values(GuaranteeType).map(x => { return { text: x, value: x }} )}
                        onFilter={(value: any, record) => record.type === value}
                        
                        render={(_: any, record: any) => 
                            <a onClick={()=>{
                                setViewGuarantee({ doc: record.__raw });
                            }}>{shortenString(record.guarantee.type, 20)}</a>
                        }
                    />
                    <Column
                        title="Currency"
                        key="currency"
                        filters={Object.values(Currencies).map(x => { return { text: x, value: x }} )}
                        onFilter={(value: any, record) => record.guarantee.currency === value}
                        
                        render={(_: any, record: any) => record.guarantee.currency}
                    />
                    <Column
                        title="Amount"
                        key="amount"
                        sorter={(a, b) => Number(a.guarantee.amount) - Number(b.guarantee.amount)}                        
                        render={(_: any, record: any) => `${record.guarantee.amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    />
                    {parent.type === 'applicants' ?
                    <></>
                    :
                    <Column
                        title="Applicants"
                        key="applicant"
                        filters={allApplicants?.map(x => { return { text: x.name, value: x.id }} )}
                        onFilter={(value: any, record) => record.guarantee.applicants.map((x:any)=>x.id).indexOf(value) === 0}
                        render={(_: any, record: any) => 
                            record.guarantee.applicants.length > 1 ?
                            <Select
                                style={{ width: '100%' }}
                                optionLabelProp="label"
                                placeholder={'Pick a type'}
                                value={record.guarantee.applicants[0].id}
                            >
                                {
                                    record.guarantee.applicants.map((template:any, i:number) => 
                                        <Option value={template.id} label={template.name} key={i}>
                                            <Space>
                                                {template.name}
                                            </Space>
                                        </Option>
                                    )
                                }
                            </Select>
                            :
                            shortenString(record.guarantee.applicants[0]?.name)
                        }
                    />
                    }
                    {parent.type === 'beneficiaries' ?
                    <></>
                    :
                    <Column
                        title="Beneficiaries"
                        key="beneficiaries" 
                        filters={allBeneficiaries?.map(x => { return { text: x.name, value: x.id }} )}
                        onFilter={(value: any, record) => record.guarantee.beneficiaries.map((x:any)=>x.id).indexOf(value) === 0}
                        render={(_: any, record: any) => 
                            record.guarantee.beneficiaries.length > 1 ?
                            <Select
                                style={{ width: '100%' }}
                                optionLabelProp="label"
                                placeholder={'Pick a type'}
                                value={record.guarantee.beneficiaries[0].id}
                            >
                                {
                                    record.guarantee.beneficiaries.map((template:any, i:number) => 
                                        <Option value={template.id} label={template.name} key={i}>
                                            <Space>
                                                {template.name}
                                            </Space>
                                        </Option>
                                    )
                                }
                            </Select>
                            :
                            shortenString(record.guarantee.beneficiaries[0]?.name)
                        }
                    />
                    }
                    {parent.type === 'issuers' ?
                    <></>
                    :
                    <Column
                        title="Issuers"
                        key="issuer" 
                        filters={allIssuers?.map(x => { return { text: x.name, value: x.id }} )}
                        onFilter={(value: any, record) => record.guarantee.issuers.map((x:any)=>x.id).indexOf(value) === 0}
                        render={(_: any, record: any) => 
                            record.guarantee.issuers.length > 1 ?
                            <Select
                                style={{ width: '100%' }}
                                optionLabelProp="label"
                                placeholder={'Pick a type'}
                                value={record.guarantee.issuers[0]?.id}
                            >
                                {
                                    record.guarantee.issuers.map((template:any, i:number) => 
                                        <Option value={template.id} label={template.name} key={i}>
                                            <Space>
                                                {template.name}
                                            </Space>
                                        </Option>
                                    )
                                }
                            </Select>
                            :
                            record.guarantee.issuers[0]?.name
                        }
                    />
                    }
                    <Column
                        title="Expiry"
                        key="expiry"
                        sorter={(a, b) => Date.parse(a.guarantee.expiryDate) - Date.parse(b.guarantee.expiryDate)}                        
                        render={(_: any, record: any) => (
                            <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(Date.parse(record.guarantee.expiryDate) / 1000)} />
                        )}
                    />
                    // <Column
                    //     title="Action"
                    //     key="action"
                    //     render={(_: any, record: any) => (
                    //         record.employee_invitation ? 
                    //         <Popconfirm
                    //             title="Accept or Decline Invitation"
                    //             description="Would you like to accept of decline the Inviation?"
                    //             onConfirm={() => {
                    //                 axios.post(
                    //                     `${azure_function_url}/Databoss?command=employees_accept`,
                    //                     {
                    //                         companyid: record.companyid,
                    //                     },
                    //                     {
                    //                         maxContentLength: Number.POSITIVE_INFINITY,
                    //                         headers: {
                    //                             'Authorization': parent.bearerToken
                    //                         }
                    //                     }
                    //                 ).then(x => {
                    //                     message.success('Employee invitation accepted');
                    //                     update();
                    //                 });

                    //             }}
                    //             onCancel={() => {
                    //                 axios.post(
                    //                     `${azure_function_url}/Databoss?command=employees_decline`,
                    //                     {
                    //                         companyid: record.companyid,
                    //                     },
                    //                     {
                    //                         maxContentLength: Number.POSITIVE_INFINITY,
                    //                         headers: {
                    //                             'Authorization': parent.bearerToken
                    //                         }
                    //                     }
                    //                 ).then(x => {
                    //                     message.success('Employee invitation declined');
                    //                     update();
                    //                 });

                    //             }}
                    //             okText="Accept"
                    //             cancelText="Decline"
                    //         >
                    //             <Button block icon={<InfoCircleOutlined />}>Respond </Button>
                    //         </Popconfirm>
                    //         : record.client_invitation ? 
                    //         <Popconfirm
                    //             title="Accept or Decline Invitation"
                    //             description="Would you like to accept of decline the Inviation?"
                    //             onConfirm={() => {
                    //                 axios.post(
                    //                     `${azure_function_url}/Databoss?command=clients_accept`,
                    //                     {
                    //                         companyid: record.companyid,
                    //                     },
                    //                     {
                    //                         maxContentLength: Number.POSITIVE_INFINITY,
                    //                         headers: {
                    //                             'Authorization': parent.bearerToken
                    //                         }
                    //                     }
                    //                 ).then(x => {
                    //                     message.success('Client invitation accepted');
                    //                     update();
                    //                 });

                    //             }}
                    //             onCancel={() => {
                    //                 axios.post(
                    //                     `${azure_function_url}/Databoss?command=clients_decline`,
                    //                     {
                    //                         companyid: record.companyid,
                    //                     },
                    //                     {
                    //                         maxContentLength: Number.POSITIVE_INFINITY,
                    //                         headers: {
                    //                             'Authorization': parent.bearerToken
                    //                         }
                    //                     }
                    //                 ).then(x => {
                    //                     message.success('Client invitation declined');
                    //                     update();
                    //                 });

                    //             }}
                    //             okText="Accept"
                    //             cancelText="Decline"
                    //         >
                    //             <Button block icon={<InfoCircleOutlined />}>Respond </Button>
                    //         </Popconfirm>
                    //         :
                    //         <Button 
                    //             type="default" 
                    //             block
                    //             disabled={record.status === DataRequestStatus.DECLINED || record.status === DataRequestStatus.DELETED}
                    //             onClick={async () => { 
                    //                     setViewGuarantee(record);
                    //                     // axios.get(
                    //                     //     `${azure_function_url}/Databoss?command=data_request_get&id=${record.id}`,
                    //                     //     {
                    //                     //         maxContentLength: Number.POSITIVE_INFINITY,
                    //                     //         headers: {
                    //                     //             'Authorization': parent.bearerToken
                    //                     //         }
                    //                     //     }
                    //                     // ).then((data) => {
                    //                     //     const doc: DataRequestResult = data.data;
                    //                     //     if(record.status === DataRequestStatus.RESPONDED || record.status === DataRequestStatus.DELETION) 
                    //                     //         setViewGuarantee(doc);
                                            
                    //                     //     else
                    //                     //         setViewRequest(doc);
                    //                     // });
                    //             } }
                    //         >
                    //             {record.status === DataRequestStatus.RESPONDED ? 
                    //                 <><CheckCircleOutlined style={{ fontSize: '16px', color: '#52c41a' }} /> Responded </> 
                    //             : record.status === DataRequestStatus.DECLINED ?  
                    //                 <><StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/> Declined </>
                    //             : record.status === DataRequestStatus.DELETION ?  
                    //                 <><StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/> Deletion </>
                    //             : record.status === DataRequestStatus.DELETED ?  
                    //                 <><StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/> Deleted </>
                    //             :  <><InfoCircleOutlined style={{ fontSize: '16px', color: '#E49B0F' }}/> View </>}
                    //         </Button>
                    //     )}
                    />
                    
                </Table>
            }
        </Card>
        : <></>
    )
}