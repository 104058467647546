import React, { useState, useEffect } from 'react';
import { InputNumber, Space, Select, List, Popconfirm, DatePicker, Table, Card, Button, message } from 'antd';

import { PlusOutlined, DeleteOutlined, UndoOutlined, CheckCircleOutlined, InfoCircleOutlined, StopOutlined } from '@ant-design/icons';

import { Currencies, DataRequestStatus } from '../../utils/constants';
import { ViewKey } from './ViewKey';
import { NewKey } from './NewKey';

import { Guarantee, Entity, DataRequestResult, EmployeeInvitations, ClientInvitations, UserUI } from '../../utils/data_types';

// import { guarantees } from '../../utils/data';

import { parseJSON, isMobileBrowser, shortenString } from '../../utils/utils';

import dayjs from 'dayjs';

import axios from 'axios';

const { Column } = Table;

export interface IKeysProps {
    user?: UserUI;
    bearerToken: string;
}
export const Keys: React.FC<IKeysProps> = (parent) => {
    
    const [viewRequest, setViewRequest] = useState<DataRequestResult>();
    const [viewKey, setViewKey] = useState<any>();
    const [viewNewKey, setNewViewKey] = useState(false);

    const [keys, setKeys] = useState<any[]>();

    const [allApplicants, setAllApplicants] = useState<any[]>();
    const [allBeneficiaries, setAllBeneficiaries] = useState<any[]>();
    const [allIssuers, setAllIssuers] = useState<any[]>();

    const [isMobile, _] = useState<boolean>(isMobileBrowser());

    const [loading, setLoading] = useState(true);
    
    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const { Option } = Select;
       
    const update = () => {
        setLoading(true);
        axios.get(
            `${azure_function_url}/NCID?command=keys_list`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            const data : any[] = parseJSON(x.data.sort((a:any,b:any) => Date.parse(b.added) - Date.parse(a.added)));

            setKeys(data)
            
            // console.log(data)

            // setGuarantees(data);
            // setAllApplicants(unique(data.map(x => x.applicants).flat()));
            // setAllBeneficiaries(unique(data.map(x => x.beneficiaries).flat()));
            // setAllIssuers(unique(data.map(x => x.issuers).flat()));
            
            setLoading(false);
        });
    }

    useEffect(() => { 
        update();
    }, []);

    const unique = (arrayWithDuplicates: any[]) : any[] => {
        return arrayWithDuplicates.filter((obj, index, self) => 
            index === self.findIndex((el) => el.id === obj.id)
        )
    }
    
    return (
        viewNewKey ?
            <NewKey bearerToken={parent.bearerToken} update={update} done={() => { setNewViewKey(false); update(); }} user={parent.user}/>
        :
        viewKey ?
            <ViewKey bearerToken={parent.bearerToken} done={() => { setViewKey(undefined); update(); }} doc={viewKey} user={parent.user}/>
        :
        <Card 
            bordered={false} 
            title={'Keys'}
            extra={
                <Space>
                    <Button style={{float:'right'}} icon={<PlusOutlined/>} onClick={() => setNewViewKey(true) }></Button> 
                    <Button style={{float:'right'}} icon={<UndoOutlined/>} onClick={() => update() }></Button> 
                </Space>
            }
        >
            {
                isMobile ?
                <>
                    <List 
                        itemLayout="horizontal"
                        pagination={{
                            onChange: (page) => {
                              console.log(page);
                            },
                            pageSize: 3
                        }}

                        dataSource={keys?.map((x, i: number) => {
                            let y: any = x;
                            y.key = i;
                            return y;
                        })}
                        renderItem= {(x) => 
                                <List.Item key={x.key}>
                                    <List.Item.Meta
                                        avatar={
                                            x.status === DataRequestStatus.RESPONDED ? 
                                                <CheckCircleOutlined style={{ fontSize: '16px', color: '#52c41a' }} />
                                            : x.status === DataRequestStatus.DECLINED ?  
                                                <StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/>
                                            : x.status === DataRequestStatus.DELETION ?  
                                                <StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/>
                                            : x.status === DataRequestStatus.DELETED ?  
                                                <StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/>
                                            :  <InfoCircleOutlined style={{ fontSize: '16px', color: '#E49B0F' }}/>
                                        }
                                        title={
                                            <a 
                                                onClick={async () => { 
                                                    setViewKey(x);
                                                    // axios.get(
                                                    //     `${azure_function_url}/Databoss?command=data_request_get&id=${x.id}`,
                                                    //     {
                                                    //         maxContentLength: Number.POSITIVE_INFINITY,
                                                    //         headers: {
                                                    //             'Authorization': parent.bearerToken
                                                    //         }
                                                    //     }
                                                    // ).then((data) => {
                                                    //     const doc: DataRequestResult = data.data;
                                                    //     if(x.status === DataRequestStatus.RESPONDED || x.status === DataRequestStatus.DELETION) 
                                                    //         setViewGuarantee(doc);
                                                        
                                                    //     else
                                                    //         setViewRequest(doc);
                                                    // });
                                            }}>
                                                {x.beneficiaries[0]?.name}
                                            </a>
                                        }
                                        description={<>{x.name} <br></br> {new Date((x.expiryDate)).toLocaleString()}</>}
                                    /> 
                                </List.Item>
                        }    
                    />
                </>
                :
                <Table
                    loading={loading}
                    dataSource={keys?.map(x => {
                        let y: any = x;
                        y.key = x.id;
                        return y;
                    })}
                    pagination={{hideOnSinglePage: true, defaultPageSize: 5}}

                >
                    <Column
                        title="Id"
                        key="id"
                        render={(_: any, record: any) => shortenString(record.id)}
                    />
                    <Column
                        title="Name"
                        key="name"
                        // filters={Object.values(Currencies).map(x => { return { text: x, value: x }} )}
                        // onFilter={(value: any, record) => record.currency === value}
                        
                        render={(_: any, record: any) => record.name}
                    />
                    {/* <Column
                        title="Amount"
                        key="amount"
                        sorter={(a, b) => Number(a.amount) - Number(b.amount)}                        
                        render={(_: any, record: any) => `${record.amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    /> */}
                    {/* <Column
                        title="Applicants"
                        key="applicant"
                        filters={allApplicants?.map(x => { return { text: x.name, value: x.id }} )}
                        onFilter={(value: any, record) => record.applicants.map((x:any)=>x.id).indexOf(value) === 0}
                        render={(_: any, record: any) => 
                            record.applicants.length > 1 ?
                            <Select
                                style={{ width: '100%' }}
                                optionLabelProp="label"
                                placeholder={'Pick a type'}
                                value={record.applicants[0].id}
                            >
                                {
                                    record.applicants.map((template:any, i:number) => 
                                        <Option value={template.id} label={template.name} key={i}>
                                            <Space>
                                                {template.name}
                                            </Space>
                                        </Option>
                                    )
                                }
                            </Select>
                            :
                            record.applicants[0]?.name
                        }
                    />
                    <Column
                        title="Beneficiaries"
                        key="beneficiaries" 
                        filters={allBeneficiaries?.map(x => { return { text: x.name, value: x.id }} )}
                        onFilter={(value: any, record) => record.beneficiaries.map((x:any)=>x.id).indexOf(value) === 0}
                        render={(_: any, record: any) => 
                            record.beneficiaries.length > 1 ?
                            <Select
                                style={{ width: '100%' }}
                                optionLabelProp="label"
                                placeholder={'Pick a type'}
                                value={record.beneficiaries[0].id}
                            >
                                {
                                    record.beneficiaries.map((template:any, i:number) => 
                                        <Option value={template.id} label={template.name} key={i}>
                                            <Space>
                                                {template.name}
                                            </Space>
                                        </Option>
                                    )
                                }
                            </Select>
                            :
                            record.beneficiaries[0]?.name
                        }
                    />
                    <Column
                        title="Issuers"
                        key="issuer" 
                        filters={allIssuers?.map(x => { return { text: x.name, value: x.id }} )}
                        onFilter={(value: any, record) => record.issuers.map((x:any)=>x.id).indexOf(value) === 0}
                        render={(_: any, record: any) => 
                            record.issuers.length > 1 ?
                            <Select
                                style={{ width: '100%' }}
                                optionLabelProp="label"
                                placeholder={'Pick a type'}
                                value={record.issuers[0]?.id}
                            >
                                {
                                    record.issuers.map((template:any, i:number) => 
                                        <Option value={template.id} label={template.name} key={i}>
                                            <Space>
                                                {template.name}
                                            </Space>
                                        </Option>
                                    )
                                }
                            </Select>
                            :
                            record.issuers[0]?.name
                        }
                    /> */}
                    <Column
                        title="Created"
                        key="created"
                        sorter={(a, b) => a.added.getTime() - b.added.getTime()}
                        render={(_: any, record: any) => (
                            <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(record.added.getTime() / 1000)} />
                        )}
                    />
                    <Column
                        title="Action"
                        key="action"
                        render={(_: any, record: any) => (
                            record.employee_invitation ? 
                            <Popconfirm
                                title="Accept or Decline Invitation"
                                description="Would you like to accept of decline the Inviation?"
                                onConfirm={() => {
                                    axios.post(
                                        `${azure_function_url}/Databoss?command=employees_accept`,
                                        {
                                            companyid: record.companyid,
                                        },
                                        {
                                            maxContentLength: Number.POSITIVE_INFINITY,
                                            headers: {
                                                'Authorization': parent.bearerToken
                                            }
                                        }
                                    ).then(x => {
                                        message.success('Employee invitation accepted');
                                        update();
                                    });

                                }}
                                onCancel={() => {
                                    axios.post(
                                        `${azure_function_url}/Databoss?command=employees_decline`,
                                        {
                                            companyid: record.companyid,
                                        },
                                        {
                                            maxContentLength: Number.POSITIVE_INFINITY,
                                            headers: {
                                                'Authorization': parent.bearerToken
                                            }
                                        }
                                    ).then(x => {
                                        message.success('Employee invitation declined');
                                        update();
                                    });

                                }}
                                okText="Accept"
                                cancelText="Decline"
                            >
                                <Button block icon={<InfoCircleOutlined />}>Respond </Button>
                            </Popconfirm>
                            : record.client_invitation ? 
                            <Popconfirm
                                title="Accept or Decline Invitation"
                                description="Would you like to accept of decline the Inviation?"
                                onConfirm={() => {
                                    axios.post(
                                        `${azure_function_url}/Databoss?command=clients_accept`,
                                        {
                                            companyid: record.companyid,
                                        },
                                        {
                                            maxContentLength: Number.POSITIVE_INFINITY,
                                            headers: {
                                                'Authorization': parent.bearerToken
                                            }
                                        }
                                    ).then(x => {
                                        message.success('Client invitation accepted');
                                        update();
                                    });

                                }}
                                onCancel={() => {
                                    axios.post(
                                        `${azure_function_url}/Databoss?command=clients_decline`,
                                        {
                                            companyid: record.companyid,
                                        },
                                        {
                                            maxContentLength: Number.POSITIVE_INFINITY,
                                            headers: {
                                                'Authorization': parent.bearerToken
                                            }
                                        }
                                    ).then(x => {
                                        message.success('Client invitation declined');
                                        update();
                                    });

                                }}
                                okText="Accept"
                                cancelText="Decline"
                            >
                                <Button block icon={<InfoCircleOutlined />}>Respond </Button>
                            </Popconfirm>
                            :
                            <Button 
                                type="default" 
                                block
                                disabled={record.status === DataRequestStatus.DECLINED || record.status === DataRequestStatus.DELETED}
                                onClick={async () => { 
                                        setViewKey(record);
                                        // axios.get(
                                        //     `${azure_function_url}/Databoss?command=data_request_get&id=${record.id}`,
                                        //     {
                                        //         maxContentLength: Number.POSITIVE_INFINITY,
                                        //         headers: {
                                        //             'Authorization': parent.bearerToken
                                        //         }
                                        //     }
                                        // ).then((data) => {
                                        //     const doc: DataRequestResult = data.data;
                                        //     if(record.status === DataRequestStatus.RESPONDED || record.status === DataRequestStatus.DELETION) 
                                        //         setViewGuarantee(doc);
                                            
                                        //     else
                                        //         setViewRequest(doc);
                                        // });
                                } }
                            >
                                {record.status === DataRequestStatus.RESPONDED ? 
                                    <><CheckCircleOutlined style={{ fontSize: '16px', color: '#52c41a' }} /> Responded </> 
                                : record.status === DataRequestStatus.DECLINED ?  
                                    <><StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/> Declined </>
                                : record.status === DataRequestStatus.DELETION ?  
                                    <><StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/> Deletion </>
                                : record.status === DataRequestStatus.DELETED ?  
                                    <><StopOutlined style={{ fontSize: '16px', color: '#FF0000' }}/> Deleted </>
                                :  <><InfoCircleOutlined style={{ fontSize: '16px', color: '#E49B0F' }}/> View </>}
                            </Button>
                        )}
                    />
                    
                </Table>
            }
        </Card>
    )
}