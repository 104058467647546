import React from 'react';
import { Card, Button, message, Image } from 'antd';

import { CloseCircleOutlined } from '@ant-design/icons';

const PDFViewer: React.FC<any> = (data) => {
    const file = new Blob([Buffer.from(data.base64PDF,"base64")], {type: data.type });
    const pdfURL = URL.createObjectURL(file);
    
    return (
        <iframe 
            frameBorder="0"
            style={{
                border: 'none'
            }}
            src={pdfURL}
            width="100%" 
            height="500px" 
            title="PDF Viewer"
        />
    );
}

export interface IFileViewerProps {
    viewFile: any;
    done: () => void;
}
export const FileViewer: React.FC<IFileViewerProps> = (parent) => {
    
    return (
        <>
            <Card
                bordered={false} 
                title={ parent.viewFile.name }
                extra={<Button icon={<CloseCircleOutlined/>} onClick={() => parent.done() }></Button>}
            >
                { parent.viewFile.type === 'application/pdf' ? <PDFViewer type={ parent.viewFile.type } name={ parent.viewFile.name } base64PDF={ parent.viewFile.data } /> : <Image src={`data:${ parent.viewFile.type};base64,${parent.viewFile.data}` }/> }
            </Card>
        </>
    )
}