import React, { useState, useEffect } from 'react';
import { Divider, Button, List, Card } from 'antd';
import { PlusOutlined, ClusterOutlined } from '@ant-design/icons';

import { ViewSchema } from './ViewSchema';
import { NewSchema } from './NewSchema';

import { parseJSON } from '../../utils/utils';

import { UserUI, Schema, FormList } from '../../utils/data_types';

import axios from 'axios';

export interface IListSchemasProps {
    user?: UserUI;
    bearerToken: string;
    schema?: Schema | undefined;
    setSchema: (schema: Schema | undefined) => void
}
export const ListSchemas: React.FC<IListSchemasProps> = (parent) => {

    const [schemas, setSchemas] = useState<FormList[]>([]);
    const [newSchema, setNewSchema] = useState(false);

    const azure_function_url = process.env.AZURE_FUNCTION_URL;
    
    const update = () => {
        axios.post(
            `${azure_function_url}/Databoss?command=forms_list`,
            {
                companyid: parent.user?.entity?.id
            },
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            const forms : FormList[] = parseJSON(x.data);
            setSchemas(forms);
        });
    }

    useEffect(() => { update(); }, []);
    
    return (
        <Card 
            title="Forms" 
            bordered={false}
            extra={
            <Button style={{float:'right'}} icon={<PlusOutlined/>} disabled={false} onClick={() => { setNewSchema(true) }} />
            }
        >
            {
                parent.schema && parent.schema.id ? 
                    <ViewSchema schema={parent.schema} done={() => { parent.setSchema(undefined) }} />
                : newSchema ?
                    <NewSchema bearerToken={parent.bearerToken} user={parent.user} done={() => setNewSchema(false)} update={update} />
                :
                <>
                    <List
                        itemLayout="horizontal"
                        dataSource={schemas}
                        renderItem={(item : any, index) => (
                        <List.Item>
                            <List.Item.Meta
                            avatar={<ClusterOutlined/>}
                            title={<a onClick={() => { parent.setSchema(item); }}>{item.name}</a>}
                            description={item.description}
                            />
                        </List.Item>
                        )}
                    />
                </>
            }
        </Card>
    )
}