import React, { useState, useEffect } from 'react';
import { Popconfirm, Modal, Form, Input, Space, List, Card, Button, message } from 'antd';

import { PlusOutlined, DeleteOutlined, BankOutlined } from '@ant-design/icons';

import { CustomDataTypes } from '../../utils/constants';
import { UserUI } from '../../utils/data_types';

import { isMobileBrowser } from '../../utils/utils';

import { Shared, Views } from './Traces';

import axios from 'axios';

export interface IBankAccountsProps {
    user?: UserUI;
    bearerToken: string;
    update: () => void;
}
export const ListBankAccounts: React.FC<IBankAccountsProps> = (parent) => {
    
    const azure_function_url = process.env.AZURE_FUNCTION_URL;
    
    const [accounts, setAccounts] = useState<{ id: string, name: string, bsb: string, account: string }[]>([]);
    const [collapsed, setCollapsed] = useState<boolean>(isMobileBrowser());

    const [newModal, setNewModal] = useState(false);
    
    const [form] = Form.useForm();

    const [viewDoc, setViewDoc] = useState<string>();

    const update = () => axios.get(
        `${azure_function_url}/Databoss?command=bank_accounts_list`,
        {
            maxContentLength: Number.POSITIVE_INFINITY,
            headers: {
                'Authorization': parent.bearerToken
            }
        }
    ).then((x) => {
        setAccounts(x.data);
    });

    useEffect(() => { 
        update();
    }, []);

    return (
        accounts.length > 0 ?
        <>
            <br></br>
            <Card 
                bordered={false} 
                title={`Bank Accounts`} 
                extra={<Button icon={<PlusOutlined/>} onClick={() => setNewModal(true) }></Button>}
                bodyStyle={{ padding: 0 }}
            ></Card>
            <br></br>
            <Card 
                bordered={false} 
            >
                {
                parent.user ?
                <>  
                    <List itemLayout="horizontal">    
                        {
                            accounts.map((account, i:number) => (
                                <List.Item 
                                    actions={[
                                        <Popconfirm
                                            title="Remove the account"
                                            description="Are you sure to remove this bank account?"
                                            onConfirm={async () => {
                                                message.info('delete is not implemented')
                                                // axios.get(
                                                //     `${azure_function_url}/Databoss?command=bank_accounts_delete&id=${account.id}`,
                                                //     {
                                                //         maxContentLength: Number.POSITIVE_INFINITY,
                                                //         headers: {
                                                //             'Authorization': parent.bearerToken
                                                //         }
                                                //     }
                                                // ).then((data) => {
                                                //     update();
                                                // });
                                            } }
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button 
                                                icon={<DeleteOutlined />}
                                            >
                                                {collapsed ? '' : 'Delete'}
                                            </Button>
                                        </Popconfirm>
                                        
                                    ]} 
                                    key={i}
                                >
                                    <List.Item.Meta
                                        avatar={<BankOutlined />}
                                        title={<a onClick={()=> { setViewDoc(account.id); } }>{account.name}</a>}
                                        description={<>BSB: {account.bsb} <br/> Account: {account.account}</>}
                                    /> 
                                </List.Item>
                            ))
                        }
                        
                    </List>
                </>
                :
                <></>
                }
            </Card>

            { viewDoc ? <Shared id={viewDoc} bearerToken={parent.bearerToken} type={CustomDataTypes.BANK_ACCOUNT} close={() => setViewDoc(undefined)} /> : <></> }
            { viewDoc ? <Views id={viewDoc} bearerToken={parent.bearerToken} type={CustomDataTypes.BANK_ACCOUNT} close={() => setViewDoc(undefined)}/> : <></> }
            
            <Modal title={"New Acccount"} closable={false} open={newModal} footer={null}>
                <Form
                    form={form}
                    name="new_bank_account"
                    onFinish={(values) => {
                        axios.post(
                            `${azure_function_url}/Databoss?command=bank_accounts_new`,
                            values,
                            {
                                maxContentLength: Number.POSITIVE_INFINITY,
                                headers: {
                                    'Authorization': parent.bearerToken
                                }
                            }
                        ).then((x) => {
                            update();
                            setNewModal(false);
                        });
                    
                    }}
                    style={{ maxWidth: 600 }}
                    autoComplete="off"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                >
                    <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="bsb" label="BSB" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="account" label="Account" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <br></br>
                    <br></br>
                    <Form.Item 
                        wrapperCol={{ offset: 8, span: 16 }}
                    >
                        <Space>
                            <Button htmlType="button" onClick={()=>{form.resetFields(); setNewModal(false)}}>
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Space>
                    </Form.Item>
                </Form> 
            </Modal>
        </>
        :
        <></>
    )
}