import React, { useEffect, useState } from 'react';
import { Space, Spin, Button, Statistic, Row, Col, Card } from 'antd';

import { BankOutlined, UndoOutlined, UserOutlined, CalendarOutlined, DashboardOutlined } from '@ant-design/icons';

import { Guarantees } from '../guarantees/Guarantees';
import { Keys } from '../keys/Keys';
import { Inbox } from './Inbox';
import { Outbox } from './Outbox';
import { Signatures } from '../signatures/Signatures';

import { EntityRegistration }  from '../onboarding/EntityRegistration';

import { VerifiedDocuments } from '../documents/VerifiedDocuments';

import { UserUI, GeneralDocument } from '../../utils/data_types';
import { EmployeeStatus, adminURL } from '../../utils/constants';

import { isMobileBrowser, parseJSON, shortenString } from '../../utils/utils';

import axios from 'axios';

export interface IHomeProps {
    user?: UserUI;
    bearerToken: string;
    updateUser: () => void;
    updateBackend: () => void;
    pt100: boolean;
};
export const Home: React.FC<IHomeProps> = (parent) => {

    const [collapsed, setCollapsed] = useState<boolean>(isMobileBrowser());
    const [guarantees, setGuarantees] = useState<any[]>();
    const [allApplicants, setAllApplicants] = useState<any[]>();
    const [allBeneficiaries, setAllBeneficiaries] = useState<any[]>();
    const [allIssuers, setAllIssuers] = useState<any[]>();


    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const unique = (arrayWithDuplicates: any[]) : any[] => {
        return arrayWithDuplicates.filter((obj, index, self) => 
            index === self.findIndex(el => el.id === obj.id)
        )
    }

    const update = () => {        
        parent.updateUser();

        axios.get(
            `${azure_function_url}/NFGX?command=guarantee_list`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            const data: GeneralDocument[] = parseJSON(x.data);

            setGuarantees(data);       
            setAllApplicants(unique(data.map(x => x.guarantee.applicants).flat()).map(x=>x.id));
            setAllBeneficiaries(unique(data.map(x => x.guarantee.beneficiaries).flat()).map(x=>x.id));
            setAllIssuers(unique(data.map(x => x.guarantee.issuers).flat()).map(x=>x.id));      
        });
    }

    useEffect(() => { 
        update();
    }, []);

    return (
        !parent.pt100 && false?
        <>
            <Card 
                bordered={false} 
                title={ `You need to verify all of the following documents...`} 
                extra={<Button icon={<UndoOutlined/>} onClick={() => parent.updateBackend() }></Button>}
                bodyStyle={{ padding: 0 }}
            />
            <VerifiedDocuments update={update} user={parent.user} data={parent.user?.data} bearerToken={parent.bearerToken} pt100={false} doc={undefined}></VerifiedDocuments>
        </>
        : (window.location.pathname === adminURL || window.location.hostname === adminURL) && parent.user?.entity?.permission !== EmployeeStatus.OWNER?
            <EntityRegistration user={parent.user} bearerToken={parent.bearerToken} updateUser={parent.updateUser} />
        :
        <div>
            {
                collapsed ?
                <>
                <Row gutter={[16, 16]} >
                    <Col span={24}>
                        <Card bordered={false}>
                            <Statistic
                                title="Name"
                                value={`${parent.user?.data?.personal?.name.firstName} ${parent.user?.data?.personal?.name.lastName}`}
                                prefix={<UserOutlined />}
                            />
                        </Card>
                    </Col>
                </Row> 
                </>
                :
                <Row gutter={[16, 16]} >
                    <Col span={10}>
                        <Card bordered={false}>
                            <Statistic
                                title="Name"
                                value={`${parent.user?.data?.personal?.name.firstName} ${parent.user?.data?.personal?.name.lastName}`}
                                prefix={<UserOutlined />}
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card bordered={false}>
                            <Statistic
                                title="Entity"
                                // value={'Essential Energy'}
                                value={`${shortenString(parent.user?.entity?.name)}`}
                                prefix={<BankOutlined />}
                            />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={false}>
                            <Statistic
                                title="Total Amount"
                                value={`${guarantees ? guarantees.reduce((acc, v) => acc + Number(v.guarantee.amount), 0) : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                prefix={<DashboardOutlined />}
                        />
                        </Card>
                    </Col>
                </Row>
            }
            {/* <br></br> */}
            {/* <VerifiedDocuments update={update} user={parent.user} data={parent.user?.data} bearerToken={parent.bearerToken} pt100={true} doc={undefined}></VerifiedDocuments> */}
            { allApplicants && allApplicants?.indexOf(parent.user?.entity?.id) > -1 ? 
            <>
            <br></br>
            <Row>
                <Col span={24}><Guarantees bearerToken={parent.bearerToken} user={parent.user} type={'applicants'} /></Col>
            </Row>
            </>
            :<></>
            }
            { allBeneficiaries && allBeneficiaries?.indexOf(parent.user?.entity?.id) > -1 ? 
            <>
            <br></br>
            <Row>
                <Col span={24}><Guarantees bearerToken={parent.bearerToken} user={parent.user} type={'beneficiaries'} /></Col>
            </Row>
            </>
             :<></>
            }
            { allIssuers && allIssuers?.indexOf(parent.user?.entity?.id) > -1 ? 
            <>
            <br></br>
            <Row>
                <Col span={24}><Guarantees bearerToken={parent.bearerToken} user={parent.user} type={'issuers'} /></Col>
            </Row>
            </>
            :
            <></>
            }
            {
                !(allApplicants && allApplicants?.indexOf(parent.user?.entity?.id) > -1) && !(allBeneficiaries && allBeneficiaries?.indexOf(parent.user?.entity?.id) > -1) && !(allIssuers && allIssuers?.indexOf(parent.user?.entity?.id) > -1) ? 
                <>
                <br></br>
                <br></br>
                <Row>
                    <Col span={24}><Guarantees bearerToken={parent.bearerToken} user={parent.user} type={'new'} /></Col>
                </Row>
                </>
                :
                <></>
            }

            <br></br>
            <Row>
                <Col span={24}><Signatures bearerToken={parent.bearerToken} user={parent.user} /></Col>
            </Row>
            
            <br></br>
            <Row>
                <Col span={24}><Keys bearerToken={parent.bearerToken} user={parent.user} /></Col>
            </Row>
            <br></br>
            <br></br>
            <Row>
                <Col span={24}><Inbox bearerToken={parent.bearerToken} user={parent.user} /></Col>
            </Row>
            {
                parent.user?.entity?.status?
            <>
                <br></br>
                <br></br>
                <Row>
                    <Col span={24}><Outbox bearerToken={parent.bearerToken} user={parent.user} /></Col>
                </Row>
            </>
            :
            <></>
            }
        </div>
    )
}