import React, { useEffect, useState } from 'react';

import { Popconfirm, Table, message, Upload, Divider, Typography, Row, Col, DatePicker, Card, InputNumber, Select, Input, Button, Form, Space } from 'antd';
import { DeleteOutlined, UploadOutlined, SendOutlined, LeftOutlined, MinusOutlined,MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import type { UploadFile, UploadProps } from 'antd';

import dayjs from 'dayjs';

import { FileViewer } from './FileViewer';
import { Guarantee, Entity, GuaranteeFile, UserUI } from '../../utils/data_types';

import { Currencies, GuaranteeStatus, GuaranteeType } from '../../utils/constants';

// import { issuers, beneficiaries, applicants } from '../../utils/data';

import { isMobileBrowser, shortenString } from '../../utils/utils';

import axios from 'axios';

export interface IEditGuaranteeProps {
    user?: UserUI;
    bearerToken: string;
    doc: any;
    update: () => void;
    done: () => void;
}
export const EditGuarantee: React.FC<IEditGuaranteeProps> = (parent) => {
    const { Option } = Select;
    const { Title, Paragraph } = Typography;
    const { TextArea } = Input;
    const { Dragger } = Upload;
    const { Column } = Table;

    const [isMobile, _] = useState<boolean>(isMobileBrowser());
    const [files, setFiles] = useState<GuaranteeFile[]>(parent.doc.guarantee.files);
    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const [issuers, setIssuers] = useState<Entity[]>([]);
    const [beneficiaries, setBeneficiaries] = useState<Entity[]>([]);
    const [applicants, setApplicants] = useState<Entity[]>([]);
    const [viewFile, setViewFile] = useState<any>();


    const uint8ArrayToBase64 = (buffer: Uint8Array): string => {
        let binary = '';
        const len = buffer.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(buffer[i]);
        }
        return window.btoa(binary);
    }
    
    const onFinish = (values: any) => {
        

        const guarantee: Guarantee = {
            id: parent.doc.id,
            version: parent.doc.version,
            startDate: values.dates[0].toDate(),
            expiryDate: values.dates[1].toDate(),
            applicants: values.applicants.map((x:Entity) => { return { id: x.id, name: '', abn: ''} }),
            beneficiaries: values.beneficiaries.map((x:Entity) => { return { id: x.id, name: '', abn: ''} }),
            issuers: values.issuers.map((x:Entity) => { return { id: x.id, name: '', abn: ''} }),
            currency: values.currency,
            amount: values.amount,
            // status: parent.doc.status,

            type: values.type,
            contract: {
                id: values.contract_id,
                startDate: values.contract_dates[0].toDate(),
                endDate: values.contract_dates[1].toDate(),
                name: values.contract_name,
                description: values.contract_description,
                currency: values.currency,
                amount: values.amount
            },

            creationDate: new Date(),
            creator: 'NA',

            // approvedDateApplicant: null,
            // approvingApplicant: null,
        
            // approvedDateBeneficiary: null,
            // approvingBeneficiary: null,
        
            // approvedDateIssuer: null,
            // approvingIssuer: null,
        
            // triggerDate: null,
            // triggerUser: null,
        
            // settledDate: null,

            files: files
        };

        console.log(parent.doc);
        console.log(guarantee);
        axios.post(
            `${azure_function_url}/NFGX?command=guarantee_edit`,
            guarantee,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            parent.done();
        });



        // parent.done();
    };

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        fileList: files.map(x => { return {
            uid: x.id,
            name: x.name,
            status: 'done'
        }}),
        showUploadList: true,
        customRequest: async (options: any) => { 
            
            // eslint-disable-next-line
            const { onSuccess, onError, file, onProgress } = options;
            const buffer = await (file as File).arrayBuffer();
            const bytes = new Uint8Array(buffer);
            const base64String = uint8ArrayToBase64(bytes);

            let newfiles = [...files];
            newfiles.push({
                id: '',
                type: file.type,
                name: file.name,
                data: base64String,
                created: new Date()
            })
            setFiles(newfiles);


            if(onSuccess){
                onSuccess("Ok");
                // update();
                // setNewModal(false);
            }            
        },
        onChange: async (info) => {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            }
            else if (status === 'removed') {
                message.success(`${info.file.name} file removed successfully.`);
                let newfiles = files.filter(x => x.name !== info.file.name);
                
                setFiles(newfiles);

            }
            else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };

    useEffect(() => {
        axios.get(
            `${azure_function_url}/NFGX?command=issuers_list`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            setIssuers(x.data);
        });

        axios.get(
            `${azure_function_url}/NFGX?command=beneficiaries_list`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            setBeneficiaries(x.data);
        });

        axios.get(
            `${azure_function_url}/NFGX?command=applicants_list`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            setApplicants(x.data);
        });
    },[]);


    return (
        <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{
                id:parent.doc.guarantee.id,
                type:parent.doc.guarantee.type,
                currency:parent.doc.guarantee.currency,
                amount:parent.doc.guarantee.amount,
                dates:[ dayjs.unix(Date.parse(parent.doc.guarantee.startDate) / 1000), dayjs.unix(Date.parse(parent.doc.guarantee.expiryDate) / 1000) ],
                issuers: parent.doc.guarantee.issuers,
                applicants: parent.doc.guarantee.applicants,
                beneficiaries: parent.doc.guarantee.beneficiaries,
                contract_name: parent.doc.guarantee.contract.name,
                contract_description: parent.doc.guarantee.contract.description,
                contract_id: parent.doc.guarantee.contract.id,
                contract_type: parent.doc.guarantee.contract.type,
                contract_dates: [ dayjs.unix(Date.parse(parent.doc.guarantee.contract.startDate) / 1000), dayjs.unix(Date.parse(parent.doc.guarantee.contract.endDate) / 1000) ],
            }}
        >
            <Card 
                bordered={false} 
                title={'Edit Guarantee'}
                extra={
                    <Space>
                        <Button style={{float:'right'}} icon={<LeftOutlined />} onClick={() => parent.done() }></Button> 
                    </Space>
                }
            >
               <Row gutter={[16, 16]}>
                    <Col span={12}><Title level={3} type="secondary">Identity Reference</Title></Col>
                    <Col span={12}><Title level={3} type="secondary">Version</Title></Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={12}><Title level={5}>{parent.doc.id}</Title></Col>
                    <Col span={12}><Title level={5}>{parent.doc.version}</Title></Col>
                </Row>
                <br></br>

                <Row gutter={[16, 16]}>
                    <Col span={6}><Title level={3} type="secondary">Type</Title></Col>
                    <Col span={3}><Title level={3} type="secondary">Currency</Title></Col>
                    <Col span={6}><Title level={3} type="secondary">Amount</Title></Col>
                    <Col span={9}><Title level={3} type="secondary">Start & Expiry Dates</Title></Col>
                </Row>

                <Row gutter={[16, 16]}>
                    
                    <Col span={6}>
                        <Form.Item
                            name={'type'}
                            style={{width:'100%'}}
                            rules={[{ required: true, message: 'Type' }]}
                        >
                            <Select
                                style={{ width: '100%'}}
                                optionLabelProp="label"
                                placeholder={'Pick a guarantee type'}
                            >
                                {
                                    Object.values(GuaranteeType).map((template, i) => 
                                        <Option value={template} label={template} key={i}>
                                            <Space>
                                                {template}
                                            </Space>
                                        </Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            // label={'Currency'}
                            name={'currency'}
                            style={{width:'100%'}}
                            rules={[{ required: true, message: 'Currency' }]}
                        >
                            <Select
                                style={{ width: '100%'}}
                                optionLabelProp="label"
                                placeholder={'Pick a currency'}
                            >
                                {
                                    Object.values(Currencies).map((template, i) => 
                                        <Option value={template} label={template} key={i}>
                                            <Space>
                                                {template}
                                            </Space>
                                        </Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            // label={'Amount'}
                            name={'amount'}
                            style={{width:'100%'}}
                            rules={[{ required: true, message: 'Amount' }]}
                        >
                            <InputNumber
                                min={0} 
                                style={{ width: '100%' }}
                                placeholder="Amount"
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name={'dates'}
                            style={{width:'100%'}}
                            rules={[{ required: true, message: 'Dates' }]}
                        >
                           <DatePicker.RangePicker style={{width:'100%'}} showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>
                    </Col>
                </Row>

            </Card>
            <br></br>
            <Card
                title={`Counterparties`}
                bodyStyle={isMobile ? { padding: 0 } : {}}

            >
                <Row gutter={[16, 16]}>
                    <Col span={8}><Title level={3} type="secondary">Applicants</Title></Col>
                    <Col span={8}><Title level={3} type="secondary">Beneficiaries</Title></Col>
                    <Col span={8}><Title level={3} type="secondary">Issuers</Title></Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Form.Item style={{width:'100%'}}>
                            <Form.List name="applicants">
                            {(fields, { add, remove }) => (
                                <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space.Compact key={key} style={{ display: 'flex', marginBottom: 8, width:'100%'}}>
                                        <Form.Item
                                            noStyle
                                            {...restField}
                                            name={[name, 'id']}
                                            
                                            rules={[{ required: true, message: 'Missing type selection' }]}
                                        >    
                                            <Select
                                                style={{ width: '100%' }}
                                                optionLabelProp="label"
                                                placeholder={'Pick an Applicant'}
                                            >
                                                {
                                                    applicants.map((template, i) => 
                                                        <Option value={template.id} label={template.name} key={i}>
                                                            <Space>
                                                                {template.name}
                                                            </Space>
                                                        </Option>
                                                    )
                                                }
                                            </Select>                                            
                                        </Form.Item>                                    
                                        <Button icon={<MinusOutlined/>} onClick={() => remove(name)}></Button>
                                    </Space.Compact>
                                ))}
                                <Form.Item style={{width:'100%'}}>
                                    <Button style={{width:'100%'}} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add Applicant
                                    </Button>
                                </Form.Item>
                                </>
                            )}
                            </Form.List>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item style={{width:'100%'}}>
                            <Form.List name="beneficiaries">
                            {(fields, { add, remove }) => (
                                <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space.Compact key={key} style={{ display: 'flex', marginBottom: 8, width:'100%'}}>
                                        <Form.Item
                                            noStyle
                                            {...restField}
                                            name={[name, 'id']}
                                            
                                            rules={[{ required: true, message: 'Missing type selection' }]}
                                        >
                                            <Select
                                                style={{ width: '100%' }}
                                                optionLabelProp="label"
                                                placeholder={'Pick an Beneficiary'}
                                            >
                                                {
                                                    beneficiaries.map((template, i) => 
                                                        <Option value={template.id} label={template.name} key={i}>
                                                            <Space>
                                                                {template.name}
                                                            </Space>
                                                        </Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>                                    
                                        <Button icon={<MinusOutlined/>} onClick={() => remove(name)}></Button>
                                    </Space.Compact>
                                ))}
                                <Form.Item style={{width:'100%'}}>
                                    <Button style={{width:'100%'}} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add Beneficiary
                                    </Button>
                                </Form.Item>
                                </>
                            )}
                            </Form.List>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item style={{width:'100%'}}>
                            <Form.List name="issuers">
                            {(fields, { add, remove }) => (
                                <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space.Compact key={key} style={{ display: 'flex', marginBottom: 8, width:'100%'}}>
                                        <Form.Item
                                            {...restField}
                                            noStyle
                                            name={[name, 'id']}
                                            rules={[{ required: true, message: 'Missing type selection' }]}
                                        >
                                            
                                                <Select
                                                    style={{ width: '100%' }}
                                                    optionLabelProp="label"
                                                    placeholder={'Pick an Issuer'}
                                                >
                                                    {
                                                        issuers.map((template, i) => 
                                                            <Option value={template.id} label={template.name} key={i}>
                                                                <Space>
                                                                    {template.name}
                                                                </Space>
                                                            </Option>
                                                        )
                                                    }
                                                </Select>
                                        </Form.Item>                                    
                                        {/* <Button icon={<MinusOutlined/>} onClick={() => remove(name)}></Button> */}
                                    </Space.Compact>
                                ))}
                                {/* <Form.Item style={{width:'100%'}}>
                                    <Button style={{width:'100%'}} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add Issuer
                                    </Button>
                                </Form.Item> */}
                                </>
                            )}
                            </Form.List>
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
            <br></br>
            <Card title={'Contract'}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Title level={3} type="secondary">
                            Name
                        </Title>
                        </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Form.Item
                            name={'contract_name'}
                            style={{width:'100%'}}
                            rules={[{ required: true, message: 'Contract Title' }]}
                        >
                            <Input style={{width:'100%'}} placeholder="Contract Title" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Title level={4} type="secondary">
                            Description
                        </Title>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Form.Item
                            name={'contract_description'}
                            style={{width:'100%'}}
                            rules={[{ required: true, message: 'Contract Description' }]}
                            >
                                <TextArea rows={4} placeholder='Contract Description'/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={8}><Title level={3} type="secondary">Contract Number</Title></Col>
                    <Col span={16}><Title level={3} type="secondary">Start & End Dates</Title></Col>
                </Row>
                
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Form.Item
                            name={'contract_id'}
                            style={{width:'100%'}}
                            rules={[{ required: true, message: 'Contract Number' }]}
                        >
                            <Input style={{width:'100%'}} placeholder="Contract Number" />
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            name={'contract_dates'}
                            style={{width:'100%'}}
                            rules={[{ required: true, message: 'Contract Dates' }]}
                        >
                           <DatePicker.RangePicker style={{ width: '100%'}} showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>
                    </Col>                    
                </Row>
            </Card>
            <br></br>
            <Card title={'Files'}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Form.Item 
                            name="file" 
                        >
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                <UploadOutlined />
                                </p>
                                <p className="ant-upload-text">{ isMobile ? 'Click to add a file' : 'Click or drag a file'}</p>
                            </Dragger>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button icon={<SendOutlined />} type="primary" htmlType="submit">
                        Submit
                    </Button>
                </div>
            </Card>
            {viewFile ? 
            <>
                <br></br>
                <FileViewer viewFile={viewFile} done={() =>  setViewFile(undefined) }/>
            </>
        : <></>}
        </Form>
    )
}