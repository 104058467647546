import { InputNumber, Select, Input, Button, Form, Space } from 'antd';

import { Schema } from '../../utils/data_types';
import { unescape } from 'querystring';

export interface IViewSchemaProps {
    schema?: Schema | undefined;
    done: () => void
}
export const ViewSchema: React.FC<IViewSchemaProps> = (parent) => {
    const { Option } = Select;

    const onFinish = (values: any) => {
        console.log('Received values of form:', values);
    };

    console.log(parent.schema)

    const changeStructure = (initial : any) => {
        if(initial){
            let newObj = {...initial};
            newObj.fields = initial.data.fields;
            console.log(newObj)
            return newObj;
        }
        else
            return undefined;
    }
      
    return (
        <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            style={{ maxWidth: 600 }}
            disabled
            autoComplete="off"
            initialValues={changeStructure(parent.schema)}

            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
        >
            <Form.Item
                label={'id'}
                name={'id'}
                style={{width:'100%'}}
                rules={[{ required: true, message: 'Form ID' }]}
            >
                <Input disabled style={{width:'100%'}} placeholder="Form ID" />
            </Form.Item>
            <Form.Item
                label={'Name'}
                name={'name'}
                style={{width:'100%'}}
                rules={[{ required: true, message: 'Form Name' }]}
            >
                <Input style={{width:'100%'}} placeholder="Form Name" />
            </Form.Item>
            <Form.Item
                label={'Description'}
                name={'description'}
                style={{width:'100%'}}
                rules={[{ required: true, message: 'Form Description' }]}
            >
                <Input style={{width:'100%'}} placeholder="Form Description" />
            </Form.Item>
            <Form.Item
                label={'Expiry'}
                name={'expiry'}
                style={{width:'100%'}}
                rules={[{ required: true, message: 'Days to Expiry' }]}
            >
                <InputNumber
                    min={0} 
                    style={{ width: '100%' }}
                    placeholder="Days to expiry"
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
            </Form.Item>
            <Form.List name="fields">
            {(fields, { add, remove }) => (
                <>
                {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8, width:'100%'}} align="baseline">
                        <Form.Item
                            {...restField}
                            label={'Id'}
                            name={[name, 'id']}
                            style={{width:'100%'}}
                            rules={[{ required: true, message: 'Field Id' }]}
                        >
                            <Input style={{width:'100%'}} placeholder="Field Id" />
                        </Form.Item>
                        <Form.Item
                            {...restField}
                            label={'Name'}
                            name={[name, 'name']}
                            style={{width:'100%'}}
                            rules={[{ required: true, message: 'Field Name' }]}
                        >
                            <Input style={{width:'100%'}} placeholder="Field Name" />
                        </Form.Item>
                        <Form.Item
                            {...restField}
                            label={'Type'}
                            name={[name, 'type']}
                            style={{width:'100%'}}
                            rules={[{ required: true, message: 'Missing type selection' }]}
                        >
                            <Select
                                style={{ width: '100%' }}
                                optionLabelProp="label"
                                placeholder={'Pick a type'}
                            >
                                {
                                    [
                                        {type: 'string',    name: 'String'}, 
                                        {type: 'number',    name: 'Number'},
                                        {type: 'date',      name: 'Date'}
                                    ].map((template, i) => 
                                        <Option value={template.type} label={template.name} key={i}>
                                            <Space>
                                                {template.name}
                                            </Space>
                                        </Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Space>
                ))}
                </>
            )}
            </Form.List>
            <Form.Item>
            <Button disabled={false} onClick={() => { parent.done() }} type="primary">
                Back
            </Button>
            </Form.Item>
        </Form>
    )
}