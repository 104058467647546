import React, { useEffect, useState } from 'react';

import { message, Upload, Divider, Typography, Row, Col, DatePicker, Card, InputNumber, Select, Input, Button, Form, Space } from 'antd';
import { UploadOutlined, SendOutlined, LeftOutlined, MinusOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import type { UploadProps } from 'antd';

import { Guarantee, Entity, GuaranteeFile, UserUI } from '../../utils/data_types';

import { Currencies, GuaranteeStatus, GuaranteeType } from '../../utils/constants';

import { isMobileBrowser } from '../../utils/utils';

import axios from 'axios';

export interface INewKeyProps {
    user?: UserUI;
    bearerToken: string;
    update: () => void;
    done: () => void;
}
export const NewKey: React.FC<INewKeyProps> = (parent) => {
    const { Option } = Select;
    const { Title, Paragraph } = Typography;
    const { TextArea } = Input;
    const { Dragger } = Upload;

    const [isMobile, _] = useState<boolean>(isMobileBrowser());
    const [files, setFiles] = useState<GuaranteeFile[]>([]);
    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const [issuers, setIssuers] = useState<Entity[]>([]);
    const [beneficiaries, setBeneficiaries] = useState<Entity[]>([]);
    const [applicants, setApplicants] = useState<Entity[]>([]);

    const uint8ArrayToBase64 = (buffer: Uint8Array): string => {
        let binary = '';
        const len = buffer.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(buffer[i]);
        }
        return window.btoa(binary);
    }

    useEffect(() => {
        axios.get(
            `${azure_function_url}/NFGX?command=issuers_list`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            setIssuers(x.data);
        });

        axios.get(
            `${azure_function_url}/NFGX?command=beneficiaries_list`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            setBeneficiaries(x.data);
        });

        axios.get(
            `${azure_function_url}/NFGX?command=applicants_list`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            setApplicants(x.data);
        });
    },[]);
    
    
    const onFinish = (values: any) => {
        
        axios.post(
            `${azure_function_url}/NCID?command=keys_new`,
            {
                name: values.key_name,
                description: values.key_description
            },
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            parent.update();
        });
        parent.done();
    };

    return (
        <Form
            name="new_key_form"
            onFinish={onFinish}
            autoComplete="off"
        >
            <Card 
                title={'New Key'}
                extra={
                    <Space>
                        <Button style={{float:'right'}} icon={<LeftOutlined />} onClick={() => parent.done() }></Button> 
                    </Space>
                }
            >
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Title level={3} type="secondary">
                            Name
                        </Title>
                        </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Form.Item
                            name={'key_name'}
                            style={{width:'100%'}}
                            rules={[{ required: true, message: 'Key Name' }]}
                        >
                            <Input style={{width:'100%'}} placeholder="Key Name" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Title level={4} type="secondary">
                            Description
                        </Title>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Form.Item
                            name={'key_description'}
                            style={{width:'100%'}}
                            rules={[{ required: true, message: 'Key Description' }]}
                            >
                                <TextArea rows={4} placeholder='Key Description'/>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button icon={<SendOutlined />} type="primary" htmlType="submit">
                        Submit
                    </Button>
                </div>
            </Card>
        </Form>
    )
}