// App.tsx

import React, { useState, useEffect } from 'react';
import { Breadcrumb, Button, Menu, Layout, Image } from 'antd';
import { KeyOutlined, InfoCircleOutlined, MenuOutlined, CloseOutlined, FileSyncOutlined, UserOutlined, TeamOutlined, UndoOutlined, HomeOutlined, FileProtectOutlined, InboxOutlined, UnorderedListOutlined, ShoppingCartOutlined, LogoutOutlined, SnippetsOutlined } from '@ant-design/icons';

import { Routes, Route } from 'react-router-dom';

import axios from 'axios';

// Login Start
import { useNavigate } from "react-router-dom";
import { MsalProvider, MsalAuthenticationTemplate, MsalAuthenticationResult, useMsal } from '@azure/msal-react';
import { InteractionType, IPublicClientApplication } from '@azure/msal-browser';
import { CustomNavigationClient } from "./utils/auth/NavigationClient";
import { loginRequest } from "./utils/auth/authConfig";
import { msalInstance } from "./index";
// Login End

import { parseJSON } from './utils/utils';

import { ListSchemas } from "./components/schemas/ListSchemas";
import { Home } from "./components/main/Home";
import { Documents } from "./components/documents/Documents";

import type { MenuProps } from 'antd';

import { PointThreshold, EmployeeStatus, adminURL } from './utils/constants';

import { Guarantees } from './components/guarantees/Guarantees';
import { Keys } from './components/keys/Keys';
import { Outbox } from './components/main/Outbox';
import { Clients } from './components/clients/Clients';
import { Employees } from './components/employees/Employees';

import { Subscribe } from './components/onboarding/Subscribe';
import { TermsConditions } from './components/onboarding/TermsConditions';

import { UserUI, Schema } from './utils/data_types';

import { isMobileBrowser } from './utils/utils';

import { Loading } from './components/main/Loading';

import { logoPath } from './utils/config';

const { Content, Sider, Header } = Layout;

type AppProps = {
    pca: IPublicClientApplication;
};

type MenuItem = Required<MenuProps>['items'][number];

const getItem = (
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem => {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
};

const Main = (vars: { isAdminPage: boolean}) => {
    const [bearerToken, setBearerToken] = useState('');
    const [page, setPage] = useState<string>('home');

    const [schema, setSchema] = useState<Schema | undefined>();
    
    const [items, setItems] = useState<MenuItem[]>([]);

    const { instance } = useMsal();
    
    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const [user, setUser] = useState<UserUI>();
    const [pt100, setPt100] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    const [collapsed, setCollapsed] = useState<boolean>(true);
    const [isMobile, _] = useState<boolean>(isMobileBrowser());

    const menuKeys : { [key: string]: { label: string, icon:  any} } = {
        'home': { label: 'Home', icon:  <HomeOutlined  style={{ fontSize: isMobile ? '20px' : '15px' }}/>},
        'guarantees': { label: 'Guarantees', icon:  <InboxOutlined  style={{ fontSize: isMobile ? '20px' : '15px' }}/>},
        'keys': { label: 'Keys', icon:  <KeyOutlined style={{ fontSize: isMobile ? '20px' : '15px' }}/>},
        'verifiedDocuments': { label: 'Documents', icon:  <FileProtectOutlined  style={{ fontSize: isMobile ? '20px' : '15px' }}/>},
        'logout': { label: 'Logout', icon:  <LogoutOutlined  style={{ fontSize: isMobile ? '20px' : '15px' }}/>},
        
        'ekyc': { label: 'Home', icon:  <HomeOutlined  style={{ fontSize: isMobile ? '20px' : '15px' }}/>},
        'clients': { label: 'Clients', icon:  <TeamOutlined  style={{ fontSize: isMobile ? '20px' : '15px' }}/>},
        'events': { label: 'Events', icon:  <UnorderedListOutlined  style={{ fontSize: isMobile ? '20px' : '15px' }}/>},
        'schemas': { label: 'Forms', icon:  <SnippetsOutlined  style={{ fontSize: isMobile ? '20px' : '15px' }}/>},
        'employees': { label: 'Employees', icon:  <UserOutlined  style={{ fontSize: isMobile ? '20px' : '15px' }}/>},
        'subscription': { label: 'Subscription', icon:  <ShoppingCartOutlined  style={{ fontSize: isMobile ? '20px' : '15px' }}/>},
        'reload': { label: 'Reload', icon:  <UndoOutlined  style={{ fontSize: isMobile ? '20px' : '15px' }}/>},
        
        'demo': { label: 'Demo', icon:  <InfoCircleOutlined  style={{ fontSize: isMobile ? '20px' : '15px' }}/>},
    };

    const update = (token?: string) => {

        if(token || bearerToken){    
            axios.get(
                `${azure_function_url}/NCID?command=id`,
                {
                    maxContentLength: Number.POSITIVE_INFINITY,
                    headers: {
                        'Authorization': token || bearerToken
                    }
                }
            ).then(x => {
                const _user: UserUI = parseJSON(x.data);

                setUser(_user);
                setPt100((_user.data.personal?.points?.amount || 0) >= PointThreshold);
                
                if(_user?.entity?.permission === EmployeeStatus.OWNER){
                    setItems([
                        getItem(menuKeys['home'].label, 'home', menuKeys['home'].icon),
                        getItem(menuKeys['guarantees'].label, 'guarantees', menuKeys['guarantees'].icon),
                        getItem(menuKeys['keys'].label, 'keys', menuKeys['keys'].icon),
                        getItem(menuKeys['employees'].label, 'employees', menuKeys['employees'].icon),
                        getItem(menuKeys['clients'].label, 'clients', menuKeys['clients'].icon),
                        getItem(menuKeys['subscription'].label, 'subscription', menuKeys['subscription'].icon),
                        getItem(menuKeys['verifiedDocuments'].label, 'verifiedDocuments', menuKeys['verifiedDocuments'].icon),
                        getItem(menuKeys['schemas'].label, 'schemas', menuKeys['schemas'].icon),




                        // getItem(menuKeys['ekyc'].label, 'ekyc', menuKeys['ekyc'].icon),
                        // // getItem(menuKeys['verifiedDocuments'].label, 'verifiedDocuments', menuKeys['verifiedDocuments'].icon),
                        // getItem(menuKeys['guarantees'].label, 'guarantees', menuKeys['guarantees'].icon),
                        // { type: 'divider' },
                        // getItem(menuKeys['clients'].label, 'clients', menuKeys['clients'].icon),
                        // getItem(menuKeys['events'].label, 'events', menuKeys['events'].icon),
                        // // getItem(menuKeys['schemas'].label, 'schemas', menuKeys['schemas'].icon),
                        // // { type: 'divider' },
                        // getItem(menuKeys['employees'].label, 'employees', menuKeys['employees'].icon),
                        // getItem(menuKeys['subscription'].label, 'subscription', menuKeys['subscription'].icon),
                        // // { type: 'divider' },
                        // getItem(menuKeys['logout'].label, 'logout', menuKeys['logout'].icon),
                        // // { type: 'divider' },
                        // getItem('Demo', 'demo', menuKeys['reload'].icon, [
                        //     getItem(menuKeys['reload'].label, 'reload', menuKeys['reload'].icon),
                        //     getItem(menuKeys['reload_kyc'].label, 'reload_kyc', menuKeys['reload_kyc'].icon),
                        // ]),
                        
                    ]);
                }
                else if(_user?.entity?.permission === EmployeeStatus.ADMIN){
                    setItems([
                        getItem(menuKeys['home'].label, 'home', menuKeys['home'].icon),
                        getItem(menuKeys['guarantees'].label, 'guarantees', menuKeys['guarantees'].icon),
                        getItem(menuKeys['keys'].label, 'keys', menuKeys['keys'].icon),
                        getItem(menuKeys['employees'].label, 'employees', menuKeys['employees'].icon),
                        getItem(menuKeys['clients'].label, 'clients', menuKeys['clients'].icon),

                        
                        getItem(menuKeys['verifiedDocuments'].label, 'verifiedDocuments', menuKeys['verifiedDocuments'].icon),
                        getItem(menuKeys['schemas'].label, 'schemas', menuKeys['schemas'].icon),
                        // getItem(menuKeys['ekyc'].label, 'ekyc', menuKeys['ekyc'].icon),
                        // getItem(menuKeys['verifiedDocuments'].label, 'verifiedDocuments', menuKeys['verifiedDocuments'].icon),
                        // getItem(menuKeys['guarantees'].label, 'guarantees', menuKeys['guarantees'].icon),
                        // { type: 'divider' },
                        // getItem(menuKeys['clients'].label, 'clients', menuKeys['clients'].icon),
                        // getItem(menuKeys['events'].label, 'events', menuKeys['events'].icon),
                        // getItem(menuKeys['schemas'].label, 'schemas', menuKeys['schemas'].icon),
                        // { type: 'divider' },
                        // getItem(menuKeys['employees'].label, 'employees', menuKeys['employees'].icon),
                        // { type: 'divider' },
                        // getItem(menuKeys['reload'].label, 'reload', menuKeys['reload'].icon),
                        // getItem(menuKeys['reload_kyc'].label, 'reload_kyc', menuKeys['reload_kyc'].icon),
                        // getItem(menuKeys['logout'].label, 'logout', menuKeys['logout'].icon),
                    ]);
                }
                else if(_user?.entity?.permission){
                    setItems([
                        getItem(menuKeys['home'].label, 'home', menuKeys['home'].icon),
                        getItem(menuKeys['guarantees'].label, 'guarantees', menuKeys['guarantees'].icon),
                        getItem(menuKeys['keys'].label, 'keys', menuKeys['keys'].icon),
                        // getItem(menuKeys['employees'].label, 'employees', menuKeys['employees'].icon),
                        getItem(menuKeys['clients'].label, 'clients', menuKeys['clients'].icon),

                        
                        getItem(menuKeys['verifiedDocuments'].label, 'verifiedDocuments', menuKeys['verifiedDocuments'].icon),
                        getItem(menuKeys['schemas'].label, 'schemas', menuKeys['schemas'].icon),
                        // getItem(menuKeys['ekyc'].label, 'ekyc', menuKeys['ekyc'].icon),
                        // getItem(menuKeys['verifiedDocuments'].label, 'verifiedDocuments', menuKeys['verifiedDocuments'].icon),
                        // getItem(menuKeys['guarantees'].label, 'guarantees', menuKeys['guarantees'].icon),
                        // { type: 'divider' },
                        // getItem(menuKeys['clients'].label, 'clients', menuKeys['clients'].icon),
                        // getItem(menuKeys['events'].label, 'events', menuKeys['events'].icon),
                        // getItem(menuKeys['schemas'].label, 'schemas', menuKeys['schemas'].icon),
                        // { type: 'divider' },
                        // getItem(menuKeys['reload'].label, 'reload', menuKeys['reload'].icon),
                        // getItem(menuKeys['reload_kyc'].label, 'reload_kyc', menuKeys['reload_kyc'].icon),
                        // getItem(menuKeys['logout'].label, 'logout', menuKeys['logout'].icon)
                    ]);
                }
                else{
                    setItems([
                        getItem(menuKeys['home'].label, 'home', menuKeys['home'].icon),
                        getItem(menuKeys['guarantees'].label, 'guarantees', menuKeys['guarantees'].icon),
                        getItem(menuKeys['keys'].label, 'keys', menuKeys['keys'].icon),
                        // getItem(menuKeys['employees'].label, 'employees', menuKeys['employees'].icon),

                        
                        getItem(menuKeys['verifiedDocuments'].label, 'verifiedDocuments', menuKeys['verifiedDocuments'].icon),
                        getItem(menuKeys['schemas'].label, 'schemas', menuKeys['schemas'].icon),
                        
                        // { type: 'divider' },
                        // getItem(menuKeys['logout'].label, 'logout', menuKeys['logout'].icon),

                        // getItem(menuKeys['demo'].label, 'demo', menuKeys['demo'].icon,[
                        //     getItem(menuKeys['reload'].label, 'reload', menuKeys['reload'].icon),
                        //     getItem(menuKeys['reload_kyc'].label, 'reload_kyc', menuKeys['reload_kyc'].icon),
                        // ]),
                        
                    ]);
                }

                setLoading(false);
            });
        }
    }

    const getToken = () => {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        }).then(response => {
            const bearer = `Bearer ${response.accessToken}`;
            setBearerToken(bearer);
            update(bearer);
        });
    }
    
    


    useEffect(() => {
        getToken();
        const id = setInterval(() => {
                console.log('------- UPDATE')
                getToken();
        }, 1000 * 60 * 15); 

        return () => clearInterval(id); 
    // eslint-disable-next-line
    }, []);

    const onClick: MenuProps['onClick'] = (e) => {
        setCollapsed(true);

        switch(e.key){
            case 'reload':
                update();
                break;

            case 'subscription':
                console.log('subscription');
                axios.post(
                    `${azure_function_url}/NCID?command=payments_dashboard`,
                    {
                        redirect_url: window.location.origin
                    },
                    {
                        maxContentLength: Number.POSITIVE_INFINITY,
                        headers: {
                            'Authorization': bearerToken
                        }
                    }
                ).then(x => {
                    window.location.replace(x.data.url);
                });
                break;
            case 'logout':
                console.log('logout');
                instance.logoutRedirect();
                break;
            default:
                setPage(e.key);
                break;
        }
    };
    
    return (
        loading ?
            <Loading />
        :
        <>
            <Header 
                style={{ 
                    padding: 0, 
                    background: 'rgba(37, 39, 40, 0.8)', 
                    position: 'fixed', 
                    width:'100%', 
                    zIndex:1,
                    borderBottom: '1px solid white'
                }}
            >
                <a
                    style={{
                        left:'15px',
                        position: 'relative'
                    }}

                    href='/'
                >
                    <Image
                        preview={false}
                        height={35}
                        width={140}
                        src={logoPath}
                    />
                </a>
                <Breadcrumb 
                    items={[{ title: menuKeys.hasOwnProperty(page) ? menuKeys[page].label : '' }]} 
                />
                
                <Button
                    type="text"
                    icon={collapsed ? <MenuOutlined /> : <CloseOutlined />}
                    onClick={() => {
                        setCollapsed(!collapsed);
                        update();
                    }}
                    style={{
                        color: 'white',
                        top:'-95px',
                        fontSize: '16px',
                        float:'right',
                        width: 64,
                        height: 64,
                    }}
                />
            </Header>
            <Layout style={{ 
                height: '100vmh', 
                paddingTop:'64px'
            }}>
                
                {
                    <>
                        <Sider
                            width={collapsed ? undefined : '100vw'}
                            collapsed={collapsed}
                            collapsedWidth={0}
                            style={{
                                transition: 'width 0.2s',
                                overflow: 'auto', 
                                backgroundColor: 'none',
                                background:'none',
                                margin: '0 0 0 0 0',
                                position: 'fixed',
                                zIndex: 100,
                                left: collapsed ? '-50px' : undefined
                            }}
                        >
                    
                            <Menu 
                                onClick={onClick} 
                                style={
                                        collapsed ?
                                        { 
                                            width: 45,
                                            height:'calc(100vh - 154px)'
                                        }
                                        :
                                        {
                                            right: 0,
                                            position: 'fixed',

                                            visibility: 'visible', 
                                            backgroundColor: 'rgba(37, 39, 40, 0.8)',
                                            background:'rgba(37, 39, 40, 0.8)',
                                            paddingTop:'20px',
                                            height:'calc(100vh - 154px)',
                                            fontSize: '25px',
                                            fontWeight: 100,
                                            fontFamily: 'Poppins,sans-serif',
                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                            overflow: 'hidden',
                                            color: 'white',

                                            maxWidth: isMobile ? undefined : '400px',

                                            flex: 1,
                                            overflowY: 'auto'
                                        }
                                } 
                                mode="inline"
                                items={items.map(x => {
                                    
                                    let tmp: any = {...x};
                                    if(collapsed)
                                        tmp.label = undefined;
                                    
                                    return tmp;
                                })} 
                            />
                            
                        </Sider>

                        <div 
                            style={
                                collapsed ?
                                { 
                                    position:'fixed',
                                    left:-100,

                                }
                                :
                                { 
                                    display: 'flex', 
                                    width: isMobile ? '100%' : '400px',
                                    right: 0,
                                    bottom: 0,
                                    zIndex: 100,
                                    position: 'fixed',            
                                }
                            }
                        >
                            <Button onClick={() => { }} icon={<HomeOutlined style={{fontSize:'30px', }}/>} style={{ flex: 1, height: '90px', borderRadius:0, borderTopColor:'rgba(255, 255, 255, 0.6)', borderRightColor:'rgba(255, 255, 255, 0.6)' }} type="primary" />
                            <Button onClick={() => { }} icon={<InfoCircleOutlined style={{fontSize:'30px', }}/>} style={{ flex: 1, height: '90px', borderRadius:0, borderTopColor:'rgba(255, 255, 255, 0.6)', borderRightColor:'rgba(255, 255, 255, 0.6)' }} type="primary" />
                            <Button onClick={() => { instance.logoutRedirect();}} icon={<LogoutOutlined style={{fontSize:'30px', }}/>} style={{ flex: 1, height: '90px', borderRadius:0, borderTopColor:'rgba(255, 255, 255, 0.6)' }} type="primary" />
                        </div>

                    </>
                }
                <Layout style={{ padding: '10px 10px 24px 10px', filter: !collapsed ? 'blur(10px)' : 'none' }}>
                    <Content style={{
                        height:'100vmh',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                            <div style={ isMobile ? { width: '97%' } : { width: '75%' } }>
                            {
                                page === 'home' ? <Home bearerToken={bearerToken} updateUser={() => update() } updateBackend={() => {} } user={user} pt100={pt100} /> :
                                page === 'keys' ? <Keys bearerToken={bearerToken} user={user} />:
                                page === 'guarantees' ? <Guarantees bearerToken={bearerToken} user={user} />:

                                page === 'verifiedDocuments' ? <Documents update={update} user={user} data={user?.data} bearerToken={bearerToken} pt100={pt100} doc={undefined}></Documents> :
                                page === 'schemas' ? <ListSchemas user={user} bearerToken={bearerToken} schema={schema} setSchema={setSchema} /> :
                                page === 'events' ? <Outbox bearerToken={bearerToken} user={user} /> :
                                page === 'clients' ? <Clients bearerToken={bearerToken} user={user} /> :
                                page === 'employees' ? <Employees bearerToken={bearerToken} user={user} /> :
                                <Home bearerToken={bearerToken} updateUser={() => update() } updateBackend={() => {} } user={user} pt100={pt100} />
                            }
                            </div>
                    </Content>
                </Layout>
        </Layout>
        </>
    );
}

const CheckAccess = (vars: { isAdminPage: boolean}) => {
    const [isSubscribed, setSubscribed] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [isAcceptedTC, setIsAcceptedTC] = useState(false);
    const [bearerToken, setBearerToken] = useState('');

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const update = () => {
        const account = msalInstance.getActiveAccount();
        if(account){
            msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: account
            }).then(response => {
                const bearer = `Bearer ${response.accessToken}`;
                setBearerToken(bearer);

                axios.get(
                    `${azure_function_url}/NCID?command=id`,
                    {
                        maxContentLength: Number.POSITIVE_INFINITY,
                        headers: {
                            'Authorization': bearer
                        }
                    }
                ).then(x => {
                    const _user: UserUI = parseJSON(x.data);

                    setIsAcceptedTC(_user.terms_accepted === 1);
                    if(_user.terms_accepted === 1){

                        if(vars.isAdminPage){
                            const _isSubscribed = _user.stripe_active === 1;

                            setSubscribed(_isSubscribed);
                        }
                        else{
                            const _isSubscribed = _user.stripe_active === 1;
                            const _needsSubscription = false;//_user.entity?.permission === EmployeeStatus.OWNER;

                            if(_needsSubscription)
                                setSubscribed(_isSubscribed);
                            else
                                setSubscribed(true);
                        }
                    }
                    
                    setLoading(false);
                });
            });
        }
    }

    useEffect(() => {
        update();
    }, []);

    return (
        loading ? <Loading/>
        :
        
            !isAcceptedTC ?
                <TermsConditions bearerToken={bearerToken} update={update}/>
            : isSubscribed?
                <Main isAdminPage={vars.isAdminPage} />
            :                    
                <Subscribe />
        
    );
}

const ErrorComponent: React.FC<MsalAuthenticationResult> = ({error}) => {
    return <div>An Error Occurred: {error ? JSON.stringify(error) : "unknown error"}</div>;
}

const App = ({ pca }: AppProps) => {
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);
 
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalProvider instance={pca}>
            <MsalAuthenticationTemplate 
                interactionType={InteractionType.Redirect} 
                authenticationRequest={authRequest} 
                errorComponent={ErrorComponent} 
                loadingComponent={() => (<div>loading...</div>)}
            >
                <Routes>
                    <Route path='/' element={<CheckAccess isAdminPage={(window.location.pathname === adminURL || window.location.hostname === adminURL)}/>}/>
                    <Route path='/admin' element={<CheckAccess isAdminPage={(window.location.pathname === adminURL || window.location.hostname === adminURL)}/>}/>
                </Routes>
                
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
}

export default App;