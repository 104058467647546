import { InputNumber, Select, Input, Button, Form, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { v4 as uuidv4 } from 'uuid';

import { UserUI } from '../../utils/data_types';

import { FrankieOneDocumentTypes, PersonalDataTypes, CustomDataTypes } from '../../utils/constants';

import axios from 'axios';

export interface INewSchemaProps {
    user?: UserUI;
    bearerToken: string;
    update: () => void;
    done: () => void;
}
export const NewSchema: React.FC<INewSchemaProps> = (parent) => {
    const { Option } = Select;

    const azure_function_url = process.env.AZURE_FUNCTION_URL;
    
    const onFinish = (values: any) => {
        axios.post(
            `${azure_function_url}/Databoss?command=forms_add`,
            {
                schema: values,
                companyid: parent.user?.entity?.id
            },
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            parent.update();
        });

        parent.done();
    };

    const uuid = uuidv4();

    return (
        <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            style={{ maxWidth: 600 }}
            autoComplete="off"
        >
            <Form.Item
                name={'id'}
                style={{width:'100%'}}
                initialValue={uuid}
                rules={[{ required: true, message: 'Form ID' }]}
            >
                <Input disabled style={{width:'100%'}} placeholder="Form ID" />
            </Form.Item>
            <Form.Item
                name={'name'}
                style={{width:'100%'}}
                rules={[{ required: true, message: 'Form Name' }]}
            >
                <Input style={{width:'100%'}} placeholder="Form Name" />
            </Form.Item>
            <Form.Item
                name={'description'}
                style={{width:'100%'}}
                rules={[{ required: true, message: 'Form Description' }]}
            >
                <Input style={{width:'100%'}} placeholder="Form Description" />
            </Form.Item>
            <Form.Item
                name={'expiry'}
                style={{width:'100%'}}
                rules={[{ required: true, message: 'Days to Expiry' }]}
            >
                <InputNumber
                    min={0} 
                    style={{ width: '100%' }}
                    placeholder="Days to expiry"
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
            </Form.Item>
            <Form.List name="fields">
            {(fields, { add, remove }) => (
                <>
                {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8, width:'100%'}} align="baseline">
                        <Form.Item
                            {...restField}
                            name={[name, 'id']}
                            style={{width:'100%'}}
                            rules={[{ required: true, message: 'Field Id' }]}
                        >
                            <Input style={{width:'100%'}} placeholder="Field Id" />
                        </Form.Item>
                        <Form.Item
                            {...restField}
                            name={[name, 'name']}
                            style={{width:'100%'}}
                            rules={[{ required: true, message: 'Field Name' }]}
                        >
                            <Input style={{width:'100%'}} placeholder="Field Name" />
                        </Form.Item>
                        <Form.Item
                            {...restField}
                            name={[name, 'type']}
                            style={{width:'100%'}}
                            rules={[{ required: true, message: 'Missing type selection' }]}
                        >
                            <Select
                                style={{ width: '100%' }}
                                optionLabelProp="label"
                                placeholder={'Pick a type'}
                            >
                                {
                                    [
                                        {type: FrankieOneDocumentTypes.PASSPORT, name: 'Passport'},
                                        {type: FrankieOneDocumentTypes.DRIVERS_LICENCE, name: 'Driver License'},
                                        // {type: 'NATIONAL_ID', name: 'National ID'},
                                        {type: FrankieOneDocumentTypes.NATIONAL_HEALTH_ID, name: 'Medicare'},

                                        {type: PersonalDataTypes.NAME, name: 'Name'},
                                        {type: PersonalDataTypes.DOB, name: 'Date of Birth'},
                                        {type: PersonalDataTypes.ADDRESS, name: 'Address'},
                                        // {type: 'PHOTO_ID', name: 'Photo ID'},
                                        {type: CustomDataTypes.BANK_ACCOUNT, name: 'Bank Account'},
                                        {type: CustomDataTypes.FILE_UPLOAD, name: 'File'},

                                        {type: 'string', name: 'String'},
                                        {type: 'number', name: 'Number'},
                                        {type: 'date', name: 'Date'}
                                    ].map((template, i) => 
                                        <Option value={template.type} label={template.name} key={i}>
                                            <Space>
                                                {template.name}
                                            </Space>
                                        </Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                ))}
                <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add field
                    </Button>
                </Form.Item>
                </>
            )}
            </Form.List>
            <Form.Item>
                <Space size="small">
                    
                    <Button onClick={() => { parent.update(); parent.done(); }}  style={{ textAlign: 'right' }}>
                        Back
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                
                </Space>
            </Form.Item>
        </Form>
    )
}