import React, { useState, useEffect } from 'react';
import { Popconfirm, Upload, Modal, Form, List, Card, Button, message } from 'antd';

import { UploadOutlined, PlusOutlined, DeleteOutlined, FileOutlined } from '@ant-design/icons';

import { CustomDataTypes } from '../../utils/constants';
import { UserUI } from '../../utils/data_types';

import { FileViewer } from '../guarantees/FileViewer';

import { isMobileBrowser } from '../../utils/utils';

import axios from 'axios';

import type { UploadProps } from 'antd';

import { Shared, Views } from './Traces';

export interface IFilesProps {
    user?: UserUI;
    bearerToken: string;
    update: () => void;
}
export const ListFiles: React.FC<IFilesProps> = (parent) => {
    
    console.log(parent)

    const azure_function_url = process.env.AZURE_FUNCTION_URL;
    
    const [accounts, setAccounts] = useState<{ id: string, name: string, bsb: string, account: string }[]>([]);
    const [collapsed, setCollapsed] = useState<boolean>(isMobileBrowser());

    const [newModal, setNewModal] = useState(false);
    const [form] = Form.useForm();

    const [viewDoc, setViewDoc] = useState<string>();
    const [viewFile, setViewFile] = useState();

    const update = () => axios.get(
        `${azure_function_url}/Databoss?command=files_uploads_list`,
        {
            maxContentLength: Number.POSITIVE_INFINITY,
            headers: {
                'Authorization': parent.bearerToken
            }
        }
    ).then((x) => {
        setAccounts(x.data);
    });

    useEffect(() => { 
        update();
    }, []);

    const { Dragger } = Upload;

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        customRequest: async (options: any) => { 
            
            // eslint-disable-next-line
            const { onSuccess, onError, file, onProgress } = options;
            const buffer = await (file as File).arrayBuffer();
            const bytes = new Uint8Array(buffer);

            const fmData = new FormData();

            fmData.append("file", new Blob([bytes], {type:file.type}), file.name);

            await axios.post(
                `${azure_function_url}/Databoss?command=files_uploads_new`,
                fmData,
                {
                    maxContentLength: Number.POSITIVE_INFINITY,
                    headers: {
                        "Content-Type": `multipart/form-data; boundery=${(fmData as any)._boundary}`,
                        'Authorization': parent.bearerToken
                    },
                }
            );

            if(onSuccess){
                onSuccess("Ok");
                update();
                setNewModal(false);
            }            
        },
        onChange: async (info) => {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            }
            else if (status === 'removed') {
                message.success(`${info.file.name} file removed successfully.`);
            }
            else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };

    return (
        accounts.length > 0 ?
        <>
            <br></br>
            <Card 
                bordered={false} 
                title={`Files`} 
                extra={<Button icon={<PlusOutlined/>} onClick={() => setNewModal(true) }></Button>}
                bodyStyle={{ padding: 0 }}
            ></Card>
            <br></br>
            <Card 
                bordered={false} 
            >
                {
                parent.user ?
                <>  
                    <List 
                        itemLayout="horizontal"
                        pagination={{
                            onChange: (page) => {
                              console.log(page);
                            },
                            pageSize: 3
                        }}

                        dataSource={accounts?.map((x, i: number) => {
                            let y: any = x;
                            y.key = i;
                            return y;
                        })}
                    
                        
                        renderItem={account =>
                                <List.Item 
                                    actions={[
                                        <Popconfirm
                                            title="Remove the file"
                                            description="Are you sure to remove this file?"
                                            onConfirm={async () => {
                                                message.info('delete is not implemented')
                                                // axios.get(
                                                //     `${azure_function_url}/Databoss?command=files_uploads_delete&id=${account.id}`,
                                                //     {
                                                //         maxContentLength: Number.POSITIVE_INFINITY,
                                                //         headers: {
                                                //             'Authorization': parent.bearerToken
                                                //         }
                                                //     }
                                                // ).then((data) => {
                                                //     update();
                                                // });
                                            } }
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button 
                                                icon={<DeleteOutlined />}
                                            >
                                                {collapsed ? '' : 'Delete'}
                                            </Button>
                                        </Popconfirm>
                                    ]} 
                                    key={account.key}
                                >
                                    <List.Item.Meta
                                        // key={account.key}
                                        avatar={<FileOutlined />}
                                        title={<a onClick={()=> { 
                                            setViewDoc(account.id); 

                                            axios.get(
                                                `${azure_function_url}/Databoss?command=files_uploads_get&id=${account.id}`,
                                                {
                                                    maxContentLength: Number.POSITIVE_INFINITY,
                                                    headers: {
                                                        'Authorization': parent.bearerToken
                                                    }
                                                }
                                            ).then((x) => {
                                                setViewFile(x.data);
                                            });

                                        } }>{account.name}</a>}
                                    /> 
                                </List.Item>
                        }
                        />                    
                </>
                :
                <></>
                }
            </Card>

            { viewFile ? <><br/><FileViewer viewFile={{ value: viewFile }} done={() =>  setViewFile(undefined) }/></> : <></> }

            { viewDoc ? <Shared id={viewDoc} bearerToken={parent.bearerToken} type={CustomDataTypes.FILE_UPLOAD} close={() => setViewDoc(undefined)} /> : <></> }
            { viewDoc ? <Views id={viewDoc} bearerToken={parent.bearerToken} type={CustomDataTypes.FILE_UPLOAD} close={() => setViewDoc(undefined)}/> : <></> }


            <Modal title={"New File"} closable={false} open={newModal} footer={null}>
                <Form
                        form={form}
                        name="new_file"
                        style={{ maxWidth: 600 }}
                        autoComplete="off"
                    >
                        <Form.Item name="file" rules={[{ required: true }]}>
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                <UploadOutlined />
                                </p>
                                <p className="ant-upload-text">{ collapsed ? 'Click to add a file' : 'Click or drag a file'}</p>
                            </Dragger>
                        </Form.Item>
                        <Form.Item>
                            <Button block htmlType="button" onClick={()=>{form.resetFields(); setNewModal(false)}}>
                                Cancel
                            </Button>
                        </Form.Item>
                    </Form> 
            </Modal>
        </>
        :
        <></>
    )
}