import React, { useState, useEffect } from 'react';

import axios from 'axios';

export interface ISmartUIProps {
    // user?: UserUI;
    // employee: EmployeeLists;
    smartUIDoc: string;
    bearerToken: string;
    done: () => void;
}
export const SmartUI: React.FC<ISmartUIProps> = (parent) => {

    const azure_function_url = process.env.AZURE_FUNCTION_URL;
    
    const getTokens = async () => {
        const smartUItoken = await axios.get(
            `${azure_function_url}/Databoss?command=kyc_token`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        );
        
        return smartUItoken.data;
    }

    const [entityId, setEntityId] = useState();
    const [ffToken, setFfToken] = useState();
    const [smartUIDoc, setSmartUIDoc] = useState<string | undefined>(parent.smartUIDoc);
    
    useEffect(() => {
        if (entityId && ffToken && smartUIDoc) {
            window.frankieFinancial.initialiseOnboardingWidget({
                entityId: entityId,
                config: {
                    frankieBackendUrl: "https://backend.demo.frankiefinancial.io",
                    documentTypes: [smartUIDoc],
                    idScanVerification: {
                      welcomeScreen: {
                        title: "Verify your identity",
                        content: [
                          "We need to collect some personal information to verify your identity before we can open your account.",
                        ],
                        ctaText: "Start Identity Verification",
                      },
                    },
                    checkProfile: "auto",
                    // injectedCssTagID: "ff-smart-ui",
                    maxAttemptCount: 50,
                    googleAPIKey: "AIzaSyCP8NxPZOrCTRRshl-QaB1qdGITc9I7vug",
                    requestAddress: { acceptedCountries: "ALL" },
                    consentText:
                      "I agree with the terms described in the Consent section of the Company's webpage"
                },
                ffToken: ffToken,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityId, ffToken, smartUIDoc]);

    useEffect(() => {
        getTokens().then(data => {
            setFfToken(data.token);
            setEntityId(data.entityid);

        });
    },[]);

    window.addEventListener('SCREEN:SUCCESS', ((e: any) => {}));

    window.addEventListener('FF_EXTERNAL_IDV_CHECK_COMPLETED', ((e: any) => {
        setEntityId(undefined);
        setFfToken(undefined);
        setSmartUIDoc(undefined);

        axios.get(
            `${azure_function_url}/Databoss?command=kyc_update`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(() => {
            parent.done();
        })
    }));

    window.addEventListener('DESTROY', ((e: any) => {}));

    window.addEventListener('FF_SAVE_RESULT', ((e: any) => {}));

    window.addEventListener('FF_CHECK_RESULT', ((e: any) => {}));

    return <ff-onboarding-widget width="100%" height="650px" />
}