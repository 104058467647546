import React, { useEffect, useState, useReducer } from 'react';
import { Typography, Space, Button, Form, Input, Card, Divider, message } from 'antd';

import { CheckCircleOutlined, SearchOutlined } from '@ant-design/icons';

import { UserUI } from '../../utils/data_types';

import axios from 'axios';

export interface IEntityRegistrationProps {
    user?: UserUI;
    bearerToken: string;
    updateUser: () => void;
}
export const EntityRegistration: React.FC<IEntityRegistrationProps> = (parent) => {

    const { Title, Paragraph } = Typography;

    const [company, setCompany] = useState<{name:string, address:string}>();
    const [abn, setAbn] = useState();
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const [formSearch] = Form.useForm();
    const [formSubmit] = Form.useForm();

    const update = () => {        
        parent.updateUser();
    }

    useEffect(() => { 
        update();
    }, []);

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    return (
        <Card 
            bordered={false} 
            // style={{
            //     width: '75%',
            //     padding: '0 50px 0 50px'
            // }}
        >
            <Typography>
                <Title>Company Registration</Title>
                <Paragraph>
                We will now go through the process of registering your business. Please ensure that you are a company director.

                </Paragraph>
            </Typography>
            <Divider></Divider>
            <Form
                labelCol= {{ span: 8 }}
                wrapperCol= {{ span: 16 }}                    
                name="abn-search"
                form={formSearch}
                onFinish={(vals) => {
                    formSubmit.resetFields();
                    forceUpdate();
                    axios.get(
                        `${azure_function_url}/Databoss?command=kyc_company_search&abn=${vals.ABN}`,
                        {
                            maxContentLength: Number.POSITIVE_INFINITY,
                            headers: {
                                'Authorization': parent.bearerToken
                            }
                        }
                    ).then(x => {
                        const comp = x.data[0];
                        setAbn(vals.ABN);
                        if(comp){
                            setCompany({ name: comp.name, address: `${comp.postalCode} ${comp.state}`});
                            forceUpdate();
                            formSubmit.setFieldsValue({ name: comp.name, address: `${comp.postalCode} ${comp.state}`});
                        }
                        else{
                            setCompany(undefined);
                            message.error('Company not found');
                        }
                    });
                }}
                style={{ maxWidth: 600 }}
                >
                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="ABN" label="ABN" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="address" label="Address" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item 
                    wrapperCol= {{ offset: 8, span: 16 }}
                >
                    <Space>
                        {/* <Button icon={<SearchOutlined />} type="primary" htmlType="submit">Search</Button> */}
                        <Button icon={<CheckCircleOutlined />} type="primary" htmlType="submit">Submit</Button>
                    </Space>
                </Form.Item>
            </Form>
            {
                    !company ?
                    <></>
                    :                        
                <>
                    <Divider></Divider>
                    <Form
                        labelCol= {{ span: 8 }}
                        wrapperCol= {{ span: 16 }}                    
                        name="company-registration"
                        initialValues={company}
                        form={formSubmit}
                        
                        onFinish={(vals) => {
                            axios.post(
                                `${azure_function_url}/Databoss?command=kyc_company_create`,
                                {
                                    abn: abn,
                                    name: vals.name,
                                    address: vals.address
                                },
                                {
                                    maxContentLength: Number.POSITIVE_INFINITY,
                                    headers: {
                                        'Authorization': parent.bearerToken
                                    }
                                }
                            ).then(() => {
                                update();
                            });
                        }}
                        style={{ maxWidth: 600 }}
                    >
                        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item name="address" label="Address" rules={[{ required: true }]}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item 
                            wrapperCol= {{ offset: 8, span: 16 }}
                        >
                            <Space>
                                <Button icon={<CheckCircleOutlined />} type="primary" htmlType="submit">Submit</Button>                                
                            </Space>
                        </Form.Item>
                    </Form>
                </>
            }
        </Card>
    )
}