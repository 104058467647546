import React, { useState, useReducer, useEffect } from 'react';
import { Modal, Timeline, Space, Radio, Table, Slider, DatePicker, Popconfirm, Typography, Row, Col, List, Card, Button, message, Image } from 'antd';
import type { SliderMarks } from 'antd/es/slider';
import { CloseCircleOutlined, LeftOutlined, RightOutlined, DeleteOutlined } from '@ant-design/icons';

import { FileViewer } from './FileViewer';
import { EditGuarantee } from './EditGuarantee';

import { VerifiedDocumentTypes, CustomDataTypes, GuaranteeStatus } from '../../utils/constants';

import { UserUI } from '../../utils/data_types';

import { isMobileBrowser, shortenString, parseJSON } from '../../utils/utils';

import { recoverSignature } from '@notcentralised/notvault-sdk';

import dayjs from 'dayjs';

import axios from 'axios';
import Paragraph from 'antd/es/skeleton/Paragraph';

const { Column } = Table;

export interface IViewGuaranteeProps {
    user?: UserUI;
    bearerToken: string;
    doc: any;
    modal?: boolean;
    done: () => void;
}
export const ViewGuarantee: React.FC<IViewGuaranteeProps> = (parent) => {

    const { Title, Paragraph } = Typography;

    const [viewItem, setViewItem] = useState<any>();
    const [editGuarantee, setEditGuarantee] = useState(false);
    const [viewFile, setViewFile] = useState<any>();
    const [viewModal, setViewModal] = useState<any>();
    const [history, setHistory] = useState<any[]>([]);
    const [signatures, setSignatures] = useState<any[]>([]);
    
    const [isMobile, _] = useState<boolean>(isMobileBrowser());

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const t0 = parent.doc.guarantee.startDate.getTime();
    const t1 = Date.now();
    const t2 = parent.doc.guarantee.expiryDate.getTime();

    const t_today = Math.round(100 * (t1-t0) / (t2-t0));

    useEffect(() => {
        axios.get(
            `${azure_function_url}/NFGX?command=guarantee_versions&id=${parent.doc.id}`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {  
            console.log(x.data)
            setHistory(x.data);
        });
        
        // Get Known signatures for the document.
        axios.post(
            `${azure_function_url}/NCID?command=signature_list_doc`,
            {
                docid: parent.doc.id,
                version: parent.doc.version
            },
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(async x => {  
            if(Array.isArray(x.data) && x.data.length > 0){
                const doc_sigs = x.data;
                console.log(doc_sigs)
                const signers = await Promise.all(doc_sigs.map(async (sig: any) => {
                    const signer = await recoverSignature({message: sig.source === sig.id ? sig.hash : (sig.hash + sig.source), signature: sig.id }).signer;
                    let y: any = Object.assign({}, sig);
                    y.signer = '0x04' + signer;
                    return y;
                }))

                // Get Known Identities for given signers
                axios.post(
                    `${azure_function_url}/NCID?command=keys_get`,
                    {
                        publickeys: signers.map(x=>x.signer)
                    },
                    {
                        maxContentLength: Number.POSITIVE_INFINITY,
                        headers: {
                            'Authorization': parent.bearerToken
                        }
                    }
                ).then(async x => {  
                    if(Array.isArray(x.data)){
                        const record: Record<string, any> = x.data.reduce((acc: any, curr: any) => {
                            acc[curr.publickey] = curr;
                            return acc;
                        }, {} as Record<string, any>);
                        
                        setSignatures(signers.map((x: any)=> {
                            let y: any = Object.assign({}, x);
                            
                            y.email = record[x.signer].email;
                            y.name = record[x.signer].name;
                            return y;
                        }));
                    }
                })
            }
        });

        
    },[]);

    const marks: SliderMarks = {
        0: <DatePicker style={{top:25}} disabled showTime format="YYYY-MM-DD HH:mm:ss" value={ dayjs.unix(Date.parse(parent.doc.guarantee.startDate) / 1000) } />,
        [t_today]: 'Now',
        100: <DatePicker style={{minWidth:'185px', top:25}} disabled showTime format="YYYY-MM-DD HH:mm:ss" value={ dayjs.unix(Date.parse(parent.doc.guarantee.expiryDate) / 1000) } />,
    };
    
    return (
        editGuarantee ?
        <EditGuarantee 
            user={parent.user}
            bearerToken={parent.bearerToken}
            doc={parent.doc}
            update={()=>{}}
            done={()=>{setEditGuarantee(false)}}
        ></EditGuarantee>
        :
        <>
        <Card
            title={'Guarantee'}
            extra={
                parent.modal?<></>:
                <Radio.Group style={{width:'100%'}}>
                    <Radio.Button onClick={() => parent.done() }><LeftOutlined /></Radio.Button>
                    { parent.doc.guarantee.beneficiaries.map((x:any) => x.id).indexOf(parent.user?.entity?.id) > -1 && (signatures?.filter(x => x.reference_id === parent.user?.id).length === 0) ?
                    <>
                    <Popconfirm
                        title="Edit Guarantee"
                        description="Are you sure you want to edit the guarantee?"
                        onConfirm={async () => { 
                            // message.success('Edit successful');
                            setEditGuarantee(true);

                        } }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Radio.Button value="edit">Edit</Radio.Button>
                    </Popconfirm>
                    <Popconfirm
                        title="Accept Guarantee"
                        description="Are you sure you want to accept the guarantee?"
                        onConfirm={async () => { 

                            const last_signature = signatures && signatures.length === 0 ? undefined : signatures.sort((a, b) => Date.parse(b.created) - Date.parse(a.created))[0];

                            axios.post(
                                `${azure_function_url}/NCID?command=signature_request`,
                                {
                                    document: parent.doc.guarantee,
                                    source: last_signature?.id
                                },
                                {
                                    maxContentLength: Number.POSITIVE_INFINITY,
                                    headers: {
                                        'Authorization': parent.bearerToken
                                    }
                                }
                            ).then(x => {  

                                const signed_document = x.data;
                                
                                axios.post(
                                    `${azure_function_url}/NFGX?command=guarantee_agree`,
                                    {
                                        document: parent.doc.guarantee,
                                        credential: signed_document
                                    },
                                    {
                                        maxContentLength: Number.POSITIVE_INFINITY,
                                        headers: {
                                            'Authorization': parent.bearerToken
                                        }
                                    }
                                ).then(x => {
                                    
    
                                    console.log(x.data)
                                    message.success('Accept successfulll');
                                    parent.done();
                                });
                            });

                        } }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Radio.Button value="edit">Accept</Radio.Button>
                    </Popconfirm>
                    <Popconfirm
                        title="Deny Guarantee"
                        description="Are you sure you want to deny the guarantee?"
                        onConfirm={async () => { 
                            message.success('Deny successfulll');
                            parent.done();
                        } }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Radio.Button value="edit">Deny</Radio.Button>
                    </Popconfirm>
                    </>
                    : parent.doc.guarantee.beneficiaries.map((x:any) => x.id).indexOf(parent.user?.entity?.id) > -1 ? 
                    <>
                    <Popconfirm
                        title="Deny Guarantee"
                        description="Are you sure you want to deny the guarantee?"
                        onConfirm={async () => { 
                            message.success('Deny successfulll');
                            parent.done();
                        } }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Radio.Button value="edit">Cancel</Radio.Button>
                    </Popconfirm>
                    </>
                    : parent.doc.guarantee.applicants.map((x:any) => x.id).indexOf(parent.user?.entity?.id) > -1 && (signatures?.filter(x => x.reference_id === parent.user?.id).length === 0) ?
                    <>
                    <Popconfirm
                        title="Edit Guarantee"
                        description="Are you sure you want to edit the guarantee?"
                        onConfirm={async () => { 
                            // message.success('Edit successfulll');
                            setEditGuarantee(true);
                            // parent.done();

                        } }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Radio.Button value="edit">Edit</Radio.Button>
                    </Popconfirm>
                    <Popconfirm
                        title="Accept Guarantee"
                        description="Are you sure you want to accept the guarantee?"
                        onConfirm={async () => { 

                            const last_signature = signatures && signatures.length === 0 ? undefined : signatures.sort((a, b) => Date.parse(b.created) - Date.parse(a.created))[0];

                            axios.post(
                                `${azure_function_url}/NCID?command=signature_request`,
                                {
                                    document: parent.doc.guarantee,
                                    source: last_signature?.id
                                },
                                {
                                    maxContentLength: Number.POSITIVE_INFINITY,
                                    headers: {
                                        'Authorization': parent.bearerToken
                                    }
                                }
                            ).then(x => {  

                                const signed_document = x.data;
                                
                                axios.post(
                                    `${azure_function_url}/NFGX?command=guarantee_agree`,
                                    {
                                        document: parent.doc.guarantee,
                                        credential: signed_document
                                    },
                                    {
                                        maxContentLength: Number.POSITIVE_INFINITY,
                                        headers: {
                                            'Authorization': parent.bearerToken
                                        }
                                    }
                                ).then(x => {
                                    
    
                                    console.log(x.data)
                                    message.success('Accept successfulll');
                                    parent.done();
                                });
                            });

                        } }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Radio.Button value="edit">Accept</Radio.Button>
                    </Popconfirm>
                    <Popconfirm
                        title="Deny Guarantee"
                        description="Are you sure you want to deny the guarantee?"
                        onConfirm={async () => { 
                            message.success('Deny successfulll');
                            parent.done();
                        } }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Radio.Button value="edit">Deny</Radio.Button>
                    </Popconfirm>
                    </>
                    : parent.doc.guarantee.applicants.map((x:any) => x.id).indexOf(parent.user?.entity?.id) > -1 ? 
                    <>
                    <Popconfirm
                        title="Deny Guarantee"
                        description="Are you sure you want to deny the guarantee?"
                        onConfirm={async () => { 
                            message.success('Deny successfulll');
                            parent.done();
                        } }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Radio.Button value="edit">Cancel</Radio.Button>
                    </Popconfirm>
                    </>
                    : parent.doc.guarantee.issuers.map((x:any) => x.id).indexOf(parent.user?.entity?.id) > -1 && (signatures?.length > 1) && (signatures?.filter(x => x.reference_id === parent.user?.id).length === 1)  ?
                    <Popconfirm
                        title="Cancel Guarantee"
                        description="Are you sure you want to cancel the guarantee?"
                        onConfirm={async () => { 
                            
                            console.log('0--0', parent.doc)
                            const last_signature = signatures && signatures.length === 0 ? undefined : signatures.sort((a, b) => Date.parse(b.created) - Date.parse(a.created))[0];
                            console.log(last_signature)
                        } }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Radio.Button value="edit">Cancel</Radio.Button>
                    </Popconfirm>
                    : parent.doc.guarantee.issuers.map((x:any) => x.id).indexOf(parent.user?.entity?.id) > -1 && (signatures?.length > 1) && (signatures?.filter(x => x.reference_id === parent.user?.id).length === 0)  ?
                    <Popconfirm
                        title="Issue Guarantee"
                        description="Are you sure you want to issue the guarantee?"
                        onConfirm={async () => { 

                            const last_signature = signatures && signatures.length === 0 ? undefined : signatures.sort((a, b) => Date.parse(b.created) - Date.parse(a.created))[0];
                           
                            axios.post(
                                `${azure_function_url}/NCID?command=signature_request`,
                                {
                                    document: parent.doc.guarantee,
                                    source: last_signature?.id
                                },
                                {
                                    maxContentLength: Number.POSITIVE_INFINITY,
                                    headers: {
                                        'Authorization': parent.bearerToken
                                    }
                                }
                            ).then(x => {  

                                const signed_document = x.data;
                                
                                axios.post(
                                    `${azure_function_url}/NFGX?command=guarantee_agree`,
                                    {
                                        document: parent.doc.guarantee,
                                        credential: signed_document
                                    },
                                    {
                                        maxContentLength: Number.POSITIVE_INFINITY,
                                        headers: {
                                            'Authorization': parent.bearerToken
                                        }
                                    }
                                ).then(x => {
                                    
    
                                    console.log(x.data)
                                    message.success('Accept successfulll');
                                    parent.done();
                                });
                            });
                        } }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Radio.Button value="edit">Issue</Radio.Button>
                    </Popconfirm>
                    : parent.doc.guarantee.issuers.map((x:any) => x.id).indexOf(parent.user?.entity?.id) > -1 && (signatures?.filter(x => x.reference_id === parent.user?.id).length === 0) ?
                    <>
                    <Popconfirm
                        title="Edit Guarantee"
                        description="Are you sure you want to edit the guarantee?"
                        onConfirm={async () => { 
                            // message.success('Edit successfulll');
                            setEditGuarantee(true);

                        } }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Radio.Button value="edit">Edit</Radio.Button>
                    </Popconfirm>
                    <Popconfirm
                        title="Issue Guarantee"
                        description="Are you sure you want to issue the guarantee?"
                        onConfirm={async () => { 
                            

                            axios.post(
                                `${azure_function_url}/NFGX?command=issue`,
                                parent.doc,
                                {
                                    maxContentLength: Number.POSITIVE_INFINITY,
                                    headers: {
                                        'Authorization': parent.bearerToken
                                    }
                                }
                            ).then(x => {
                                message.success('Deny successfulll');
                                parent.done();
                            });

                        } }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Radio.Button value="edit">Deny</Radio.Button>
                    </Popconfirm>
                    </>
                    : <></>
                    }
                </Radio.Group>
            }
                
            bodyStyle={isMobile ? { padding: 0 } : {}}
        >
            {
                isMobile ?
                <></>
                :
                <>

                <Row gutter={[16, 16]}>
                    <Col span={6}><h1>Identity Reference</h1></Col>
                    <Col span={3}><h1>Version</h1></Col>
                    <Col span={3}><h1>Status</h1></Col>
                    <Col span={6}><h1>Type</h1></Col>
                    <Col span={6}><h1>Amount</h1></Col>
                    
                </Row>
                
                <Row gutter={[16, 16]}>
                    <Col span={6}>{parent.doc.id}</Col>
                    <Col span={3}>{parent.doc.version}</Col>
                    <Col span={3}>{parent.doc.status}</Col>
                    <Col span={6}>{parent.doc.guarantee.type}</Col>
                    <Col span={6}>{`${parent.doc.guarantee.currency} ` + `${parent.doc.guarantee.amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Col>
                    
                </Row>
            </>
            }
        </Card>
        <br></br>
        <br></br>
        <Card
            title={`Timelines`}
            // extra={<Button style={{float:'right'}} icon={<LeftOutlined />} onClick={() => parent.done() }></Button>}
            bodyStyle={isMobile ? { padding: 0 } : {}}

        >
            <div style={{paddingLeft: 110, paddingRight:100, paddingBottom: 20}}>
                <Slider 
                    disabled
                    marks={marks} 
                    defaultValue={t_today} 
                />   
            </div>
        </Card>
        <br></br>
        <Card
            title={`Counterparties`}
            // extra={<Button style={{float:'right'}} icon={<LeftOutlined />} onClick={() => parent.done() }></Button>}
            bodyStyle={isMobile ? { padding: 0 } : {}}
        >
            <Row gutter={[16, 16]}>
                <Col span={8}><Title level={3} type="secondary">Applicants</Title></Col>
                <Col span={8}><Title level={3} type="secondary">Beneficiaries</Title></Col>
                <Col span={8}><Title level={3} type="secondary">Issuers</Title></Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <List
                        itemLayout="vertical"
                        dataSource={parent.doc.guarantee.applicants}
                        renderItem={(item : any, index) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<RightOutlined/>}
                                    title={<a onClick={() => { 
                                        if(VerifiedDocumentTypes.indexOf(item.type) > -1 || item.type === CustomDataTypes.PHOTO_ID) {
                                            setViewItem(item)
                                        } 
                                        else if(item.type === CustomDataTypes.FILE_UPLOAD){
                                            setViewFile(item)
                                        }
                                        console.log(item)
                                    }}>{item.name}</a>}
                                    description={ `ABN: ${item.reference}` }
                                />
                            </List.Item>
                        )}
                    />
                </Col>
                <Col span={8}>
                    <List
                        itemLayout="vertical"
                        dataSource={parent.doc.guarantee.beneficiaries}
                        renderItem={(item : any, index) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<RightOutlined style={{fontSize: '10px'}}/>}
                                    title={<a onClick={() => { 
                                        if(VerifiedDocumentTypes.indexOf(item.type) > -1 || item.type === CustomDataTypes.PHOTO_ID) {
                                            setViewItem(item)
                                        } 
                                        else if(item.type === CustomDataTypes.FILE_UPLOAD){
                                            setViewFile(item)
                                        }
                                    }}>{item.name}</a>}
                                    description={ `ABN: ${item.reference}` }
                                />
                            </List.Item>
                        )}
                    />
                </Col>
                <Col span={8}>
                    <List
                        itemLayout="vertical"
                        dataSource={parent.doc.guarantee.issuers}
                        renderItem={(item : any, index) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<RightOutlined/>}
                                    title={<a onClick={() => { 
                                        if(VerifiedDocumentTypes.indexOf(item.type) > -1 || item.type === CustomDataTypes.PHOTO_ID) {
                                            setViewItem(item)
                                        } 
                                        else if(item.type === CustomDataTypes.FILE_UPLOAD){
                                            setViewFile(item)
                                        }
                                        console.log(item)
                                    }}>{item.name}</a>}
                                    description={ `ABN: ${item.reference}` }
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </Card>
        <br></br>
        <Card title={'Contract'}>
            <Title level={3} type="secondary">{parent.doc.guarantee.contract.name}</Title>
            <Paragraph >{parent.doc.guarantee.contract.description}</Paragraph>
            <br></br>
            <Row gutter={[16, 16]}>
                <Col span={6}><h1>Contract Number</h1></Col>
                <Col span={9}><h1>Start Date</h1></Col>
                <Col span={9}><h1>End Date</h1></Col>
            </Row>
            
            <Row gutter={[16, 16]}>
                <Col span={6}>{parent.doc.guarantee.contract.id}</Col>
                <Col span={9}>{parent.doc.guarantee.contract.startDate.toLocaleString()}</Col>
                <Col span={9}>{parent.doc.guarantee.contract.endDate.toLocaleString()}</Col>
            </Row>
        </Card>
        {
            parent.doc.guarantee.files?.length > 0 ?
            <>
            <br></br>
            <Card title={'Files'}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Table
                            // loading={loading}
                            dataSource={parent.doc.guarantee.files?.map((x: any) => {
                                let y: any = Object.assign({}, x);
                                y.key = x.id;
                                return y;
                            })}
                            pagination={{hideOnSinglePage: true}}
                        >
                            <Column
                                title="Id"
                                key="id"
                                render={(_: any, record: any) => shortenString(record.id)}
                            />
                            <Column
                                title="Name"
                                key="name"
                                render={(_: any, record: any) => 
                                    <a onClick={() => {
                                        axios.get(
                                            `${azure_function_url}/NFGX?command=file_get&id=${record.id}`,
                                            {
                                                maxContentLength: Number.POSITIVE_INFINITY,
                                                headers: {
                                                    'Authorization': parent.bearerToken
                                                }
                                            }
                                        ).then(x => {
                                            setViewFile(x.data);
                                        });
                                        
                                    }}
                                    >
                                        {record.name}
                                    </a>}
                            />
                            <Column
                                title="Type"
                                key="type"
                                render={(_: any, record: any) => record.type}
                            />
                            <Column
                                title="Date"
                                key="date"
                                render={(_: any, record: any) => record.created.toLocaleString()}
                            />
                        </Table>
                    </Col>
                </Row>
            </Card>
            </>
            :<></>
        }
        {
            signatures?.length > 0 ?
            <>
            <br></br>
            <Card title={'Document Signatures'}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Table
                            // loading={loading}
                            dataSource={signatures?.map((x: any) => {
                                let y: any = Object.assign({}, x);
                                y.key = x.id;
                                return y;
                            })}
                            pagination={{hideOnSinglePage: true}}
                        >
                            <Column
                                title="Id"
                                key="id"
                                render={(_: any, record: any) => shortenString(record.id)}
                            />
                            <Column
                                title="Source"
                                key="source"
                                render={(_: any, record: any) => shortenString(record.source)}
                            />
                            <Column
                                title="Email"
                                key="email"
                                render={(_: any, record: any) => record.email }
                            />
                            <Column
                                title="Date"
                                key="date"
                                render={(_: any, record: any) => new Date(Date.parse(record.created)).toLocaleString()}
                            />
                        </Table>
                    </Col>
                </Row>
            </Card>
            </>
            :<></>
        }
        <br></br>
        {
            parent.modal === undefined?
        
            <Card title={'History'}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Timeline
                            mode={'left'}
                            items={history?.map(x => {
                                return [{
                                    label: x.creationDate ? new Date(Date.parse(x.creationDate)).toLocaleString() : null,
                                    children: <a onClick={()=>setViewModal(x)}>Version: {x.version}</a>,
                                }
                                // ,{
                                //     label: x.approvedDateBeneficiary ? new Date(Date.parse(x.approvedDateBeneficiary)).toLocaleString() : null,
                                //     children: <a onClick={()=>setViewModal(x)}>Version: {x.version} - Beneficiary Approval</a>,
                                // },{
                                //     label: x.approvedDateIssuer ? new Date(Date.parse(x.approvedDateIssuer)).toLocaleString() : null,
                                //     children: <a onClick={()=>setViewModal(x)}>Version: {x.version} - Issuer Approval</a>,
                                // },{
                                //     label: x.approvedDateApplicant ? new Date(Date.parse(x.approvedDateApplicant)).toLocaleString() : null,
                                //     children: <a onClick={()=>setViewModal(x)}>Version: {x.version} - Applicant Approval</a>,
                                // }
                            ]
                            }).flat().filter(x=>x.label !== null).sort((a,b) => Date.parse(a.label ?? '') - Date.parse(b.label ?? ''))
                            }
                        />
                    </Col>
                </Row>
            </Card>
            :<></>
        }


        {viewFile ? 
            <>
                <br></br>
                <FileViewer viewFile={viewFile} done={() =>  setViewFile(undefined) }/>
            </>
        : <></>}

        {viewModal && parent.modal === undefined ? 
        <>
        <Modal width={1500} title={"Historical Version"} onCancel={()=>{setViewModal(undefined)}} closable={true} open={viewModal} footer={null}>
            <ViewGuarantee bearerToken={parent.bearerToken} user={parent.user} doc={parseJSON(viewModal)} modal={true} done={()=>{}} ></ViewGuarantee>
        </Modal>
        </>:<></>}
        </>

    )
}