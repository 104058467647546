import React, { useState, useEffect } from 'react';
import { List, Card, DatePicker, Typography, Row, Col, Table, Carousel, Image, Button, Popconfirm, message, Divider } from 'antd';

import { EyeOutlined, DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { FrankieOneDocumentTypes, CustomDataTypes } from '../../utils/constants';
import { FrankieOneDocument, UserUI } from '../../utils/data_types';

import { isMobileBrowser } from '../../utils/utils';

import { Shared, Views } from './Traces';

import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';


const { Title, Text } = Typography;
const { Column } = Table;

const license = (doc: FrankieOneDocument | undefined | null) => {
    return (
        <>
            <Row gutter={[16, 24]}>

                <Col span={12}><Text strong>License Number</Text></Col>
                <Col span={12}><Text>{doc?.idNumber}</Text></Col>
                
                <Col span={12}><Text strong>Document Number</Text></Col>
                <Col span={12}><Text>{doc?.ocr_scanned_document_number}</Text></Col>

                <Col span={12}><Text strong>Country</Text></Col>
                <Col span={12}><Text>{doc?.country}</Text></Col>
                
                <Col span={12}><Text strong>Region</Text></Col>
                <Col span={12}><Text>{doc?.region}</Text></Col>

                <Col span={12}><Text strong>First Name</Text></Col>
                <Col span={12}><Text>{doc?.ocr_scanned_first_name}</Text></Col>

                <Col span={12}><Text strong>Last Name</Text></Col>
                <Col span={12}><Text>{doc?.ocr_scanned_last_name}</Text></Col>

                <Col span={12}><Text strong>Date of Birth</Text></Col>
                <Col span={12}><Text>{doc?.ocr_scanned_date_of_birth}</Text></Col>
                
            </Row>
        </>
        
    )
}

const passport = (doc: FrankieOneDocument | undefined | null) => {
    return (
        <>
            <Row gutter={[16, 24]}>

                <Col span={12}><Text strong>Passport Number</Text></Col>
                <Col span={12}><Text>{doc?.idNumber}</Text></Col>
                
                <Col span={12}><Text strong>Document Number</Text></Col>
                <Col span={12}><Text>{doc?.ocr_scanned_document_number}</Text></Col>

                <Col span={12}><Text strong>Country</Text></Col>
                <Col span={12}><Text>{doc?.country}</Text></Col>
                
                <Col span={12}><Text strong>Issuer</Text></Col>
                <Col span={12}><Text>{doc?.ocr_scanned_issuing_country}</Text></Col>

                <Col span={12}><Text strong>First Name</Text></Col>
                <Col span={12}><Text>{doc?.ocr_scanned_first_name}</Text></Col>

                <Col span={12}><Text strong>Last Name</Text></Col>
                <Col span={12}><Text>{doc?.ocr_scanned_last_name}</Text></Col>

                {/* <Col span={12}><Text strong>Date of Birth</Text></Col>
                <Col span={12}><Text>{(new Date(doc.ocr_scanned_date_of_birth)).toUTCString()}</Text></Col>
                <Col span={12}><DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(Number(doc.ocr_scanned_date_of_birth) / 1000)} /></Col> */}
                
            </Row>
        </>
        
    )
}

const medicare = (doc: FrankieOneDocument | undefined | null) => {
    return (
        <>
            <Row gutter={[16, 24]}>

                <Col span={12}><Text strong>Medicare Number</Text></Col>
                <Col span={12}><Text>{doc?.idNumber}</Text></Col>
                
                <Col span={12}><Text strong>Position</Text></Col>
                <Col span={12}><Text>{doc?.reference}</Text></Col>

                <Col span={12}><Text strong>Color</Text></Col>
                <Col span={12}><Text>{doc?.idSubType === 'G' ? 'Green' : doc?.idSubType === 'B' ? 'Blue' : 'Yellow'}</Text></Col>
                
            </Row>
        </>
        
    )
}

// const Shared: React.FC<{user?: UserUI, bearerToken: string, doc: FrankieOneDocument | undefined | null}>  = (parent) => {
//     const [views, setViews] = useState<any[]>([]);
//     const [toDelete, setToDelete] = useState<any[]>([]);

//     const [collapsed, setCollapsed] = useState<boolean>(isMobileBrowser());

//     const azure_function_url = process.env.AZURE_FUNCTION_URL;

//     useEffect(() => {
//         axios.get(
//             `${azure_function_url}/Databoss?command=document_trace&id=${parent.user?.id}&type=${parent.doc?.idType}`,
//             {
//                 maxContentLength: Number.POSITIVE_INFINITY,
//                 headers: {
//                     'Authorization': parent.bearerToken
//                 }
//             }
//         ).then(x => {
//             setViews(x.data);
//         });
//     },[]);

//     return (
//         <>
//             {
//                 views.length > 0 ?
//                 <>
//                     <br></br>
//                     <Card 
//                         bordered={false} 
//                         title={'Access'}
//                     >
//                     {
//                         toDelete?.length > 0 ?
//                         <>
//                             <Popconfirm
//                                 title="Delete the request"
//                                 description="Are you sure to delete this request?"
//                                 // onConfirm={confirm}
//                                 // onCancel={cancel}
//                                 okText="Yes"
//                                 cancelText="No"
//                             >
//                                 <Button style={{border:'none'}} icon={<DeleteOutlined />}>Delete Selection</Button>
//                             </Popconfirm>
//                         </>
//                         :
//                         <></>
//                     }
//                     {
//                         collapsed ?
//                         <>
//                         <Table 
//                             rowSelection={{
//                                 type: 'checkbox',
//                                 onChange: (selectedRowKeys, selectedRows) => {
//                                     setToDelete(selectedRows);
//                                 },
//                             }}
//                             dataSource={views?.map((x:any, i:number) => {
//                                 let y: any = x;
//                                 y.key = i;
//                                 return y;
//                             })}
//                             pagination={{hideOnSinglePage: true}}
//                         >
//                             {/* <Column></Column> */}
//                             {/* <Column
//                                 title="Expiry"
//                                 key="date"
//                                 render={(_: any, record: any) => 
//                                     <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(Date.parse(record.date_expiry) / 1000)} />
//                                 }
//                             /> */}
//                             <Column
//                                 title="Company"
//                                 key="companyname"
//                                 render={(_: any, record: any) => record.companyname}
//                             />
//                             <Column
//                                 title="Delete"
//                                 key="employeeid"
//                                 render={(_: any, record: any) => (
//                                     <Popconfirm
//                                         title="Delete the request"
//                                         description="Are you sure to delete this request?"
//                                         // onConfirm={confirm}
//                                         // onCancel={cancel}
//                                         okText="Yes"
//                                         cancelText="No"
//                                     >
//                                         <Button style={{border:'none'}} icon={<DeleteOutlined />}/>
//                                     </Popconfirm>
//                                 )}
//                             />
//                         </Table>
//                         </>
//                         :
//                         <Table 
//                             rowSelection={{
//                                 type: 'checkbox',
//                                 onChange: (selectedRowKeys, selectedRows) => {
//                                     setToDelete(selectedRows);
//                                 },
//                             }}
//                             dataSource={views?.map((x:any, i:number) => {
//                                 let y: any = x;
//                                 y.key = i;
//                                 return y;
//                             })}
//                             pagination={{hideOnSinglePage: true}}
//                         >
//                             {/* <Column></Column> */}
//                             <Column
//                                 title="Expiry"
//                                 key="date"
//                                 render={(_: any, record: any) => 
//                                     <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(Date.parse(record.date_expiry) / 1000)} />
//                                 }
//                             />
//                             <Column
//                                 title="Company"
//                                 key="companyname"
//                                 render={(_: any, record: any) => record.companyname}
//                             />
//                             <Column
//                                 title="Delete"
//                                 key="employeeid"
//                                 render={(_: any, record: any) => (
//                                     <Popconfirm
//                                         title="Delete the request"
//                                         description="Are you sure to delete this request?"
//                                         // onConfirm={confirm}
//                                         // onCancel={cancel}
//                                         okText="Yes"
//                                         cancelText="No"
//                                     >
//                                         <Button style={{border:'none'}} icon={<DeleteOutlined />}/>
//                                     </Popconfirm>
//                                 )}
//                             />
//                         </Table>
//                     }
//                     </Card>
//                 </>
//                 :
//                 <></>
//             }
//         </>
        
//     )
// }

// const Views: React.FC<{user?: UserUI, bearerToken: string, doc: FrankieOneDocument | undefined | null}>  = (parent) => {
//     const [views, setViews] = useState<any[]>([]);
//     const [collapsed, setCollapsed] = useState<boolean>(isMobileBrowser());

//     const azure_function_url = process.env.AZURE_FUNCTION_URL;

//     useEffect(() => {
//         axios.get(
//             `${azure_function_url}/Databoss?command=document_views&id=${parent.user?.id}&type=${parent.doc?.idType}`,
//             {
//                 maxContentLength: Number.POSITIVE_INFINITY,
//                 headers: {
//                     'Authorization': parent.bearerToken
//                 }
//             }
//         ).then(x => {
//             setViews(x.data);
//         });
//     }, []);
    

//     return (
//         <>
//             {
//                 views.length > 0 ?
//                 <>
//                 <br></br>
//                 <Card 
//                     bordered={false} 
//                     title={'Views'}
//                 >
//                     {
//                         collapsed ?
//                         <>
//                         <List itemLayout="horizontal">
//                         {
//                             views?.map(x => {
//                                 let y: any = x;
//                                 y.key = x.id;
//                                 return y;
//                             }).map(x => 
//                                 <List.Item key={0}>
//                                     <List.Item.Meta
//                                         avatar={
//                                             <EyeOutlined />
//                                         }
//                                         title={x.companyname}
//                                         description={<>{x.employeeid} <br></br> {new Date(x.date).toLocaleString()}</>}
//                                     /> 
//                                 </List.Item>
//                             )
//                         }
//                         </List>
//                         </>
//                         :
//                         <Table 
//                             dataSource={views?.map((x:any, i:number) => {
//                                 let y: any = x;
//                                 y.key = i;
//                                 return y;
//                             })}
//                             pagination={{hideOnSinglePage: true}}
//                         >
                            
//                             <Column
//                                 title="Date"
//                                 key="date"
//                                 render={(_: any, record: any) => 
                                    
//                                     <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(Date.parse(record.date) / 1000)} />
//                                 }
//                             />
//                             <Column
//                                 title="Company"
//                                 key="companyname"
//                                 render={(_: any, record: any) => record.companyname}
//                             />
//                             <Column
//                                 title="Employee"
//                                 key="employeeid"
//                                 render={(_: any, record: any) => record.employeeid}
//                             />
//                         </Table>
//                     }
//                 </Card>
//                 </>
//                 :
//                 <></>
//             }
             
//         </>
        
//     )
// }

export interface IViewDocumentProps {
    user?: UserUI,
    bearerToken: string,
    doc: { name: string, type: FrankieOneDocumentTypes | string, doc: FrankieOneDocument | undefined }
    done: () => void
}

export const ViewDocument: React.FC<IViewDocumentProps> = (parent) => {

    const [collapsed, setCollapsed] = useState<boolean>(isMobileBrowser());
    
    return (        
        <div 
        >
            {
                // parent.doc.type === CustomDataTypes.PHOTO_ID ?
                // <>
                // <Card>
                //     <Title>{parent.doc.name}</Title>
                //     <br></br>
                //     <br></br>
                //     <Carousel>
                //     {
                //         parent.doc?.doc?.images?.map((x:any, i: number) => (
                //             <div key={i}>
                //                 <Image src={`data:image/png;base64,${x}`}/>
                //             </div>
                //         ))
                //     }
                //     </Carousel>
                // </Card>
                // </>
                // :
                parent.doc?.doc?.images ?
                <>
                    <br></br>
                    <Card
                        bordered={false} 
                        title={'Document Viewer'}
                        extra={<Button icon={<CloseCircleOutlined/>} onClick={() => parent.done() }></Button>}
                        bodyStyle={{ padding: 0 }}
                    >
                    </Card>
                    <br></br>
                    {
                        collapsed ?
                        <>
                        <Row gutter={[16, 16]}>
                            
                            <Col key={0} span={24}>
                                <Card
                                    bordered={false} 
                                    title={parent.doc.name}
                                >
                                    {
                                        parent.doc.type === FrankieOneDocumentTypes.DRIVERS_LICENCE ?
                                            license(parent.doc.doc)
                                        :
                                        parent.doc.type === FrankieOneDocumentTypes.NATIONAL_HEALTH_ID ?
                                            medicare(parent.doc.doc)
                                        :
                                        parent.doc.type === FrankieOneDocumentTypes.PASSPORT ?
                                            passport(parent.doc.doc)
                                        :
                                            <JsonView collapsed={1} src={parent.doc} />    
                                    }
                                </Card>
                            </Col>
                        </Row> 
                        <br></br>
                        <Row gutter={[16, 16]}>
                            
                            <Col key={0} span={24}>
                                <Card
                                    bordered={false} 
                                    title={'Image'}
                                >
                                    <Carousel
                                        style={{
                                            textAlign: 'center'
                                        }}
                                    >
                                    {
                                        parent.doc.doc.images.map((x:any, i: number) => (
                                            <div key={i}>
                                                <Image 
                                                    height={292} 
                                                    style={{
                                                        borderRadius:15, 
                                                    }} 
                                                    preview={true} 
                                                    src={`data:image/png;base64,${x}`}
                                                />
                                            </div>
                                        ))
                                    }
                                    </Carousel>
                                </Card>
                            </Col>
                        </Row> 
                        </>
                        :
                        <Row gutter={[16, 16]}>
                            
                            <Col key={0} span={12}>
                                <Card
                                    bordered={false} 
                                    title={parent.doc.name}
                                >
                                    {
                                        parent.doc.type === FrankieOneDocumentTypes.DRIVERS_LICENCE ?
                                            license(parent.doc.doc)
                                        :
                                        parent.doc.type === FrankieOneDocumentTypes.NATIONAL_HEALTH_ID ?
                                            medicare(parent.doc.doc)
                                        :
                                        parent.doc.type === FrankieOneDocumentTypes.PASSPORT ?
                                            passport(parent.doc.doc)
                                        :
                                            <JsonView collapsed={1} src={parent.doc} />    
                                    }
                                </Card>
                            </Col>
                            <Col key={1} span={12}>
                                <Card
                                    bordered={false} 
                                    title={'Image'}
                                >
                                    <Carousel
                                        style={{
                                            textAlign: 'center'
                                        }}
                                    >
                                    {
                                        parent.doc.doc.images.map((x:any, i: number) => (
                                            <div key={i}>
                                                <Image 
                                                    height={292} 
                                                    style={{
                                                        borderRadius:15, 
                                                    }} 
                                                    preview={true} 
                                                    src={`data:image/png;base64,${x}`}
                                                />
                                            </div>
                                        ))
                                    }
                                    </Carousel>
                                </Card>
                            </Col>   
                        </Row> 
                        
                    }
                </>
                :
                <>
                    <br></br>
                    <Card
                        bordered={false} 
                        title={'Document Viewer'}
                        extra={<Button icon={<CloseCircleOutlined/>} onClick={() => parent.done() }></Button>}
                        bodyStyle={{ padding: 0 }}
                    >
                    </Card>

                    <br></br>
                    <Card 
                        bordered={false} 
                        title={parent.doc.name}
                    >
                    {
                        parent.doc.type === FrankieOneDocumentTypes.DRIVERS_LICENCE ?
                            license(parent.doc?.doc)
                        :
                        parent.doc.type === FrankieOneDocumentTypes.NATIONAL_HEALTH_ID ?
                            medicare(parent.doc?.doc)
                        :
                        parent.doc.type === FrankieOneDocumentTypes.PASSPORT ?
                            passport(parent.doc?.doc)
                        :
                            <JsonView collapsed={1} src={parent.doc} />
                    }
                    </Card>
                </>
            }   
            <Shared id={parent.user?.id} bearerToken={parent.bearerToken} type={parent.doc?.doc?.idType} close={undefined}/>
            <Views id={parent.user?.id} bearerToken={parent.bearerToken} type={parent.doc?.doc?.idType} close={undefined}/>
        </div>  
    )
}